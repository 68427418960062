import { useEffect, useState } from 'react';
import './presupuestos-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {consultarPresupuestos, eliminarPresupuesto} from '../../../backend/presupuestos/presupuestos';
import {edit, trash, flechaVer} from '../../../global/imgs.js';
import {Modal} from '../../../components/modals/modals';

function PresupuestosEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

	const [busquedaPresupuestos, CambioBusquedaPresupuestos] = useState(false);
	const [presupuestos, CambioPresupuestos] = useState([]);
	const [presupuestosFinales, CambioPresupuestosFinales] = useState([]);
	const [limitePresupuestos, CambioLimitePresupuestos] = useState(20);

	const [preguntaModal, CambioPreguntaModal] = useState();
	const [idPresupuesto, CambioIdPresupuesto] = useState();
	const [modalEliminarPresupuesto, CambioModalEliminarPresupuesto] = useState();

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('presupuestos').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaPresupuestos == false) {

			consultarPresupuestos(urlEmpresa).then(response =>{

				let losPresupuestos = [];
				let cero = 0;

				for (let presupuesto of response) {
					
					if (cero <= limitePresupuestos) {

						losPresupuestos.push(presupuesto);
					}

					cero++;
				}

				CambioPresupuestos(response);
				CambioPresupuestosFinales(losPresupuestos);
			});

			CambioBusquedaPresupuestos(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const preguntarEliminarPresupuesto = async function(item){

		CambioPreguntaModal('¿Desea eliminar esta presupuesto?');
		CambioIdPresupuesto(item.id);
		CambioModalEliminarPresupuesto(true);
	}

	const procederEliminarPresupuesto = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    eliminarPresupuesto(idPresupuesto).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Presupuesto eliminado.',
			});

			CambioModalEliminarPresupuesto(false);
			CambioIdPresupuesto(false);
			CambioBusquedaPresupuestos(false);
	    })
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					<div class="container"><br/>

						<div class="row">
						
							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Presupuestos</h5>
										<div class="form-group">
											<input type="text" class="form-control" id="buscarPresupuesto" placeholder="Buscar por ID de reparación" />
										</div>
										<button onClick={() => window.location.href = '/'+urlEmpresa+'/presupuesto/crear'} class="btn btn-primary botonRepararTuPC widthContent">Crear Presupuesto</button>
									</div>
									<div class="formulario">
										<table class="table table-hover">
											<tr>
										      	<th class="th" scope="row">ID</th>
										      	<th class="th">ID Reparación</th>
										      	<th class="th">Fecha</th>
										      	<th class="th">Acciones</th>
										    </tr>
										    {presupuestosFinales.map((item) =>(
										    	<tr class="bordeTr">
										    		<td class="elTr" scope="row">{item.id}</td>
											      	<td class="elTr">{item.data.numeroFolio}</td>
											      	<td class="elTr">{item.data.fechaCreacion}</td>
											      	<td class="elTr">
											      		<img onClick={() => window.location.href = '/'+urlEmpresa+'/presupuesto/editar/'+item.id} src={edit} class="eye" />
												      	<img onClick={() => preguntarEliminarPresupuesto(item)} src={trash} class="eye" />
												    </td>
											    </tr>
										    ))}
										</table>
									</div>
								</div>

							</div>

						</div><br/>

					</div>

				</div>

			</div>

			<Modal mostrar={modalEliminarPresupuesto}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={procederEliminarPresupuesto} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEliminarPresupuesto(false);
				    	CambioIdPresupuesto(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

		</AnimatedPage>
	);
}

export default PresupuestosEmpresaScreen;