import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc, referencia, almacenamiento, subirArchivo, obtenerURL} from '../conex.js';

export async function validarFactura(idReparacion){

	let date = new Date();

	let ano = date.getFullYear();

	let facturas = [];

	const q = buscar(coleccion(db, "facturas"), donde("idReparacion", "==", idReparacion));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		if (doc.data().ano == ano) {

			facturas.push({
				id: doc.id,
				data: doc.data(),
			});
		}
	});

	if (facturas.length > 0) {

		return facturas[0];
	}else{

		return false;
	}
}

export async function crearFactura(datos){

	let numeroFactura = 1;

	let cantidad = await cantidadFacturas(datos.urlEmpresa, datos.ano);

	if (cantidad > 0) {

		numeroFactura = cantidad + 1;
	}

	var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    var id = "";
      	for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    let datosFinales = {
    	idReparacion: datos.idReparacion,
		fecha: datos.fecha,
		ano: datos.ano,
		urlEmpresa: datos.urlEmpresa,
		numeroFactura: numeroFactura,
    };

    await set(documento(db, "facturas", id), datosFinales);

    return datosFinales;
}

async function cantidadFacturas(urlEmpresa, ano){

	let facturas = [];

	const q = buscar(coleccion(db, "facturas"), donde("urlEmpresa", "==", urlEmpresa));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		if (doc.data().ano == ano) {

			facturas.push({
				id: doc.id,
				data: doc.data(),
			});
		}
	});

	return facturas.length;
}