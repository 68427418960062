import { useEffect, useState } from 'react';
import './login-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import {iniciarSesion, recuperarPassword, resgistrarUsuario} from '../../../backend/auth/auth';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {Modal} from '../../../components/modals/modals';
import {logoLogin, logoFinal1} from '../../../global/imgs.js';

function LoginEmpresasScreen(){

	const [pantalla, CambioPantalla] = useState('');
	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [modalPassword, CambioModalPassword] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	});

	const ponerUsuario = async function(){

		let registrar = await localStorage.getItem('registrarEmpresa');

		if (registrar != null) {

			await localStorage.removeItem('registrarEmpresa');

			CambioPantalla('registro');
		}

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}
		}
	}

	const terminar = async function(respuesta){

		if (respuesta != false) {

			if (respuesta.rol == 'empresa' || respuesta.rol == 'empleado') {

				await localStorage.setItem('usuarioReparacionDeOrdenadores', JSON.stringify(respuesta));
				
				window.location.href = '/'+respuesta.urlEmpresa;
			}else{

				Swal.fire({
			        icon: 'error',
			        title: 'No tiene permisos para ingresar aqui.',
			    });
			}
		}else{

			Swal.fire({
		        icon: 'error',
		        title: 'Lo sentimos pero hubo un error al autenticar sus datos.',
		    });
		}
	}

	const procesarLogin = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('email').value;
	    let password = document.getElementById('password').value;

	    if (email === '' || password === '') {

	      Swal.fire({
	        icon: 'info',
	        title: 'Complete todos los campos para continuar.',
	      });
	    }else{

	      iniciarSesion(email, password).then(response =>{

	        if (response != false) {

	          terminar(response);
	        }else{

	          Swal.fire({
	            icon: 'error',
	            title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
	          });
	        }
	      })
	    }
	}

	const procesarRecuperacion = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let emailRecuperar = document.getElementById('emailRecuperar').value;

	    if (emailRecuperar === '') {

	      Swal.fire({
	        title: 'Complete todos los campos para continuar.',
	        icon: 'info',
	      });
	    }else{
	      recuperarPassword(emailRecuperar).then(response =>{
	        if (response) {

	          Swal.fire({
	            title: 'Se ha enviado un correo electronico.',
	            icon: 'success',
	          });
	          CambioModalPassword(false);
	        }
	      })
	    }
	}

	const cronstruirURL = async function(elNombre){

		let quitarLaLetraNino = elNombre.replaceAll("ñ", "n");

		let quitarCaracteresEspaciales = quitarLaLetraNino.replaceAll(/[^a-zA-Z0-9 ]/g, '');

		let quitarEpacios = quitarCaracteresEspaciales.replaceAll(" ", "-");

		let ponerMinusculas = quitarEpacios.toLowerCase();

		return ponerMinusculas;
	}

	const procesarRegistro = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('email').value;
	    let password = document.getElementById('password').value;
	    let passwordConfirmar = document.getElementById('passwordConfirmar').value;
	    let nombre = document.getElementById('nombre').value;
	    let telefono = document.getElementById('telefono').value;
	    let DNIoNIF = document.getElementById('DNIoNIF').value;
	    let direccion = document.getElementById('direccion').value;

	    if (email === '' || password === '' || passwordConfirmar === '' || nombre === '' || telefono === '' || DNIoNIF === '' || direccion === '') {

	    	Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
	    }else if (isNaN(telefono)) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'El telefono debe ser numerico.',
		    });
	    }else if (password != passwordConfirmar) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'La contraseña no coincide.',
		    });
	    }else{

	    	let urlEmpresa = await cronstruirURL(nombre);

	    	let datos = {
	    		email: email,
				password: password,
				nombre: nombre,
				telefono: telefono,
				DNIoNIF: DNIoNIF,
				direccion: direccion,
				rol: 'empresa',
				suscripcion: false,
				urlEmpresa: urlEmpresa,
	    	};

	    	resgistrarUsuario(datos).then(response =>{

		        if (response == 'ya existe') {

		        	Swal.fire({
		            	icon: 'error',
		            	title: 'El usuario ingresado ya existe.',
		          	});
		        }else if (response != false) {

		          	terminar(response);
		        }else{

		          	Swal.fire({
		            	icon: 'error',
		            	title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
		          	});
		        }
		    });
	    }
	}

	if (pantalla == 'registro') {

		return(
			<AnimatedPage duracion={1}>
				<div class="fondoContainer">
					<div class="fondoHerramientas" style={{height: '132vh',}}></div>
					<div class="container containerLargoCompleto">

						<div class="row">
							<div class="col-sm" style={{paddingTop: '5%',}}>

								<div class="formContainer centrarTarjeta">
									<img src={logoFinal1} class="logoLogin" />
									<div class="headerForm">
										<h5 class="tituloForm">Registrar tu Empresa</h5>
										<span class="preguntaRegistro" style={{color: '#000', fontWeght: 'bold',}}>¿Ya tienes una cuenta? <span class="registrateAqui" onClick={() => CambioPantalla('')}>Ingresar Aqui</span></span>
									</div>
									<div class="formulario">

										<div class="form-group">
											<label for="email" class="form-label mt-4 labelSatflow">Escriba su Email*</label>
									      	<input type="email" class="inputSatflow" id="email" />
									    </div>

									    <div class="form-group">
									    	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Contraseña*</label>
									      	<input type="password" class="inputSatflow" id="password" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Confirme su Contraseña*</label>
									      	<input type="password" class="inputSatflow" id="passwordConfirmar" />
									    </div>

									    <div class="form-group">
									    	<label for="email" class="form-label mt-4 labelSatflow">Escriba el nombre de la empresa*</label>
									      	<input type="text" class="inputSatflow" id="nombre" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Escriba el telefono de la empresa*</label>
									      	<input type="text" class="inputSatflow" id="telefono" />
									    </div>

									    <div class="form-group">
									    	<label for="email" class="form-label mt-4 labelSatflow">Escriba el DNI / NIF de la empresa*</label>
									      	<input type="text" class="inputSatflow" id="DNIoNIF" />
									    </div>

									    <div class="form-group">
									    	<label for="email" class="form-label mt-4 labelSatflow">Dirección*</label>
									      	<textarea class="inputSatflow" id="direccion" rows="3"></textarea>
									    </div>

									    <br/>

									    <button onClick={procesarRegistro} class="btn btn-primary botonRepararTuPC">Registrarte</button>
									
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>

			</AnimatedPage>
		);
	}else{

		return(
			<AnimatedPage duracion={1}>
				<div class="fondoContainer">
					<div class="fondoHerramientas"></div>
					<div class="container containerLargoCompleto">

						<div class="row">
							<div class="col-sm" style={{paddingTop: '5%',}}>

								<div class="formContainer centrarTarjeta">
									<img src={logoFinal1} class="logoLogin" />
									<div class="headerForm">
										<h5 class="tituloForm">Ingresar al Panel de tu Empresa</h5>
										<span class="preguntaRegistro" style={{color: '#000', fontWeght: 'bold',}}>¿No tienes una cuenta? <span class="registrateAqui" onClick={() => CambioPantalla('registro')}>Registrate Aqui</span></span>
									</div>
									<div class="formulario">
										<div class="form-group">
											<label for="email" class="form-label mt-4 labelSatflow">Escriba su Email*</label>
									      	<input type="email" class="inputSatflow" id="email" />
									    </div>
									    <div class="form-group">
									    	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Contraseña*</label>
									      	<input type="password" class="inputSatflow" id="password" />
									    	<br/><br/>
									    	<small onClick={() => CambioModalPassword(true)} id="emailHelp" class="form-text text-muted botonPreguntaPassword">¿Haz olvidado tu contraseña?</small>
									    </div><br/>

									    <button onClick={procesarLogin} class="btn btn-primary botonRepararTuPC">Entrar</button>

									    <br/><br/>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>

				<Modal mostrar={modalPassword}>
				    <p onClick={() => CambioModalPassword(false)} class="equix">X</p>
				    <div class="formContainer centrarTarjeta">
						<div class="headerForm">
							<h5 class="tituloForm">Recuperar Contraseña</h5>
						</div>
						<div class="formulario">
							<div class="form-group">
								<label for="emailRecuperar" class="form-label mt-4">Email</label>
								<input type="email" class="inputSatflow" id="emailRecuperar" placeholder="Escriba su Email" />
							</div><br/>

							<button onClick={procesarRecuperacion} class="btn btn-primary botonRepararTuPC">Enviar</button>
						</div>
					</div>
				</Modal>

			</AnimatedPage>
		);
	}
}

export default LoginEmpresasScreen;