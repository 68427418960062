import { useEffect, useState } from 'react';
import './reparaciones-styles.css';

export function InfoUsuario({datos}){

	if (datos != false) {

		return(
			<>
				<div>
			      	<label class="form-label mt-4">Nombre:</label>
			    	<br/>
			    	<span>{datos.data.nombre}</span>
			    </div>
			    <div>
			      	<label class="form-label mt-4">Email:</label>
			    	<br/>
			    	<span>{datos.data.email}</span>
			    </div>
			    <div>
			      	<label class="form-label mt-4">telefono:</label>
			    	<br/>
			    	<span>{datos.data.telefono}</span>
			    </div>
			    <div>
			      	<label class="form-label mt-4">DNI / NIF:</label>
			    	<br/>
			    	<span>{datos.data.DNIoNIF}</span>
			    </div>
			    <div>
			      	<label class="form-label mt-4">Dirección:</label>
			    	<br/>
			    	<span>{datos.data.direccion}</span>
			    </div>
			</>
		);
	}else{

		return(
			<></>
		);
	}
}

export function ArchivoAdjunto({archivo}){

	if (archivo != '') {

		return(
			<>
				<button onClick={() => window.open(archivo, '_blank')} class="btn btn-primary botonRepararTuPC">Descargar</button>
			</>
		);
	}else{

		return(
			<>
				<span>No hay archivo adjunto</span>
			</>
		);
	}
}