import { useEffect, useState } from 'react';
import './menu-styles.css';
import {
	imgBotonMenu, 
	cerrarMenu, 
	folder, 
	cerrarSesion, 
	suscripciones,
	logoFinal1,
	bellsatflow,
	userSatflow,
	imgUsuarios,
	imgContenidos,
	imgConfig,
	imgAnalisis,
	imgSuscripciones,
	imgLock,
	imgEmpleados,
	imgReparaciones,
	imgDashboart,
	XNotis,
	facturaMenu,
	blogImg,
	facturaSatflow,
	presupuestosSatflow,
	imgCupones,
} from '../../global/imgs.js';
import {validarUsuario} from '../../global/validarUsuario';
import {consultarNotis, actualizarNoti} from '../../backend/notificaciones/notificaciones';
import Swal from 'sweetalert2';
import {consultarLogo} from '../../backend/usuarios/usuarios';

export function MenuAdmin(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [menuAbierto, CambioMenuAbierto] = useState(true);
	const [miUid, CambioMiUid] = useState(false);
	const [fotoUsuario, CambioFotoUsuario] = useState('https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fdescarga.jpg?alt=media&token=2897bb03-e19b-4671-9364-b7f8e4276c88');
	const [nombreUsuario, CambioNombreUsuario] = useState('');
	const [emailUsuario, CambioEmailUsuario] = useState('');

	const [busquedaNotis, CambiosBusquedaNotis] = useState(false);
	const [notis, CambionNotis] = useState([]);
	const [notisFinales, CambioNotisFinales] = useState([]);
	const [limiteNotis, CambioLimiteNotis] = useState(20);
	const [notisSinVer, CambioNotisSinVer] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaNotis == false && miUid != false) {

			consultarNotis(miUid).then(response =>{

				let lasNotis = [];
				let cero = 0;

				for (let laNoti of response) {
					
					if (cero <= limiteNotis) {

						lasNotis.push(laNoti);
					}

					if (laNoti.data.vistaCliente == false) {

						CambioNotisSinVer(true);
					}

					cero++;
				}

				CambionNotis(response);
				CambioNotisFinales(lasNotis);
			});

			CambiosBusquedaNotis(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			CambioFotoUsuario(elUsuario.foto);
			CambioNombreUsuario(elUsuario.nombre);
			CambioEmailUsuario(elUsuario.email);
			CambioMiUid(elUsuario.uid);
		}
	}

	const abrirOCerrarMenu = async function(){

		if (menuAbierto == false) {

			document.getElementById('menuAdmin').classList.add('menuAdminAbierto');
			document.getElementById('listaMenu').classList.remove('quitarResposive');
			CambioMenuAbierto(true);
		}else{

			document.getElementById('menuAdmin').classList.remove('menuAdminAbierto');
			document.getElementById('listaMenu').classList.add('quitarResposive');
			CambioMenuAbierto(false);
		}
	}

	const terminarSesion = async function(){

		await localStorage.clear();
		window.location.href = '/admin/login';
	}

	const accionListaNotis = async function(accion){

		if (accion == 'abrir') {

			document.getElementById('notisMenu').setAttribute('style', '');
		}else{

			document.getElementById('notisMenu').setAttribute('style', 'display: none;');
		}
	}

	const verMasNotis = async function(){

		let suma = limiteNotis + 20;

		CambioLimiteNotis(suma);

		let lasNotis = [];
		let cero = 0;

		for (let laNoti of notis) {
					
			if (cero <= suma) {

				lasNotis.push(laNoti);
			}

			cero++;
		}

		CambioNotisFinales(lasNotis);
	}

	const verNoti = async function(item){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		        '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		        '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		let dejatVistaNotis = await actualizarNoti(item);

		window.location.href = '/admin';
	}

	return(
		<div class="menuAdmin menuAdminAbierto" id="menuAdmin">
			<div class="headerMenu">
				<img src={logoFinal1} class="logoMenu" />

				<div class="bellUserContainer ocultarBell">
					<div>
						<img onClick={() => accionListaNotis('abrir')} src={bellsatflow} class="bellUserImg" />
						<PuntoSinVer visto={notisSinVer} />
					</div>
					<div class="notisContainer" id="notisMenu" style={{display: 'none',}}>
						<div class="headerNotis">
							<span class="tituloHeaderNotis">Notificaciones</span>
						
							<img onClick={() => accionListaNotis('cerrar')} src={XNotis} class="XNotis" />
						</div>
						<div class="listaNotis">
							{notisFinales.map((item) =>(
								<div class="noti" onClick={() => verNoti(item)}>
									<img src={item.usuario.data.foto} class="imgNoti" />
									<div class="dataNoti">
										<span class="tituloNoti">{item.data.titulo}</span>
										<br/>
										<span class="infoNotis">{item.data.descripcion}</span>
										<br/>
										<span class="infoNotis">{item.data.fechaCreacion}</span>
									</div>
									<PuntoNoti visto={item.data.vistaCliente} />
								</div>
							))}
						</div>
						<FooterNotis 
							lista={notis}
							limite={limiteNotis}
							accion={verMasNotis} 
						/>
					</div>

					<img src={userSatflow} class="bellUserImg" />
					<img onClick={abrirOCerrarMenu} src={cerrarMenu} class="cerrarMenu"/>
				</div>
			</div>

			<div class="datosUsuarioMenu">
				<img src={fotoUsuario} class="fotoUsuario" />
				<span class="nombreUsuarioMenu">{nombreUsuario}</span>
				<span class="emailUsuarioMenu">{emailUsuario}</span>
			</div>

			<div class="listaMenu quitarResposive" id="listaMenu">

				<div onClick={() => window.location.href = '/admin/'} class="elementoMenu" id="home">
					<img src={imgUsuarios} class="imgElementoMenu"/>

					<span>Usuarios</span>
				</div>

				<div class="elementoMenu">
					<img src={imgContenidos} class="imgElementoMenu"/>

					<span>Contenidos</span>
				</div>

				<div class="elementoMenu">
					<img src={imgConfig} class="imgElementoMenu"/>

					<span>Configuraciones del Sistema</span>
				</div>

				<div class="elementoMenu">
					<img src={imgAnalisis} class="imgElementoMenu"/>

					<span>Análisis y Reportes</span>
				</div>

				<div onClick={() => window.location.href = '/admin/suscripciones'} class="elementoMenu" id="suscripciones">
					<img src={imgSuscripciones} class="imgElementoMenu" id="suscripciones" />

					<span>Suscripciones</span>
				</div>

				<div onClick={() => window.location.href = '/admin/cupones'} class="elementoMenu" id="cupones">
					<img src={imgCupones} class="imgElementoMenu" id="imgCupones" />

					<span>Cupones</span>
				</div>

				<div onClick={terminarSesion} class="elementoMenu">
					<img src={imgLock} class="imgElementoMenu"/>

					<span>Cerrar Sesiòn</span>
				</div>

			</div>
		</div>
	);
}

export function BotonMotrarMenu(){

	const [menuAbierto, CambioMenuAbierto] = useState(false);

	const abrirOCerrarMenu = async function(){

		if (menuAbierto == false) {

			document.getElementById('menuAdmin').classList.add('menuAdminAbierto');
			document.getElementById('listaMenu').classList.remove('quitarResposive');
			CambioMenuAbierto(true);
		}else{

			document.getElementById('menuAdmin').classList.remove('menuAdminAbierto');
			document.getElementById('listaMenu').classList.add('quitarResposive');
			CambioMenuAbierto(false);
		}
	}

	return(
		<div class="botonMenu">
			<h5 class="tituloForm">Menu</h5>
			<img onClick={abrirOCerrarMenu} src={imgBotonMenu} class="imgBotonMenu" />
		</div>
	);
}

export function BarraDeTareas(){

	const [menuAbierto, CambioMenuAbierto] = useState(true);

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

	const [miUid, CambioMiUid] = useState(false);
	const [urlEmpresa, CambioUrlEmpresa] = useState(false);
	const [rol, CambioRol] = useState(false);
	const [busquedaNotis, CambiosBusquedaNotis] = useState(false);
	const [notis, CambionNotis] = useState([]);
	const [notisFinales, CambioNotisFinales] = useState([]);
	const [limiteNotis, CambioLimiteNotis] = useState(20);
	const [notisSinVer, CambioNotisSinVer] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaNotis == false && miUid != false) {

			consultarNotis(miUid).then(response =>{

				let lasNotis = [];
				let cero = 0;

				for (let laNoti of response) {
					
					if (cero <= limiteNotis) {

						lasNotis.push(laNoti);
					}

					if (laNoti.data.vistaCliente == false) {

						CambioNotisSinVer(true);
					}

					cero++;
				}

				CambionNotis(response);
				CambioNotisFinales(lasNotis);
			});

			CambiosBusquedaNotis(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			CambioMiUid(elUsuario.uid);
			CambioUrlEmpresa(elUsuario.urlEmpresa);
			CambioRol(elUsuario.rol);
		}
	}

	const abrirOCerrarMenu = async function(){

		if (menuAbierto == false) {

			document.getElementById('menuAdmin').classList.add('menuAdminAbierto');
			document.getElementById('listaMenu').classList.remove('quitarResposive');
			CambioMenuAbierto(true);
		}else{

			document.getElementById('menuAdmin').classList.remove('menuAdminAbierto');
			document.getElementById('listaMenu').classList.add('quitarResposive');
			CambioMenuAbierto(false);
		}
	}

	const accionListaNotis = async function(accion){

		if (accion == 'abrir') {

			document.getElementById('notisMenu2').setAttribute('style', '');
		}else{

			document.getElementById('notisMenu2').setAttribute('style', 'display: none;');
		}
	}

	const verMasNotis = async function(){

		let suma = limiteNotis + 20;

		CambioLimiteNotis(suma);

		let lasNotis = [];
		let cero = 0;

		for (let laNoti of notis) {
					
			if (cero <= suma) {

				lasNotis.push(laNoti);
			}

			cero++;
		}

		CambioNotisFinales(lasNotis);
	}

	const verNoti = async function(item){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		        '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		        '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		let dejatVistaNotis = await actualizarNoti(item);

		if (item.data.datos.tipo == 'reparacion') {

			
			if (rol == 'cliente') {

				window.location.href = '/cliente/reparacion/'+urlEmpresa+'/'+item.data.de+'/'+item.data.datos.id;
			}else if (rol == 'empresa' || rol == 'empleado') {

				window.location.href = '/'+urlEmpresa+'/reparacion/'+item.data.datos.id;
			}else{

				window.location.href = '/'+urlEmpresa;
			}
		}else{

			window.location.href = '/'+urlEmpresa;
		}
	}

	return(
		<div class="barraDeTareas">
			<img onClick={abrirOCerrarMenu} src={imgBotonMenu} class="imgBotonMenu" />
		
			<div class="perfilNotiBarraContainer">
				<div>
					<img onClick={() => accionListaNotis('abrir')} src={bellsatflow} class="bellUserImg" />
					<PuntoSinVer visto={notisSinVer} />
				</div>
				<div class="notisContainer notisBarra" id="notisMenu2" style={{display: 'none',}}>
						<div class="headerNotis">
							<span class="tituloHeaderNotis">Notificaciones</span>
						
							<img onClick={() => accionListaNotis('cerrar')} src={XNotis} class="XNotis" />
						</div>
						<div class="listaNotis">
							{notisFinales.map((item) =>(
								<div class="noti" onClick={() => verNoti(item)}>
									<img src={item.usuario.data.foto} class="imgNoti" />
									<div class="dataNoti">
										<span class="tituloNoti">{item.data.titulo}</span>
										<br/>
										<span class="infoNotis">{item.data.descripcion}</span>
										<br/>
										<span class="infoNotis">{item.data.fechaCreacion}</span>
									</div>
									<PuntoNoti visto={item.data.vistaCliente} />
								</div>
							))}
						</div>
						<FooterNotis 
							lista={notis}
							limite={limiteNotis}
							accion={verMasNotis} 
						/>
					</div>

				<img src={userSatflow} class="bellUserImg" />
			</div>
		</div>
	);
}

function PuntoNoti({visto}){

	if (visto == false) {

		return(
			<div class="puntoNoti"></div>
		);
	}else{

		return(
			<></>
		);
	}
}

function PuntoSinVer({visto}){

	if (visto == true) {

		return(
			<div class="puntoSinVer"></div>
		);
	}else{

		return(
			<></>
		);
	}
}

function FooterNotis({lista, limite, accion}){

	if (lista.length > limite) {

		return(
			<div class="footerContainer">
				<span onClick={accion} class="verMar">Ver mas</span>
			</div>
		);
	}else{

		return(
			<></>
		);
	}
}

export function MenuEmpresa({urlEmpresa}){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [menuAbierto, CambioMenuAbierto] = useState(true);
	const [miUid, CambioMiUid] = useState(false);
	const [fotoUsuario, CambioFotoUsuario] = useState('https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fdescarga.jpg?alt=media&token=2897bb03-e19b-4671-9364-b7f8e4276c88');
	const [nombreUsuario, CambioNombreUsuario] = useState('');
	const [emailUsuario, CambioEmailUsuario] = useState('');

	const [busquedaNotis, CambiosBusquedaNotis] = useState(false);
	const [notis, CambionNotis] = useState([]);
	const [notisFinales, CambioNotisFinales] = useState([]);
	const [limiteNotis, CambioLimiteNotis] = useState(20);
	const [notisSinVer, CambioNotisSinVer] = useState(false);

	const [elLogo, CambioElLogo] = useState(logoFinal1);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaNotis == false && miUid != false) {

			consultarNotis(miUid).then(response =>{

				let lasNotis = [];
				let cero = 0;

				for (let laNoti of response) {
					
					if (cero <= limiteNotis) {

						lasNotis.push(laNoti);
					}

					if (laNoti.data.vistaCliente == false) {

						CambioNotisSinVer(true);
					}

					cero++;
				}

				CambionNotis(response);
				CambioNotisFinales(lasNotis);
			});

			CambiosBusquedaNotis(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			CambioFotoUsuario(elUsuario.foto);
			CambioNombreUsuario(elUsuario.nombre);
			CambioEmailUsuario(elUsuario.email);
			CambioMiUid(elUsuario.uid);

			if (elUsuario.rol == 'empleado') {

				document.getElementById('empleados').setAttribute('style', 'display: none;');
				document.getElementById('suscripciones').setAttribute('style', 'display: none;');
				document.getElementById('pedidos').setAttribute('style', 'display: none;');
			}

			consultarLogo(urlEmpresa).then(response =>{

				if (response != false) {

					CambioElLogo(response);
				}
			});
		}
	}

	const abrirOCerrarMenu = async function(){

		if (menuAbierto == false) {

			document.getElementById('menuAdmin').classList.add('menuAdminAbierto');
			document.getElementById('listaMenu').classList.remove('quitarResposive');
			CambioMenuAbierto(true);
		}else{

			document.getElementById('menuAdmin').classList.remove('menuAdminAbierto');
			document.getElementById('listaMenu').classList.add('quitarResposive');
			CambioMenuAbierto(false);
		}
	}

	const terminarSesion = async function(){

		await localStorage.clear();
		window.location.href = '/empresa/login';
	}

	const accionListaNotis = async function(accion){

		if (accion == 'abrir') {

			document.getElementById('notisMenu').setAttribute('style', '');
		}else{

			document.getElementById('notisMenu').setAttribute('style', 'display: none;');
		}
	}

	const verMasNotis = async function(){

		let suma = limiteNotis + 20;

		CambioLimiteNotis(suma);

		let lasNotis = [];
		let cero = 0;

		for (let laNoti of notis) {
					
			if (cero <= suma) {

				lasNotis.push(laNoti);
			}

			cero++;
		}

		CambioNotisFinales(lasNotis);
	}

	const verNoti = async function(item){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		        '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		        '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		let dejatVistaNotis = await actualizarNoti(item);

		if (item.data.datos.tipo == 'reparacion') {

			window.location.href = '/'+urlEmpresa+'/reparacion/'+item.data.datos.id;
		}else{

			window.location.href = '/'+urlEmpresa;
		}
	}

	return(
		<div class="menuAdmin menuAdminAbierto" id="menuAdmin">
			<div class="headerMenu">
				<img src={elLogo} class="logoMenu" />

				<div class="bellUserContainer ocultarBell">
					<div>
						<img onClick={() => accionListaNotis('abrir')} src={bellsatflow} class="bellUserImg" />
						<PuntoSinVer visto={notisSinVer} />
					</div>
					<div class="notisContainer" id="notisMenu" style={{display: 'none',}}>
						<div class="headerNotis">
							<span class="tituloHeaderNotis">Notificaciones</span>
						
							<img onClick={() => accionListaNotis('cerrar')} src={XNotis} class="XNotis" />
						</div>
						<div class="listaNotis">
							{notisFinales.map((item) =>(
								<div class="noti" onClick={() => verNoti(item)}>
									<img src={item.usuario.data.foto} class="imgNoti" />
									<div class="dataNoti">
										<span class="tituloNoti">{item.data.titulo}</span>
										<br/>
										<span class="infoNotis">{item.data.descripcion}</span>
										<br/>
										<span class="infoNotis">{item.data.fechaCreacion}</span>
									</div>
									<PuntoNoti visto={item.data.vistaCliente} />
								</div>
							))}
						</div>
						<FooterNotis 
							lista={notis}
							limite={limiteNotis}
							accion={verMasNotis} 
						/>
					</div>

					<img onClick={() => window.location.href = '/'+urlEmpresa+'/perfil'} src={userSatflow} class="bellUserImg" />
					<img onClick={abrirOCerrarMenu} src={cerrarMenu} class="cerrarMenu"/>
				</div>
			</div>

			<div class="datosUsuarioMenu">
				<img onClick={() => window.location.href = '/'+urlEmpresa+'/perfil'} src={fotoUsuario} class="fotoUsuario" />
				<span onClick={() => window.location.href = '/'+urlEmpresa+'/perfil'} class="nombreUsuarioMenu">{nombreUsuario}</span>
				<span onClick={() => window.location.href = '/'+urlEmpresa+'/perfil'} class="emailUsuarioMenu">{emailUsuario}</span>
			</div>

			<div class="listaMenu quitarResposive" id="listaMenu">

				<div onClick={() => window.location.href = '/'+urlEmpresa} class="elementoMenu" id="home">
					<img src={imgDashboart} class="imgElementoMenu"/>

					<span>Dashboard</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/clientes'} class="elementoMenu" id="clientes">
					<img src={imgUsuarios} class="imgElementoMenu"/>

					<span>Clientes</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/reparaciones'} class="elementoMenu" id="reparaciones">
					<img src={imgReparaciones} class="imgElementoMenu"/>

					<span>Reparaciones</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/empleados'} class="elementoMenu" id="empleados">
					<img src={imgEmpleados} class="imgElementoMenu"/>

					<span>Empleados</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/suscripciones'} class="elementoMenu" id="suscripciones">
					<img src={imgSuscripciones} class="imgElementoMenu" />

					<span>Suscripciones</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/facturas'} class="elementoMenu" id="facturas">
					<img src={facturaSatflow} class="imgElementoMenu" />

					<span>Facturas</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/presupuestos'} class="elementoMenu" id="presupuestos">
					<img src={presupuestosSatflow} class="imgElementoMenu" />

					<span>Presupuestos</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/pedidos'} class="elementoMenu" id="pedidos">
					<img src={facturaMenu} class="imgElementoMenu" />

					<span>Pedidos</span>
				</div>

				<div onClick={() => window.location.href = '/'+urlEmpresa+'/blog'} class="elementoMenu" id="blog">
					<img src={blogImg} class="imgElementoMenu" />

					<span>Blog</span>
				</div>

				<div onClick={terminarSesion} class="elementoMenu">
					<img src={imgLock} class="imgElementoMenu"/>

					<span>Cerrar Sesiòn</span>
				</div>

			</div>
		</div>
	);
}

export function MenuCliente({urlEmpresa, id}){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [menuAbierto, CambioMenuAbierto] = useState(true);
	const [miUid, CambioMiUid] = useState(false);
	const [fotoUsuario, CambioFotoUsuario] = useState('https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fdescarga.jpg?alt=media&token=2897bb03-e19b-4671-9364-b7f8e4276c88');
	const [nombreUsuario, CambioNombreUsuario] = useState('');
	const [emailUsuario, CambioEmailUsuario] = useState('');

	const [busquedaNotis, CambiosBusquedaNotis] = useState(false);
	const [notis, CambionNotis] = useState([]);
	const [notisFinales, CambioNotisFinales] = useState([]);
	const [limiteNotis, CambioLimiteNotis] = useState(20);

	const [notisSinVer, CambioNotisSinVer] = useState(false);

	const [elLogo, CambioElLogo] = useState(logoFinal1);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaNotis == false && miUid != false) {

			consultarNotis(miUid).then(response =>{

				let lasNotis = [];
				let cero = 0;

				for (let laNoti of response) {
					
					if (cero <= limiteNotis) {

						lasNotis.push(laNoti);
					}

					if (laNoti.data.vistaCliente == false) {

						CambioNotisSinVer(true);
					}

					cero++;
				}

				CambionNotis(response);
				CambioNotisFinales(lasNotis);
			});

			CambiosBusquedaNotis(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			CambioFotoUsuario(elUsuario.foto);
			CambioNombreUsuario(elUsuario.nombre);
			CambioEmailUsuario(elUsuario.email);
			CambioMiUid(elUsuario.uid);

			consultarLogo(urlEmpresa).then(response =>{

				if (response != false) {

					CambioElLogo(response);
				}
			});
		}
	}

	const abrirOCerrarMenu = async function(){

		if (menuAbierto == false) {

			document.getElementById('menuAdmin').classList.add('menuAdminAbierto');
			document.getElementById('listaMenu').classList.remove('quitarResposive');
			CambioMenuAbierto(true);
		}else{

			document.getElementById('menuAdmin').classList.remove('menuAdminAbierto');
			document.getElementById('listaMenu').classList.add('quitarResposive');
			CambioMenuAbierto(false);
		}
	}

	const terminarSesion = async function(){

		await localStorage.clear();
		window.location.href = '/';
	}

	const accionListaNotis = async function(accion){

		if (accion == 'abrir') {

			document.getElementById('notisMenu').setAttribute('style', '');
		}else{

			document.getElementById('notisMenu').setAttribute('style', 'display: none;');
		}
	}

	const verMasNotis = async function(){

		let suma = limiteNotis + 20;

		CambioLimiteNotis(suma);

		let lasNotis = [];
		let cero = 0;

		for (let laNoti of notis) {
					
			if (cero <= suma) {

				lasNotis.push(laNoti);
			}

			cero++;
		}

		CambioNotisFinales(lasNotis);
	}

	const verNoti = async function(item){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		        '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		        '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		let dejatVistaNotis = await actualizarNoti(item);

		if (item.data.datos.tipo == 'reparacion') {

			window.location.href = '/cliente/reparacion/'+urlEmpresa+'/'+id+'/'+item.data.datos.id;
		}else{

			window.location.href = '/'+urlEmpresa;
		}
	}

	return(
		<div class="menuAdmin menuAdminAbierto" id="menuAdmin">
			<div class="headerMenu">
				<img src={elLogo} class="logoMenu" />

				<div class="bellUserContainer ocultarBell">
					<div>
						<img onClick={() => accionListaNotis('abrir')} src={bellsatflow} class="bellUserImg" />
						<PuntoSinVer visto={notisSinVer} />
					</div>
					<div class="notisContainer" id="notisMenu" style={{display: 'none',}}>
						<div class="headerNotis">
							<span class="tituloHeaderNotis">Notificaciones</span>
						
							<img onClick={() => accionListaNotis('cerrar')} src={XNotis} class="XNotis" />
						</div>
						<div class="listaNotis">
							{notisFinales.map((item) =>(
								<div class="noti" onClick={() => verNoti(item)}>
									<img src={item.usuario.data.foto} class="imgNoti" />
									<div class="dataNoti">
										<span class="tituloNoti">{item.data.titulo}</span>
										<br/>
										<span class="infoNotis">{item.data.descripcion}</span>
										<br/>
										<span class="infoNotis">{item.data.fechaCreacion}</span>
									</div>
									<PuntoNoti visto={item.data.vistaCliente} />
								</div>
							))}
							<FooterNotis 
								lista={notis}
								limite={limiteNotis}
								accion={verMasNotis} 
							/>
						</div>
					</div>

					<img src={userSatflow} class="bellUserImg" />
					<img onClick={abrirOCerrarMenu} src={cerrarMenu} class="cerrarMenu"/>
				</div>
			</div>

			<div class="datosUsuarioMenu">
				<img src={fotoUsuario} class="fotoUsuario" />
				<span class="nombreUsuarioMenu">{nombreUsuario}</span>
				<span class="emailUsuarioMenu">{emailUsuario}</span>
			</div>

			<div class="listaMenu quitarResposive" id="listaMenu">

				<div onClick={() => window.location.href = '/cliente/panel/'+urlEmpresa+'/'+id} class="elementoMenu" id="home">
					<img src={imgDashboart} class="imgElementoMenu"/>

					<span>Mis datos</span>
				</div>

				<div onClick={() => window.location.href = '/cliente/reparaciones/'+urlEmpresa+'/'+id} class="elementoMenu" id="reparaciones">
					<img src={imgReparaciones} class="imgElementoMenu"/>

					<span>Reparaciones</span>
				</div>

				<div onClick={terminarSesion} class="elementoMenu">
					<img src={imgLock} class="imgElementoMenu"/>

					<span>Cerrar Sesiòn</span>
				</div>

			</div>
		</div>
	);
}