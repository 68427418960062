import { useEffect, useState } from 'react';
import './empleados-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {edit, trash, flechaVer} from '../../../global/imgs.js';
import {Modal} from '../../../components/modals/modals';
import {resgistrarUsuario} from '../../../backend/auth/auth';
import {consultarUsuarios, activaODesactivarUsuario, eliminarUsuario} from '../../../backend/usuarios/usuarios';
import {ElOjo, Estado} from '../../../components/tablas/tablas';

function EmpleadosEmpresasScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

  	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

  	const [busquedaEmpleados, CambioBusquedaEmpleados] = useState(false);
  	const [empleados, CambioEmpleados] = useState([]);
  	const [empleadosFinales, CambioEmpleadosFinales] = useState([]);
  	const [limiteEmpleados, CambioLimiteEmpleados] = useState(20);

  	const [modalEmpleado, CambioModalEmpleado] = useState(false);
  	const [idEmpleado, CambioIdEmpleado] = useState(false);

  	const [estadoEmpleado, CambioEstadoEmpleado] = useState(true);

  	const [modalEstadoUsuario, CambioModalEstadoUsuario] = useState(false);
	const [preguntaModal, CambioPreguntaModal] = useState('');
	const [estadoUsuario, CambioEstadoUsuario] = useState(false);
	const [modalEliminarUsuario, CambioModalEliminarUsuario] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('empleados').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaEmpleados == false) {

			consultarUsuarios('empleado').then(response =>{

				let losUsuarios = [];
				let cero = 0;

				for (let usuario of response) {
					
					if (cero <= limiteEmpleados) {

						losUsuarios.push(usuario);
					}

					cero++;
				}

				CambioEmpleados(response);
				CambioEmpleadosFinales(losUsuarios);
			});

			CambioBusquedaEmpleados(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else if (elUsuario.rol == 'empleado') {

				terminarSesion();
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const alterarEstadoEmpleado = async function(){

		if (estadoEmpleado == true) {

			CambioEstadoEmpleado(false);
		}else{


			CambioEstadoEmpleado(true);
		}
	}

	const procesarEmpleado = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('email').value;
	    //let password = document.getElementById('password').value;
	    //let passwordConfirmar = document.getElementById('passwordConfirmar').value;
	    let nombre = document.getElementById('nombre').value;
	    let telefono = document.getElementById('telefono').value;
	    let DNIoNIF = document.getElementById('DNIoNIF').value;
	    let direccion = document.getElementById('direccion').value;

	    let rolEmpleado = document.getElementById('rolEmpleado').value;

	    let elUsuario = await validarUsuario();

	    if (email === '' || nombre === '' || telefono === '' || DNIoNIF === '' || direccion === '' || rolEmpleado === '') {

	    	Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
	    }else if (isNaN(telefono)) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'El telefono debe ser numerico.',
		    });
	    }else if (elUsuario == false) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'No hay usuario autenticado.',
		    });
	    }else{

	    	let datos = {
	    		activo: estadoEmpleado,
	    		email: email,
				password: '12345678',
				nombre: nombre,
				telefono: telefono,
				DNIoNIF: DNIoNIF,
				direccion: direccion,
				rol: 'empleado',
				rolEmpleado: rolEmpleado,
				idEmpresa: elUsuario.uid,
				urlEmpresa: urlEmpresa,
	    	};

	    	resgistrarUsuario(datos).then(response =>{

		        if (response == 'ya existe') {

		        	Swal.fire({
		            	icon: 'error',
		            	title: 'El usuario ingresado ya existe.',
		          	});
		        }else if (response != false) {

		          	Swal.fire({
		            	icon: 'success',
		            	title: 'Datos guardados.',
		          	});

		          	CambioModalEmpleado(false);
					CambioIdEmpleado(false);
					CambioBusquedaEmpleados(false);
		        }else{

		          	Swal.fire({
		            	icon: 'error',
		            	title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
		          	});
		        }
		    });
	    }
	}

	const modificarEstadoUsuario = async function(item){

		if (item.data.activo == true) {

			CambioPreguntaModal('¿Desea desactivar este empleado?');
		}else if (item.data.activo == false) {

			CambioPreguntaModal('¿Desea activar este empleado?');
		}

		CambioIdEmpleado(item.id);
		CambioEstadoEmpleado(item.data.activo);
		CambioModalEstadoUsuario(true);
	}

	const alterarEstadoUsuario = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    activaODesactivarUsuario(idEmpleado, estadoEmpleado).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Empleado actualizado.',
			});

			CambioModalEstadoUsuario(false);
			CambioIdEmpleado(false);
			CambioBusquedaEmpleados(false);
	    })
	}

	const preguntarEliminarUsuario = async function(item){

		CambioPreguntaModal('¿Desea eliminar este empleado?');
		CambioIdEmpleado(item.id);
		CambioModalEliminarUsuario(true);
	}

	const procederEliminarUsuario = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    eliminarUsuario(idEmpleado).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Empleado eliminado.',
			});

			CambioModalEliminarUsuario(false);
			CambioIdEmpleado(false);
			CambioBusquedaEmpleados(false);
	    })
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

						<div class="row">
							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla">
										<h5 class="tituloForm">Empleados</h5>
									<button onClick={() => CambioModalEmpleado(true)} class="btn btn-primary botonRepararTuPC widthContent">Añadir Empleado</button>
									</div>
									<div class="formulario">
										<table class="table table-hover">
											<tr>
										      	<th class="th" scope="row">Nombre</th>
										      	<th class="th">Email</th>
										      	<th class="th">Rol en la Empresa</th>
										      	<th class="th">Estado</th>
										      	<th class="th">Acciones</th>
										    </tr>

										    {empleadosFinales.map((item) =>(
										    	<tr class="bordeTr">
											      	<td class="elTr" scope="row">{item.data.nombre}</td>
											      	<td class="elTr">{item.data.email}</td>
											      	<td class="elTr">{item.data.rolEmpleado}</td>
											      	<td class="elTr">
											      		<Estado
											      			activo={item.data.activo}
											      		/>
											      	</td>
											      	<td class="elTr">
											      		<ElOjo
											      			estado={item.data.activo}
											      			accion={() => modificarEstadoUsuario(item)}
											      		/>
											      		<img onClick={() => preguntarEliminarUsuario(item)} src={trash} class="eye" />
											      		<img onClick={() => window.location.href = '/'+urlEmpresa+'/empleado/'+item.id} src={flechaVer} class="eye" />
											      	</td>
											    </tr>
										    ))}
										    
										</table>
									</div>
								</div>

							</div>
						</div>

				</div>

			</div>

			<Modal mostrar={modalEmpleado}>

				<p onClick={() => {
					CambioModalEmpleado(false);
					CambioIdEmpleado(false);
				}} class="equix">X</p>
				<div class="formContainer formGestion">
					<div class="headerForm">
						<h5 class="tituloForm">Gestionar Empleado</h5>
					</div>
					<div class="formulario">

						<fieldset>

							<legend>Estado del empleado</legend>

							<div class="form-check" style={{textAlign: 'left',}}>
						        <input class="form-check-input" type="checkbox" value="" id="estado" onChange={alterarEstadoEmpleado} checked={estadoEmpleado} />
						        <label class="form-check-label" for="estado">
						         	Activo
						        </label>
						    </div>

						</fieldset><br/>

						<div class="form-group">
							<label for="email" class="form-label mt-4 labelSatflow">Nombre**</label>
							<input type="text" class="inputSatflow" id="nombre" />
						</div>

						<div class="form-group">
							<label for="email" class="form-label mt-4 labelSatflow">Email*</label>
							<input type="text" class="inputSatflow" id="email" />
						</div>

						<div class="form-group">
							<label for="email" class="form-label mt-4 labelSatflow">Rol en la empresa*</label>
							<input type="text" class="inputSatflow" id="rolEmpleado" />
						</div>

						<div class="form-group">
							<label for="email" class="form-label mt-4 labelSatflow">Teléfono*</label>
							<input type="text" class="inputSatflow" id="telefono" />
						</div>

						<div class="form-group">
							<label for="email" class="form-label mt-4 labelSatflow">DNI / NIF*</label>
							<input type="text" class="inputSatflow" id="DNIoNIF" />
						</div>

						<div class="form-group">
							<label for="email" class="form-label mt-4 labelSatflow">Dirección*</label>
							<textarea class="inputSatflow" id="direccion" rows="3"></textarea>
						</div>

						<br/>

						<button onClick={procesarEmpleado} class="btn btn-primary botonRepararTuPC">Guardar</button>
								
					</div>
				</div>

			</Modal>

			<Modal mostrar={modalEstadoUsuario}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={alterarEstadoUsuario} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEstadoUsuario(false);
				    	CambioIdEmpleado(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

			<Modal mostrar={modalEliminarUsuario}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={procederEliminarUsuario} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEliminarUsuario(false);
				    	CambioIdEmpleado(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

		</AnimatedPage>
	);
}

export default EmpleadosEmpresasScreen;