import { useEffect, useState } from 'react';
import './home-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import {MenuAdmin, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {consultarUsuarios, activaODesactivarUsuario, eliminarUsuario} from '../../../backend/usuarios/usuarios';
import {ElOjo} from '../../../components/tablas/tablas';
import {trash} from '../../../global/imgs.js';
import {Modal} from '../../../components/modals/modals';

function HomeAdminScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [busquedaUsuarios, CambioBusquedaUsuarios] = useState(false);
	const [usuarios, CambioUsuarios] = useState([]);
	const [usuariosFinales, CambioUsuariosFinales] = useState([]);
	const [limiteUsuarios, CambioLimiteUsuarios] = useState(20);
	const [modalEstadoUsuario, CambioModalEstadoUsuario] = useState(false);
	const [preguntaModal, CambioPreguntaModal] = useState('');
	const [estadoUsuario, CambioEstadoUsuario] = useState(false);
	const [idUsuario, CambioIdUsuario] = useState(false);
	const [modalEliminarUsuario, CambioModalEliminarUsuario] = useState(false);
	
	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('home').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaUsuarios == false) {

			consultarUsuarios('empresa').then(response =>{

				let losUsuarios = [];
				let cero = 0;

				for (let usuario of response) {
					
					if (cero <= limiteUsuarios) {

						losUsuarios.push(usuario);
					}

					cero++;
				}

				CambioUsuarios(response);
				CambioUsuariosFinales(losUsuarios);
			});

			CambioBusquedaUsuarios(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'empresa' || elUsuario.rol == 'empleado') {

				window.location.href = '/'+elUsuario.urlEmpresa;
			}
		}else{

			window.location.href = '/admin/login';
		}
	}

	const modificarEstadoUsuario = async function(item){

		if (item.data.activo == true) {

			CambioPreguntaModal('¿Desea desactivar este usuario?');
		}else if (item.data.activo == false) {

			CambioPreguntaModal('¿Desea activar este usuario?');
		}

		CambioIdUsuario(item.id);
		CambioEstadoUsuario(item.data.activo);
		CambioModalEstadoUsuario(true);
	}

	const alterarEstadoUsuario = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    activaODesactivarUsuario(idUsuario, estadoUsuario).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Usuario actualizada.',
			});

			CambioModalEstadoUsuario(false);
			CambioIdUsuario(false);
			CambioBusquedaUsuarios(false);
	    })
	}

	const preguntarEliminarUsuario = async function(item){

		CambioPreguntaModal('¿Desea eliminar este usuario?');
		CambioIdUsuario(item.id);
		CambioModalEliminarUsuario(true);
	}

	const procederEliminarUsuario = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    eliminarUsuario(idUsuario).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Usuario eliminado.',
			});

			CambioModalEliminarUsuario(false);
			CambioIdUsuario(false);
			CambioBusquedaUsuarios(false);
	    })
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuAdmin/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

					<div class="row">
						<div class="col-sm">

							<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla">
										<h5 class="tituloForm">Usuarios</h5>

										<div class="form-group">
										    <label for="rolUsuarios" class="form-label mt-4">Tipo de usuarios</label>
										    <select class="form-select" id="rolUsuarios">
										        <option value="">Todos</option>
										        <option value="cliente">Cliente</option>
										        <option value="empresa">Empresas</option>
										        <option value="subAdmin">Sub-Administradores</option>
										    </select>
										</div>
									</div>
									<br/>
									<div class="formulario">
										<table class="table table-hover">
											<thead class="thead">
												<tr>
											      	<th class="th" scope="row">Nombre</th>
											      	<th class="th">Dirección</th>
											      	<th class="th">Telefono</th>
											      	<th class="th">Acciones</th>
											    </tr>
										    </thead>
										    {usuariosFinales.map((item) =>(
										    	<tr class="bordeTr">
											      	<td class="elTr" scope="row">{item.data.nombre}</td>
											      	<td class="elTr">{item.data.direccion}</td>
											      	<td class="elTr">{item.data.telefono}</td>
											      	<td class="elTr">
											      		<ElOjo
											      			estado={item.data.activo}
											      			accion={() => modificarEstadoUsuario(item)}
											      		/>
											      		<img onClick={() => preguntarEliminarUsuario(item)} src={trash} class="eye" />
											      	</td>
											    </tr>
										    ))}
										</table>
									</div>
							</div>

						</div>
					</div>

				</div>

			</div>

			<Modal mostrar={modalEstadoUsuario}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={alterarEstadoUsuario} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEstadoUsuario(false);
				    	CambioIdUsuario(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

			<Modal mostrar={modalEliminarUsuario}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={procederEliminarUsuario} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEliminarUsuario(false);
				    	CambioIdUsuario(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

		</AnimatedPage>
	);
}

export default HomeAdminScreen;