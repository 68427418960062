import { useEffect, useState } from 'react';
import './home-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {consultarReparaciones} from '../../../backend/reparaciones/reparaciones';
import {consultarUsuarios} from '../../../backend/usuarios/usuarios';
import {Chart as ChartJS} from 'chart.js/auto';
import {Bar, Doughnut, line} from 'react-chartjs-2';
import {meses} from '../../../global/datosCalendario';
 
function HomeEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

	const [logoPuesto, CambioLogoPuesto] = useState(false);

	const [busquedaEstadistica, CambioBusquedaEstadistica] = useState(false);
	const [reparaciones, CambioReparaciones] = useState([]);
	const [clientes, CambioClientes] = useState([]);
	const [empleados, CambioEmpleados] = useState([]);
	const [presupuestos, CambioPresupuestos] = useState([]);

	const [graficoPuesto, CambioGraficoPuesto] = useState(false);
	const [tipoGrafico, CambioTipoGrafico] = useState('reparaciones');
	const [labelGrafico, CambioLabelGrafico] = useState('Reparaciones');
	const [datosGrafico, CambioDatosGrafico] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

	const [losLabels, CambioLosLabels] = useState(['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('home').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaEstadistica == false) {

			consultarReparaciones(urlEmpresa).then(response =>{

				CambioReparaciones(response);

				if (tipoGrafico == 'reparaciones') {

					ponerGrafico('reparaciones', response);
				}
			});

			consultarUsuarios('cliente').then(response =>{

				CambioClientes(response);

				if (tipoGrafico == 'clientes') {

					ponerGrafico('clientes', response);
				}
			});

			consultarUsuarios('empleado').then(response =>{

				CambioEmpleados(response);

				if (tipoGrafico == 'empleados') {

					ponerGrafico('empleados', response);
				}
			});

			CambioBusquedaEstadistica(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const recargar = async function(){

		window.location.reload();
	}

	const ponerGrafico = async function(tipo, datos){ 

		let anoActual = document.getElementById('anoGrafico').value; console.log(anoActual);
		let mesesGrafico = document.getElementById('mesesGrafico').value;

		if (mesesGrafico != 'todos') {

			let losDatosGrafico = [];
			let diasMes = [];

			let elMes = meses[parseInt(mesesGrafico)];
		
			let ultimoDia = 0;
			let bisiesto = parseInt(anoActual) % 4;

			if (elMes.nombre == 'February' && bisiesto == 0) {

				ultimoDia = 29;
			}else{

				ultimoDia = elMes.dias;
			}

			let calcularDatosMes = await datosMes(ultimoDia);
		
			let labelDeDias = calcularDatosMes;
			let datosLosDias = calcularDatosMes;

			let separacionDeDias = [];

			for (let i = 1; i <= labelDeDias.length; i++) {
				
				let separacion = await separarDiasDelMes(i, datos, elMes.numeroMes, anoActual);

				separacionDeDias.push(separacion.length);
			}

			CambioLosLabels(labelDeDias);
			CambioDatosGrafico(separacionDeDias);
		}else{

			let ene = [];
			let feb = [];
			let mar = [];
			let abr = [];
			let may = [];
			let jun = [];
			let jul = [];
			let ago = [];
			let sep = [];
			let oct = [];
			let nov = [];
			let dic = [];

			for (let dato of datos) {
					
				let fecha = dato.data.fechaCreacion;

				if (fecha.includes(anoActual)) {

					if (fecha.includes(anoActual+'-01')) {

						ene.push(1);
					}else if (fecha.includes(anoActual+'-02')) {

						feb.push(1);
					}else if (fecha.includes(anoActual+'-03')) {

						mar.push(1);
					}else if (fecha.includes(anoActual+'-04')) {

						abr.push(1);
					}else if (fecha.includes(anoActual+'-05')) {

						may.push(1);
					}else if (fecha.includes(anoActual+'-06')) {

						jun.push(1);
					}else if (fecha.includes(anoActual+'-07')) {

						jul.push(1);
					}else if (fecha.includes(anoActual+'-08')) {

						ago.push(1);
					}else if (fecha.includes(anoActual+'-09')) {

						sep.push(1);
					}else if (fecha.includes(anoActual+'-10')) {

						oct.push(1);
					}else if (fecha.includes(anoActual+'-11')) {

						nov.push(1);
					}else if (fecha.includes(anoActual+'-12')) {

						dic.push(1);
					}
				}
			}

			let datosFinales = [ene.length, feb.length, mar.length, abr.length, may.length, jun.length, jul.length, ago.length, sep.length, oct.length, nov.length, dic.length];

			CambioLosLabels(['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']);
			CambioDatosGrafico(datosFinales);
		}
	}

	const datosMes = async function(ultimoDia){

		let losDias = [];

		for (let i = 1; i <= ultimoDia; i++) {
			
			losDias.push(i);
		}

		return losDias;
	}

	const separarDiasDelMes = async function(numeroDia, datos, numeroMes, anoActual){

		let dia = '';

		if (numeroDia < 10) {

			dia = '0'+numeroDia;
		}else{

			dia = numeroDia;
		}

		let dias = [];

		for (let dato of datos) {
					
			let fecha = dato.data.fechaCreacion;

			if (fecha.includes(anoActual)) {

				if (fecha.includes(anoActual+'-'+numeroMes+'-'+dia)) {

					dias.push(dato.data.fechaCreacion);
				}
			}
		}

		return dias;
	}

	const verOtroGrafico = async function(){

		let selectGrafico = document.getElementById('tipoGrafico').value;

		if (selectGrafico == 'reparaciones') {

			CambioTipoGrafico(selectGrafico);
			CambioLabelGrafico('Reparaciones');

			ponerGrafico(selectGrafico, reparaciones);
		}else if (selectGrafico == 'clientes') {

			CambioTipoGrafico(selectGrafico);
			CambioLabelGrafico('Clientes');

			ponerGrafico(selectGrafico, clientes);
		}else if (selectGrafico == 'empleados') {

			CambioTipoGrafico(selectGrafico);
			CambioLabelGrafico('Empleados');

			ponerGrafico(selectGrafico, empleados);
		}else if (selectGrafico == 'presupuestos') {

			CambioTipoGrafico(selectGrafico);
			CambioLabelGrafico('Presupuestos');

			ponerGrafico(selectGrafico, presupuestos);
		}
	}

	const verYearGrafico = async function(){

		if (tipoGrafico == 'reparaciones') {

			ponerGrafico(tipoGrafico, reparaciones);
		}else if (tipoGrafico == 'clientes') {

			ponerGrafico(tipoGrafico, clientes);
		}else if (tipoGrafico == 'empleados') {

			ponerGrafico(tipoGrafico, empleados);
		}else if (tipoGrafico == 'presupuestos') {

			ponerGrafico(tipoGrafico, presupuestos);
		}
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					<div class="container"><br/>

						<div class="row">
						
							<div class="col-sm">

								<div class="cantidades">

									<div class="laCatidadDashboard">
										<span class="tituloCantidad">Reparaciones</span>

										<h1 class="numeroCantidadHome" style={{color: '#3b82f6',}}>{reparaciones.length}</h1>
										<p></p>
									</div>

									<div class="laCatidadDashboard">
										<span class="tituloCantidad">Clientes</span>

										<h1 class="numeroCantidadHome" style={{color: '#ef4444',}}>{clientes.length}</h1>
										<p></p>
									</div>

									<div class="laCatidadDashboard">
										<span class="tituloCantidad">Empleados</span>

										<h1 class="numeroCantidadHome" style={{color: '#f59e0b',}}>{empleados.length}</h1>
										<p></p>
									</div>

									<div class="laCatidadDashboard">
										<span class="tituloCantidad">Presupuestos</span>

										<h1 class="numeroCantidadHome" style={{color: '#22c55e',}}>{presupuestos.length}</h1>
										<p></p>
									</div>

								</div>

							</div>

						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="estadisticasDashboard">

									<div class="opcionesGrafico">
										<div class="divOpcioGrafico">
											<div>
											    <label for="tipoGrafico" class="form-label mt-4">Grafico de</label>
											    <select onChange={verOtroGrafico} class="form-select" id="tipoGrafico">
											        <option value="reparaciones">Reparaciones</option>
											        <option value="clientes">Clientes</option>
											        <option value="empleados">Empleados</option>
											        <option value="presupuestos">Presupuestos</option>
											    </select>
											 </div>
										</div>

										<div class="divOpcioGrafico">
											<div>
											    <label for="mesesGrafico" class="form-label mt-4">Ver por mes</label>
											    <select onChange={verYearGrafico} class="form-select" id="mesesGrafico">
											        <option value="todos">Todos</option>
											        <option value="0">Enero</option>
											        <option value="1">Febrero</option>
											        <option value="2">Marzo</option>
											        <option value="3">Abril</option>
											        <option value="4">Mayo</option>
											        <option value="5">Junio</option>
											        <option value="6">Julio</option>
											        <option value="7">Agosto</option>
											        <option value="8">Septiembre</option>
											        <option value="9">Octubre</option>
											        <option value="10">Noviembre</option>
											        <option value="11">Diciembre</option>
											    </select>
											 </div>
										</div>

										<div class="divOpcioGrafico">
											<div>
											    <label for="anoGrafico" class="form-label mt-4">Ver por</label>
											    <select onChange={verYearGrafico} class="form-select" id="anoGrafico">
											        <option value="2024">Año 2024</option>
											        <option value="2023">Año 2023</option>
											        <option value="2022">Año 2022</option>
											        <option value="2021">Año 2021</option>
											        <option value="2020">Año 2020</option>
											    </select>
											 </div>
										</div>
									</div>

									<Bar
										data={{
											labels: losLabels,
											datasets: [
												{
													label: labelGrafico,
													data: datosGrafico,
												},
											],
										}}
									/>

								</div>

							</div>
						</div><br/><br/>

					</div>

				</div>

			</div>

		</AnimatedPage>
	);
}

export default HomeEmpresaScreen;