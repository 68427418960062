import { useEffect, useState } from 'react';
import './blog-styles.css';
import {datosSuscripcion} from '../../backend/suscripciones/suscripciones';
import {consultarReacciones} from '../../backend/blog/blog';
import {corazonImgActive} from '../../global/imgs';
import ClipLoader from "react-spinners/ClipLoader";

export function BotonPublicarBlog({urlEmpresa, suscripcion, accion}){

	const [pantalla, CambioPantalla] = useState('');

	const [busquedaSuscripcion, CambioBusquedaSuscripcion] = useState(false);

	useEffect(() =>{

		if (busquedaSuscripcion == false && suscripcion != false) { 

				datosSuscripcion(suscripcion).then(response =>{

					if (response != false) {

						if (response.data.funcionesForo != 'soloLectura') {

							CambioPantalla('boton');
						}
					}
				});

				CambioBusquedaSuscripcion(true);
		}
	});

	if (pantalla == 'boton') {

		return(
			<button onClick={accion} class="btn btn-primary botonRepararTuPC" style={{width: 'fit-content',}}>Publicar</button>
		);
	}else{

		return (
			<span class="msjSuscrip">
				No puedes publicar<br/>
				(<span onClick={() => window.location.href = '/'+urlEmpresa+'/suscripciones'} style={{color: '#685dd8',}}>Cambiar suscripción</span>)
			</span>
		);
	}
}

export function ContenidoBlog({foto, blog}){

	if (foto != false) {

		return(
			<>
				<span class="textoPubli">{blog.data.texto}</span>
				<img
					src={blog.data.foto}
					class="fotoPubli"
				/>
			</>
		);
	}else{

		return(
			<>
				<h4 class="soloTextoPubli">{blog.data.texto}</h4>
			</>
		);
	}
}

export function BotonAccionBlog({
	idBlog,
	uid,
	img,
	texto,
	accion,
	tipo,
}){

	const [busquedaReacciones, CambioBusquedaReacciones] = useState(false);

	const [imgLaPubli, CambioImgLaPubli] = useState(img);
	const [textoLaPubli, CambioTextoLaPubli] = useState(texto);
	const [cantidad, CambioCantidad] = useState(0);
	const [idReaccion, CambioIdReaccion] = useState(false);

	useEffect(() =>{

		if (busquedaReacciones == false) {

			consultarReacciones(idBlog, tipo).then(response =>{

				for (let reaccion of response) {
					
					if (reaccion.reaccion.data.uid == uid && tipo == 'meGusta') {

						CambioImgLaPubli(corazonImgActive);
						CambioTextoLaPubli('Te gusta');
						CambioIdReaccion(reaccion.reaccion.id);
					}
				}

				CambioCantidad(response.length);
			});

			CambioBusquedaReacciones(true);
		}
	});

	const ejecutarAccion = async function(){

		if (tipo == 'meGusta') {

			await accion(idBlog, idReaccion, tipo);

			CambioImgLaPubli(img);
			CambioTextoLaPubli(texto);
			CambioIdReaccion(false);

			CambioBusquedaReacciones(false);
		}else if (tipo == 'comentario') {

			accion();
		}
	}

	return(
		<div onClick={ejecutarAccion} class="botonAccionPubli">
			<img
				src={imgLaPubli}
				class="imgAccionPubli"
			/>
			<span class="nombreUsuarioPubli">{textoLaPubli} ({cantidad})</span>
		</div>
	);
}

export function ComentarioPubli({
	idBlog,
	mostrarAhora,
	fotoUsuario,
	urlEmpresa,
	suscripcion,
	comentar,
}){

	const [mostrar, CambioMostrar] = useState(false);

	const [busquedaComentarios, CambioBusquedaComentarios] = useState(false);
	const [comentarios, CambioComentarios] = useState([]);
	const [comentariosFinales, CambioComentariosFinales] = useState([]);
	const [limiteComentarios, CambioLimiteComentarios] = useState(20);
	
	useEffect(() =>{

		if (busquedaComentarios == false && mostrarAhora == true) {

			CambioMostrar('loading');

			consultarReacciones(idBlog, 'comentario').then(response =>{

				let losComentarios = [];
				let cero = 0;

				for (let comentario of response) {
					
					if (cero <= limiteComentarios) {

						losComentarios.push(comentario);
					}

					cero++;
				}

				CambioComentarios(response);
				CambioComentariosFinales(losComentarios);
				CambioMostrar(true);
			});

			CambioBusquedaComentarios(true);
		}
	});

	if (mostrar == true) {

		return(
			<>
				<div class="formBlog">
					<h3 class="tituloFormBlog" style={{textAlign: 'left',}}>Crear comentario</h3>
								
					<div class="contentPlublicacion">

						<img
							src={fotoUsuario}
							class="fotoUsuarioBlog"
						/>

						<div class="form-group" style={{display: 'inline-block', width: '82%',}}>
							<input type="text" class="inputSatflow" id="inputComentario" placeholder="Escribe tu comentario" />
						</div>

					</div>

					<div class="accionesPublicar">
						<span></span>

						<BotonPublicarBlog
							urlEmpresa={urlEmpresa}
							suscripcion={suscripcion}
							accion={() => {

								comentar().then(response =>{

									if (response == true) {

										CambioBusquedaComentarios(false);
									}
								});
							}}
						/>
					</div>
				</div>
				<hr/>
				<div class="comentarios">
					{comentariosFinales.map((item) =>(
						<div class="comentario">
							<img
								src={item.usuario.data.foto}
								class="fotoUsuarioBlog"
							/>
							<span class="elTextoComentario">
								<span class="nombreUseComentario">{item.usuario.data.nombre} </span>
								{item.reaccion.data.texto}<br/>
								<span class="fechaComentario">{item.reaccion.data.fecha}</span>
							</span>
						</div>
					))}
				</div>
			</>
		);
	}else if (mostrar == 'loading') {

		return(
			<>
				<div style={{width: '100%', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
	          		<ClipLoader
				        color={'#7367f0'}
				        loading={true}
				        size={20}
				    />
	          	</div>
			</>
		);
	}else{

		return(
			<></>
		);
	}
}