import { useEffect, useState } from 'react';
import './facturas-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {Modal} from '../../../components/modals/modals';
import {descargarImg} from '../../../global/imgs.js';
import {consultarUsuarios} from '../../../backend/usuarios/usuarios';
import {fechaHoy} from '../../../global/datosCalendario.js';
import {consultarReparaciones} from '../../../backend/reparaciones/reparaciones';
import {datosReparacion, datosOrdenador} from '../../../backend/reparaciones/reparaciones';
import {datosUsuario} from '../../../backend/usuarios/usuarios';
import {validarFactura, crearFactura} from '../../../backend/facturas/facturas';
import {presupuestoSegunReparacion} from '../../../backend/presupuestos/presupuestos';

function FacturasEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

  	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

	const [busquedaReparaciones, CambioBusquedaReparaciones] = useState(false);
	const [reparaciones, CambioReparaciones] = useState([]);
	const [reparacionesFinales, CambioReparacionesFinales] = useState([]);
	const [limiteReparaciones, CambioLimiteReparaciones] = useState(30);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('facturas').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaReparaciones == false) {

			consultarReparaciones(urlEmpresa).then(response =>{

				let lasReparaciones = [];
				let cero = 0;

				for (let reparacion of response) {
					
					if (cero <= limiteReparaciones) {

						lasReparaciones.push(reparacion);
					}

					cero++;
				}

				lasReparaciones.sort(function(a, b) { 
					a = a.data.fechaCreacion; 
					b = b.data.fechaCreacion; 
					return a>b ? -1 : a<b ? 1 : 0; 
				});

				CambioReparaciones(response);
				CambioReparacionesFinales(lasReparaciones);
			});

			CambioBusquedaReparaciones(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

					if (elUsuario.rol == 'empleado') {

						document.getElementById('empleados').setAttribute('style', 'display: none;');
					}

					Swal.fire({
				      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
				          	'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
				          	'</div>',
				      	showConfirmButton: false,
				      	allowOutsideClick: false,
				      	allowEnterKey: false,
				      	allowEscapeKey: false,
				    });

				    Swal.close();
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const buscarReparacion = async function(){

		let query = document.getElementById('buscarReparacion').value;

	    if (query === '') {

	    	let lasReparaciones = [];
	    	let cero = 0;

	    	for (let reparacion of reparaciones) {
	    		
	    		if (cero <= limiteReparaciones) {

	    			lasReparaciones.push(reparacion);
	    		}

	    		cero++;
	    	}

	    	CambioReparacionesFinales(lasReparaciones);
	    }else{
	        
	        let resultado = [];

	        const datos = reparaciones;

	        const regex = new RegExp(`${query.trim()}`, 'i');

	        const laBusqueda = datos.filter(datos => datos.data.numeroFolio.search(regex) >= 0);

	        let lasReparaciones = [];
			let cero = 0;

			for (let reparacion of laBusqueda) {
					
				if (cero <= limiteReparaciones) {

					lasReparaciones.push(reparacion);
				}

				cero++;
			}

			/*if (laBusqueda.length > limiteUsuarios) {

				document.getElementById('botonVerMas').classList.remove('ocultarVerMas');
			}else{

				document.getElementById('botonVerMas').classList.add('ocultarVerMas');
			}*/

			CambioReparacionesFinales(lasReparaciones);
	    }
	}

	const verFactura = async function(item){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		        '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		    '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		let usuario = await datosUsuario(item.data.idCliente);
		let tecnico = await datosUsuario(item.data.uid);
		let ordenador = await datosOrdenador(item.data.idCliente);

		let empresa = '';
		let total = 0;
		let subTotal = 0;

		if (tecnico.data.rol == 'empleado') {

			let dataEmpresa = await datosUsuario(tecnico.data.idEmpresa);

			if (dataEmpresa != false) {

				empresa = dataEmpresa.data.nombre;
			}
		}else{

			empresa = tecnico.data.nombre;
		}

		let presupuesto = await presupuestoSegunReparacion(item.id);

		if (presupuesto != false) {

			let costoFinal = await calcularTotalInicial(presupuesto.data.costos);

			total = costoFinal.total;
			subTotal = costoFinal.subTotal;
		}

		if (usuario != false && tecnico != false && ordenador != false) {

			let validarSiFacturaExiste = await validarFactura(item.id); console.log(validarSiFacturaExiste);

			if (validarSiFacturaExiste != false) {

				window.open(
		    		'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/factura.php?'+
		    		'numeroFactura='+validarSiFacturaExiste.data.numeroFactura+
		    		'&fechaMostrar='+validarSiFacturaExiste.data.fecha+
		    		'&nombre='+usuario.data.nombre+
					'&email='+usuario.data.email+
					'&direccion='+usuario.data.direccion+
					'&telefono='+usuario.data.telefono+
					'&identificacion='+usuario.data.DNIoNIF+
					'&nombreProducto='+ordenador.data.marcaDelOrdenador+
					'&costoProducto='+subTotal+'€'+
					'&total='+total+'€'+
					'&formaPago=nada'+
		    		'&empresa='+empresa+
					'&nombreTecnico='+tecnico.data.nombre+
					'&DNITecnico='+tecnico.data.DNIoNIF+
					'&direccionTecnico='+tecnico.data.direccion+
					'&emailTecnico='+tecnico.data.email,
		    		'_blank'
		    	);
			}else{

				let fecha = await fechaHoy();

				let date = new Date();

				let ano = date.getFullYear();

				let datos = {
					idReparacion: item.id,
					fecha: fecha,
					ano: ano,
					urlEmpresa: urlEmpresa,
				};

				crearFactura(datos).then(response =>{

					window.open(
		    			'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/factura.php?'+
		    			'numeroFactura='+response.numeroFactura+
		    			'&fechaMostrar='+response.fecha+
		    			'&nombre='+usuario.nombre+
						'&email='+usuario.email+
						'&direccion='+usuario.direccion+
						'&telefono='+usuario.telefono+
						'&identificacion='+usuario.DNIoNIF+
						'&nombreProducto='+ordenador.data.marcaDelOrdenador+
						'&costoProducto='+subTotal+'€'+
						'&total='+total+'€'+
						'&formaPago=nada'+
		    			'&empresa='+empresa+
						'&nombreTecnico='+tecnico.data.nombre+
						'&DNITecnico='+tecnico.data.DNIoNIF+
						'&direccionTecnico='+tecnico.data.direccion+
						'&emailTecnico='+tecnico.data.email,
		    			'_blank'
		    		);
				})
			}

			Swal.close();
		}else{

			Swal.close();
		}
	}

	const calcularTotalInicial = async function(losCostos){

		let elTotal = 0;
		let elSubTotal = 0;

		for (let elCosto of losCostos) {
			
			let suma = elSubTotal + parseFloat(elCosto.costo);

			elSubTotal = suma;
		}

		let iva = elSubTotal * 0.21;

		elTotal = elSubTotal + iva;

		
		return {
			total: elTotal,
			subTotal: elSubTotal,
		};
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

						<div class="row">
							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Facturas</h5>
										<div class="form-group">
											<input type="text" onChange={buscarReparacion} class="form-control" id="buscarReparacion" placeholder="Buscar por ID de reparación" />
										</div>
									</div>
									<div class="formulario">
										<table class="table table-hover">
											<tr>
										      	<th class="th" scope="row">ID</th>
										      	<th class="th">Cliente</th>
										      	<th class="th">Descripción</th>
										      	<th class="th">Técnico Responsable</th>
										      	<th class="th">Tiempo Estimado</th>
										      	<th class="th">Acciones</th>
										    </tr>

										    {reparacionesFinales.map((item) =>(
										    	<tr class="bordeTr">
										    		<td class="elTr" scope="row">{item.data.numeroFolio}</td>
											      	<td class="elTr">{item.data.cliente}</td>
											      	<td class="elTr">{item.data.descripcion}</td>
											      	<td class="elTr">{item.data.tecnico}</td>
											      	<td class="elTr">{item.data.tiempoEstimado} día(s)</td>
											      	<td class="elTr">
											      		<img onClick={() => verFactura(item)} src={descargarImg} class="eye" />
												    </td>
											    </tr>
										    ))}
										    
										</table>
									</div>
								</div>

							</div>
						</div>

				</div>

			</div>

		</AnimatedPage>
	);
}

export default FacturasEmpresaScreen;