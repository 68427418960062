export const imgBotonMenu = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fbars.png?alt=media&token=aeeb65c2-6b88-40e6-99a7-929ed790f559';

export const cerrarMenu = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2FcerrarMenu.png?alt=media&token=a6704dba-91f1-45cb-943d-9396f5c7b5a5';

export const folder = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Ffolder.png?alt=media&token=f4252f69-7450-4d61-a444-ba94a072820c';

export const cerrarSesion = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2FcerrarSesion.png?alt=media&token=2c627270-bea0-40e6-a266-0d555a8cab80';

export const suscripciones = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fsuscripciones.png?alt=media&token=86d34d07-ba07-409c-a030-09315705f921';

export const eye = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Ftablas%2Feye.png?alt=media&token=086e2a33-3048-4dba-9e12-87f043d8ec9a';

export const eyeCerrado = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Ftablas%2FeyeCerrado.png?alt=media&token=8318bab0-88f2-407d-93b4-f45388186067';

export const edit = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Ftablas%2Fedit.png?alt=media&token=0d107ebd-0267-4aba-ba4c-411d2f8c6094';

export const trash = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Ftablas%2Ftrash.png?alt=media&token=e1b4718b-2b16-42dc-a022-d598e29299a0';

export const flechaVer = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Ftablas%2Fver.png?alt=media&token=c98404e8-5d2a-48b5-b87f-1d5979f6a97a';

export const profileBanner = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fprofile-banner.png?alt=media&token=b11f58a4-b45f-478d-838e-b61b17daa5f5';

export const logoLogin = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Flogin%2Flogo.svg?alt=media&token=0d8a81bc-3743-4157-98a4-1ae2c7750444';

export const logoFinal1 = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Flogin%2FImagen%20de%20WhatsApp%202024-03-29%20a%20las%2010.32.30_1b7741e4.jpg?alt=media&token=d1854757-7650-421c-bd48-23af5e2254f7';

export const logoFinal2 = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Flogin%2FImagen%20de%20WhatsApp%202024-03-29%20a%20las%2010.32.30_ac149fc5.jpg?alt=media&token=7821ee44-e678-422b-b8b2-15fcd7c82ee8';

export const bellsatflow = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fbellsatflw.png?alt=media&token=082e17d6-2593-4758-b930-7676ac1da6af';

export const userSatflow = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2FuserSatflow.png?alt=media&token=cf70822c-7a7d-4d3a-b56c-e04dc488213f';

export const imgUsuarios = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fusuarios.png?alt=media&token=8bc5e7ef-23d3-48a4-a881-52bcf5dbb6c7';

export const imgContenidos = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fcontenidos.png?alt=media&token=86c1e32d-2fab-4cee-9b75-56d9f448a06b';

export const imgConfig = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fconfiguracion.png?alt=media&token=84537949-6737-4c77-9519-194cc732130c';

export const imgAnalisis = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fanalisis.png?alt=media&token=b713f70c-2649-4be7-aa1b-0d3a24bb305b';

export const imgSuscripciones = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fsuscripciones2.png?alt=media&token=8e37fad9-dd9e-46e5-929d-e4f5c0a5fe29';

export const imgLock = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Flock.png?alt=media&token=fac818cb-4122-4565-af98-2c433004799f';

export const imgEmpleados = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fempleados.png?alt=media&token=48d30722-6c1e-487a-95aa-c487f2faae8c';

export const imgReparaciones = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Freparaciones.png?alt=media&token=a603d2bf-58e0-4cff-a1cd-4e025c5b348b';

export const imgDashboart = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fdashboart.png?alt=media&token=a33abbb6-6756-4a1b-ba45-1a5054065bbe';

export const XNotis = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fnotificaciones%2FXNotis.png?alt=media&token=3af704ad-5866-4b21-a7ba-3d90cc62c07e';

export const facturaMenu = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Ffactura.png?alt=media&token=e7b1e850-e1e4-4848-9e83-b1ccce29ce6f';

export const descargarImg = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Ftablas%2FdescargarFactura.png?alt=media&token=09da5efa-b77c-439f-8362-0d20a5d03db3'; 

export const blogImg = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fblog.png?alt=media&token=faa08b47-b539-42dd-94be-87b90f606489';

export const addImgBlog = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fblog%2FaddImgBlog.png?alt=media&token=14d4b5e6-3001-43fb-9732-31dbaf768ca7';

export const corazonImg = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fblog%2FcorazonPubli.png?alt=media&token=2d9998d7-8075-40e8-b31b-32f473b65953';

export const comentarioImg = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fblog%2FcomentarioPubli.png?alt=media&token=7c4aca27-e3c8-4a0c-b498-5c08d0bcc945';

export const corazonImgActive = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fblog%2FcorazonPubliActive.png?alt=media&token=c577b8fb-995d-42aa-a437-2b4ec02e88d3';

export const facturaSatflow = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2FfacturaSatflow.png?alt=media&token=4df2d81c-d813-48af-ad93-f9d9dd91d38e';

export const presupuestosSatflow = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2FpresupuestosSatflow.png?alt=media&token=19816975-46db-44e4-86a3-88c9bde39158';

export const imgCupones = 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fmenu%2Fcupones.png?alt=media&token=3181036e-16dc-4403-8c5c-ff9924757e7a';