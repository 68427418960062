import { useEffect, useState } from 'react';
import './reparaciones-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {useParams} from 'react-router-dom';
import {validarUsuario} from '../../../global/validarUsuario';
import {MenuCliente, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {datosUsuario} from '../../../backend/usuarios/usuarios';
import {consultarReparaciones} from '../../../backend/reparaciones/reparaciones';
import {flechaVer} from '../../../global/imgs.js';

function ReparacionesClienteScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;
  	const id = routeParams.id;

  	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
  	const [uid, CambioUid] = useState(false);

  	const [busquedaReparaciones, CambioBusquedaReparaciones] = useState(false);
	const [reparaciones, CambioReparaciones] = useState([]);

  	useEffect(() =>{

  		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('reparaciones').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaReparaciones == false && uid != false) {

			consultarReparaciones(urlEmpresa).then(response =>{

				let lasReparaciones = [];

				for (let reparacion of response) {
					
					if (uid == reparacion.data.idCliente) {

						lasReparaciones.push(reparacion);
					}
				}

				CambioReparaciones(lasReparaciones);
			});

			CambioBusquedaReparaciones(true);
		}
  	});

  	const ponerUsuario = async function(){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
	    });

		let elUsuario = await validarUsuario();
		let empresa = await datosUsuario(id);

		if (empresa != false) {

			if (elUsuario != false) {

				if (elUsuario.rol == 'cliente') {

					if (elUsuario.urlEmpresa != urlEmpresa && empresa.id != id) {

						terminarSesion();
					}else{

						Swal.close();
						CambioUid(elUsuario.uid);
						//window.location.href = '/cliente/panel/'+urlEmpresa+'/'+id;
					}
				}else if (elUsuario.rol == 'empresa' || elUsuario.rol == 'empleado') {

					window.location.href = '/'+elUsuario.urlEmpresa;
				}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

					window.location.href = '/admin/';
				}else{

					terminarSesion();
				}
			}else{

				window.location.href = '/cliente/login/'+urlEmpresa+'/'+id;
			}
		}else{

			terminarSesion();
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/';
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuCliente
					urlEmpresa={urlEmpresa}
					id={id}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

						<div class="row">
							<div class="col-sm">

								<div class="row">
									<div class="col-sm">

										<div class="formContainer tablaContainer">
											<div class="headerForm headerTabla">
												<h5 class="tituloForm">Mis Reparaciones</h5>
											</div>
											<div class="formulario">
												<table class="table table-hover">
													<tr>
												      	<th class="th" scope="row">ID</th>
												      	<th class="th">Descripción</th>
												      	<th class="th">Técnico Responsable</th>
												      	<th class="th">Tiempo Estimado</th>
												      	<th class="th">Acciones</th>
												    </tr>

												    {reparaciones.map((item) =>(
												    	<tr class="bordeTr">
													      	<td class="elTr" scope="row">{item.id}</td>
													      	<td class="elTr">{item.data.descripcion}</td>
													      	<td class="elTr">{item.data.tecnico}</td>
													      	<td class="elTr">{item.data.tiempoEstimado} día(s)</td>
													      	<td class="elTr">
													      		<img onClick={() => window.location.href = '/cliente/reparacion/'+urlEmpresa+'/'+id+'/'+item.id} src={flechaVer} class="eye" />
													      	</td>
													    </tr>
												    ))}
												    
												</table>
											</div>
										</div>

									</div>
								</div>

							</div>
						</div>

				</div>

			</div>

		</AnimatedPage>
	);
}

export default ReparacionesClienteScreen;