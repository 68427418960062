import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc, referencia, almacenamiento, subirArchivo, obtenerURL} from '../conex.js';
import {datosUsuario} from '../usuarios/usuarios';

export async function guardarPublicacion(id, data){

	let foto = false;

	if (data.foto != false) {

		const storageRef = referencia(almacenamiento, 'assets/blog/'+id+'/'+id);

		await subirArchivo(storageRef, data.foto);

		foto = await obtenerURL(storageRef);
	}

	let datosFinales = {
		uid: data.uid,
		foto: foto,
		texto: data.texto,
		fecha: data.fecha,
		tipo: data.tipo,
	};

	await set(documento(db, "blog", id), datosFinales);

	return true;
}

export async function consultarBlog(){

	let blog = [];
	let blogFinales = [];

	const q = buscar(coleccion(db, "blog"), donde("fecha", "!=", "nada"));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		blog.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	for (let elBlog of blog) {
		
		let usuario = await datosUsuario(elBlog.data.uid);

		if (usuario != false) {

			blogFinales.push({
				blog: elBlog,
				usuario: usuario,
			});
		}
	}

	blogFinales.sort(function(a, b) { 
		a = a.blog.data.fecha; 
		b = b.blog.data.fecha; 
		return a>b ? -1 : a<b ? 1 : 0; 
	});

	return blogFinales;
}

export async function consultarReacciones(idBlog, tipo){

	let reacciones = [];
	let reaccionesFinales = [];

	const q = buscar(coleccion(db, "reacciones"), donde("idBlog", "==", idBlog));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		if (doc.data().tipo == tipo) {

			reacciones.push({
				id: doc.id,
				data: doc.data(),
			});
		}
	});

	for (let reaccion of reacciones) {
		
		let usuario = await datosUsuario(reaccion.data.uid);

		if (usuario != false) {

			reaccionesFinales.push({
				reaccion: reaccion,
				usuario: usuario,
			});
		}
	}

	reaccionesFinales.sort(function(a, b) { 
		a = a.reaccion.data.fecha; 
		b = b.reaccion.data.fecha; 
		return a>b ? -1 : a<b ? 1 : 0; 
	});

	return reaccionesFinales;
}

export async function agregarReaccion(datos){

	var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var id = "";
      		for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    await set(documento(db, "reacciones", id), datos); 

    return true; 			
}

export async function quitarReaccion(id){

	await eliminarDoc(documento(db, "reacciones", id));

	return true;
}