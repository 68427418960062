import { useEffect, useState } from 'react';
import './blog-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {addImgBlog, corazonImg, comentarioImg} from '../../../global/imgs';
import {BotonPublicarBlog, ContenidoBlog, BotonAccionBlog, ComentarioPubli} from '../../../components/blog/blog';
import {guardarPublicacion, consultarBlog, agregarReaccion, quitarReaccion} from '../../../backend/blog/blog';
import {fechaHoy} from '../../../global/datosCalendario.js';
import {Modal} from '../../../components/modals/modals';

function BlogEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [fotoUsuario, CambioFotoUsuario] = useState('https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fdescarga.jpg?alt=media&token=2897bb03-e19b-4671-9364-b7f8e4276c88');
	const [uid, CambioUid] = useState(false);

	const [fotoBlog, CambioFotoBlog] = useState(false);

	const [suscripcion, CambioSuscripcion] = useState(false);

	const [busquedaBlog, CambioBusquedaBlog] = useState(false);
	const [blog, CambioBlog] = useState([]);
	const [blogFinales, CambioBlogFinales] = useState([]);
	const [limiteBlog, CambioLimiteBlog] = useState(20);

	const [idBlog, CambioIdBlog] = useState(false);
	const [modalComentarios, CambioModalComentarios] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('blog').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaBlog == false) {

			consultarBlog().then(response =>{

				let losBlog = [];
				let cero = 0;

				for (let elBlog of response) {
					
					if (cero <= limiteBlog) {

						losBlog.push(elBlog);
					}
				}

				CambioBlog(response);
				CambioBlogFinales(losBlog);
			});

			CambioBusquedaBlog(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				
					window.location.href = '/'+urlEmpresa;
				}

				CambioFotoUsuario(elUsuario.foto);
				CambioSuscripcion(elUsuario.suscripcion);
				CambioUid(elUsuario.uid);
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const ponerNombreImagen = async function(){

		const subirFotoBlog = document.getElementById('subirFotoBlog').files[0];

		document.getElementById('textoSubirFotoBlog').innerHTML = subirFotoBlog.name;

		CambioFotoBlog(subirFotoBlog);
	}

	const procesarPublicacion = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		let inputTextPubli = document.getElementById('inputTextPubli').value;

		if (fotoBlog == false && inputTextPubli === '') {

			Swal.fire({
			    icon: 'info',
			    title: 'Debe introducir un imagen o texto.',
			});
		}else{

			var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    		var id = "";
      			for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));
		
      		let fecha = await fechaHoy();

      		let data = {
      			texto: inputTextPubli,
      			foto: fotoBlog,
      			uid: uid,
      			fecha: fecha,
      			tipo: 'comentario',
      		};

      		guardarPublicacion(id, data).then(response =>{

      			CambioBusquedaBlog(false);
      			Swal.close();
      		});
		}
	}

	const reaccionar = async function(idBlog, idReaccion, tipo){

		if (tipo == 'meGusta') {

			if (idReaccion != false) {

				await quitarReaccion(idReaccion)
			}else{

				let fecha = await fechaHoy();

				let datos = {
					uid: uid,
					fecha: fecha,
					idBlog: idBlog,
					tipo: tipo,
				};

				await agregarReaccion(datos);
			}

			return true;
		}
	}

	const comentar = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let inputComentario = document.getElementById('inputComentario').value;

	    if (inputComentario === '') {

	    	Swal.fire({
			    icon: 'info',
			    title: 'Debe introducir un texto.',
			});

			return false;
	    }else{

	    	let fecha = await fechaHoy();

      		let data = {
      			texto: inputComentario,
      			uid: uid,
      			fecha: fecha,
      			tipo: 'comentario',
      			idBlog: idBlog,
      		};

      		await agregarReaccion(data);

      		Swal.close();

      		return true;
	    }
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					<div class="container"><br/>

						<div class="row">
						
							<div class="col-sm">

								<div class="formBlog">
									<h3 class="tituloFormBlog">Crear publicación</h3>
								
									<div class="contentPlublicacion">

										<img
											src={fotoUsuario}
											class="fotoUsuarioBlog"
										/>

										<div class="form-group" style={{display: 'inline-block', width: '86%',}}>
									    	<input type="text" class="inputSatflow" id="inputTextPubli" placeholder="¿Qué tienes en mente?" />
									    </div>

									</div>

									<div class="accionesPublicar">
										<label class="botonAddImageBlog" for="subirFotoBlog">
											<img
												src={addImgBlog}
												class="addImgBlog"
											/>
											<span id="textoSubirFotoBlog" class="textoSubirFotoBlog">Subir foto</span>
											<input onChange={ponerNombreImagen} type="file" id="subirFotoBlog" accept="image/*" style={{display: 'none',}} />
										</label>

										<BotonPublicarBlog
											urlEmpresa={urlEmpresa}
											suscripcion={suscripcion}
											accion={procesarPublicacion}
										/>
									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
						
							<div class="col-sm">

								{blog.map((item) =>(

									<div class="blogContainer">

										<div class="header">
											<img
												src={item.usuario.data.foto}
												class="fotoUsuarioPubli"
											/>
											<div class="nombreYFecha">
												<span class="nombreUsuarioPubli">{item.usuario.data.nombre}</span>
												<br/>
												<span class="fechaPubli">{item.blog.data.fecha}</span>
											</div>
										</div>

										<ContenidoBlog
											foto={item.blog.data.foto}
											blog={item.blog}
										/>

										<div class="footerPubli">

											<BotonAccionBlog
												idBlog={item.blog.id}
												uid={uid}
												img={corazonImg}
												texto={'Me gusta'}
												accion={reaccionar}
												tipo={'meGusta'}
											/>
											

											<BotonAccionBlog
												idBlog={item.blog.id}
												uid={uid}
												img={comentarioImg}
												texto={'Comentarios'}
												accion={() => {
													CambioModalComentarios(true);
													CambioIdBlog(item.blog.id);
												}}
												tipo={'comentario'}
											/>
										</div>

									</div>

								))}

							</div>

						</div><br/>

					</div>

				</div>

			</div>

			<Modal mostrar={modalComentarios}>

				<p onClick={() => {
					CambioModalComentarios(false);
					CambioIdBlog(false);
				}} class="equix">X</p>
				<div class="formContainer formGestion">
					<div class="headerForm">
						<h5 class="tituloForm">Comentarios</h5>
					</div>
					<div class="formulario">

						<ComentarioPubli
							idBlog={idBlog}
							mostrarAhora={modalComentarios}
							fotoUsuario={fotoUsuario}
							urlEmpresa={urlEmpresa}
							suscripcion={suscripcion}
							comentar={comentar}
						/>

					</div>
				</div>

			</Modal>

		</AnimatedPage>
	);
}

export default BlogEmpresaScreen;