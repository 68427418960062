import { useEffect, useState } from 'react';
import './pedidos-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {consultarPedidos} from '../../../backend/pedidos/pedidos'
import {descargarImg, flechaVer} from '../../../global/imgs';
import {datosSuscripcion} from '../../../backend/suscripciones/suscripciones';

function PedidosEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [uid, CambioUid] = useState(false);
	const [usuario, CambioUsuario] = useState({});

	const [busquedaPedidos, CambioBusquedaPedidos] = useState(false);
	const [pedidos, CambioPedidos] = useState([]);
	const [pedidosFinales, CambioPedidosFinales] = useState([]);
	const [limitePedidos, CambioLimitePedidos] = useState(20);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('pedidos').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaPedidos == false && uid != false) {

			consultarPedidos(uid).then(response =>{

				let losPedidos = [];
				let cero = 0;

				for (let pedido of response) {
					
					if (cero <= limitePedidos) {

						losPedidos.push(pedido);
					}

					cero++;
				}

				CambioPedidos(response);
				CambioPedidosFinales(losPedidos);
			});

			CambioBusquedaPedidos(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else if (elUsuario.rol == 'empleado') {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}

				CambioUid(elUsuario.uid);
				CambioUsuario(elUsuario);
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const verFactura = async function(item){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

    	let producto = false;

    	if (item.data.tipo == 'suscripcion') {

    		producto = await datosSuscripcion(item.data.datosCompra.idProducto);
    	}

    	// http://localhost/reparacion-de-ordenadores-backend/factura/factura.php

    	if (producto != false) {

    		window.open(
    			'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/factura.php?'+
    			'numeroFactura='+item.data.numeroFactura+
    			'&fechaMostrar='+item.data.fecha+
    			'&nombre='+usuario.nombre+
				'&email='+usuario.email+
				'&direccion='+usuario.direccion+
				'&telefono='+usuario.telefono+
				'&identificacion='+usuario.DNIoNIF+
				'&nombreProducto='+producto.data.nombre+
				'&costoProducto='+producto.data.precio+
				'&total='+producto.data.precio+
				'&formaPago='+item.data.datosCompra.metodoPago,
    			'_blank'
    		);
    	}

    	Swal.close();
  	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

						<div class="row">
							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Pedidos</h5>
									</div>
									<div class="formulario">
										<table class="table table-hover">
											<tr>
										      	<th class="th" scope="row">ID</th>
										      	<th class="th">Fecha</th>
										      	<th class="th">Tipo de producto</th>
										      	<th class="th">Acciones</th>
										    </tr>
										    {pedidosFinales.map((item) =>(
										    	<tr class="bordeTr">
										    		<td class="elTr" scope="row">{item.id}</td>
											      	<td class="elTr">{item.data.fecha}</td>
											      	<td class="elTr">{item.data.tipo}</td>
											      	<td class="elTr">
											      		<img onClick={() => verFactura(item)} src={descargarImg} class="eye" />
											      		<img onClick={() => window.location.href = '/'+urlEmpresa+'/pedido/'+item.id} src={flechaVer} class="eye" />
											      	</td>
											    </tr>
										    ))}
										</table>
									</div>
								</div>

							</div>
						</div>

				</div>

			</div>

		</AnimatedPage>
	);
}

export default PedidosEmpresaScreen;