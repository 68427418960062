import React, {Component} from 'react';
import ReactDOM from 'react-dom';

const portalRoot = document.getElementById('portal');

class Portal extends Component{
	constructor(){
		super();
		this.el = document.createElement('div');
	}

	componentDidMount = async () =>{
		portalRoot.appendChild(this.el);
	}

	componentWillUmount = async () =>{
		portalRoot.removeChild(this.el);
	}

	render(){
		const {children} = this.props;

		return ReactDOM.createPortal(children, this.el);
	}
}


export default Portal;