import { useEffect, useState } from 'react';
import {InfoUsuario, ArchivoAdjunto} from '../reparaciones/reparaciones';

export function InfoReparacion({datos}){

	if (datos != false) {

		return(
			<div class="formContainer">

				<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
					<h5 class="tituloForm">{datos.ordenador.data.tipoDeOrdenador}</h5>
				</div><br/>
				<div class="formulario">
											
					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Cliente</span>
						<input type="text" class="form-control" readonly value={datos.usuario.data.nombre} />
					</div><br/>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">ID</span>
						<input type="text" class="form-control" readonly value={datos.usuario.id} />
					</div><br/>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Dirección</span>
						<input type="text" class="form-control" readonly value={datos.usuario.data.direccion} />
					</div><br/><hr/>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Dirección de recogida</span>
						<input type="text" class="form-control" readonly value="Domicilio del cliente" />
					</div><br/><hr/>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Modelo</span>
						<input type="text" class="form-control" readonly value={datos.ordenador.data.modeloDelOrdenador} />
					</div><br/>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Marca</span>
						<input type="text" class="form-control" readonly value={datos.ordenador.data.marcaDelOrdenador} />
					</div><br/>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Cuenta con certificados</span>
						<input type="text" class="form-control" readonly value={datos.ordenador.data.cuentaConCertificadosDigitales} />
					</div><br/>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Tiempo estimado</span>
						<input type="text" class="form-control" readonly value={datos.reparacion.data.tiempoEstimado} />
					</div>

					<div class="columnaReparacion">
						<label class="form-label mt-4">Archivo Adjunto:</label>
						<br/>
						<ArchivoAdjunto archivo={datos.reparacion.data.archivo} />
					</div>

					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">Descripción del problema</span>
						<input type="text" class="form-control" readonly value={datos.ordenador.data.descripcion} />
					</div><br/>

				</div>
			</div>
		);
	}else{

		return(
			<></>
		);
	}
}