import React, { useState, useEffect } from 'react';
import './animacion-styles.css';
import { motion } from "framer-motion"

export function AnimatedPage({children, duracion}){

	const animations = {
		initial: {opacity: 0, x: 100},
		animate: {opacity: 1, x: 0},
		exit: {opacity: 0, x: -100},
	};

	return(
		<motion.div 
			variants={animations}
			initial="initial"
			animate="animate"
			exit="exit"
			transition={{duration: duracion,}}
		>
			{children}
		</motion.div>
	);
}