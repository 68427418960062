import { useEffect, useState } from 'react';
import './clientes-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {Modal} from '../../../components/modals/modals';
import {resgistrarUsuario} from '../../../backend/auth/auth';
import {consultarUsuarios, activaODesactivarUsuario, eliminarUsuario} from '../../../backend/usuarios/usuarios';
import {ElOjo} from '../../../components/tablas/tablas';
import {trash} from '../../../global/imgs.js';
import {datosSuscripcion} from '../../../backend/suscripciones/suscripciones';

function ClientesEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [modalCliente, CambioModalCliente] = useState(false);
	const [idCliente, CambioIdCliente] = useState(false);
	const [busquedaUsuarios, CambioBusquedaUsuarios] = useState(false);
	const [usuarios, CambioUsuarios] = useState([]);
	const [usuariosFinales, CambioUsuariosFinales] = useState([]);
	const [limiteUsuarios, CambioLimiteUsuarios] = useState(20);
	const [modalEstadoUsuario, CambioModalEstadoUsuario] = useState(false);
	const [preguntaModal, CambioPreguntaModal] = useState('');
	const [estadoUsuario, CambioEstadoUsuario] = useState(false);
	const [modalEliminarUsuario, CambioModalEliminarUsuario] = useState(false);
	const [usuariosPermitidos, CambioUsuariosPermitidos] = useState(20);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('clientes').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaUsuarios == false) {

			consultarUsuarios('cliente').then(response =>{

				let losUsuarios = [];
				let cero = 0;

				for (let usuario of response) {
					
					if (cero <= limiteUsuarios) {

						losUsuarios.push(usuario);
					}

					cero++;
				}

				CambioUsuarios(response);
				CambioUsuariosFinales(losUsuarios);
			});

			CambioBusquedaUsuarios(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}

				if (elUsuario.suscripcion == false) {

					CambioUsuariosPermitidos(20);
				}else{

					Swal.fire({
				      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
				          	'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
				          	'</div>',
				      	showConfirmButton: false,
				      	allowOutsideClick: false,
				      	allowEnterKey: false,
				      	allowEscapeKey: false,
				    });

				    let laSuscripcion = await datosSuscripcion(elUsuario.suscripcion);

				    if (laSuscripcion != false) {

				    	if (laSuscripcion.data.cantidadClientes == '') {

				    		CambioUsuariosPermitidos('Ilimitada');
				    	}else{	

				    		CambioUsuariosPermitidos(parseInt(laSuscripcion.data.cantidadClientes));
				    	}
				    }else{

				    	CambioUsuariosPermitidos(20);
				    }

				    Swal.close();
				}
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const procesarRegistro = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('email').value;
	    //let password = document.getElementById('password').value;
	    //let passwordConfirmar = document.getElementById('passwordConfirmar').value;
	    let nombre = document.getElementById('nombre').value;
	    let telefono = document.getElementById('telefono').value;
	    let DNIoNIF = document.getElementById('DNIoNIF').value;
	    let direccion = document.getElementById('direccion').value;

	    let elUsuario = await validarUsuario();

	    if (email === '' || nombre === '' || telefono === '' || DNIoNIF === '' || direccion === '') {

	    	Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
	    }else if (isNaN(telefono)) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'El telefono debe ser numerico.',
		    });
	    }else if (elUsuario == false) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'No hay usuario autenticado.',
		    });
	    }else{

	    	if (usuariosPermitidos != 'Ilimitada') {

	    		if (usuarios.length >= usuariosPermitidos) {

		    		Swal.fire({
				        icon: 'info',
				        title: 'No puedes registrar mas cleintes.',
				    });

				    return false;
		    	}
	    	}

	    	let datos = {
	    		email: email,
				password: '12345678',
				nombre: nombre,
				telefono: telefono,
				DNIoNIF: DNIoNIF,
				direccion: direccion,
				rol: 'cliente',
				idEmpresa: elUsuario.uid,
				urlEmpresa: urlEmpresa,
	    	};

	    	resgistrarUsuario(datos).then(response =>{

		        if (response == 'ya existe') {

		        	Swal.fire({
		            	icon: 'error',
		            	title: 'El usuario ingresado ya existe.',
		          	});
		        }else if (response != false) {

		          	Swal.fire({
		            	icon: 'success',
		            	title: 'Datos guardados.',
		          	});

		          	CambioModalCliente(false);
					CambioIdCliente(false);
		        }else{

		          	Swal.fire({
		            	icon: 'error',
		            	title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
		          	});
		        }
		    });
	    }
	}

	const modificarEstadoUsuario = async function(item){

		if (item.data.activo == true) {

			CambioPreguntaModal('¿Desea desactivar este usuario?');
		}else if (item.data.activo == false) {

			CambioPreguntaModal('¿Desea activar este usuario?');
		}

		CambioIdCliente(item.id);
		CambioEstadoUsuario(item.data.activo);
		CambioModalEstadoUsuario(true);
	}

	const alterarEstadoUsuario = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    activaODesactivarUsuario(idCliente, estadoUsuario).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Usuario actualizada.',
			});

			CambioModalEstadoUsuario(false);
			CambioIdCliente(false);
			CambioBusquedaUsuarios(false);
	    })
	}

	const preguntarEliminarUsuario = async function(item){

		CambioPreguntaModal('¿Desea eliminar este usuario?');
		CambioIdCliente(item.id);
		CambioModalEliminarUsuario(true);
	}

	const procederEliminarUsuario = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    eliminarUsuario(idCliente).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Usuario eliminado.',
			});

			CambioModalEliminarUsuario(false);
			CambioIdCliente(false);
			CambioBusquedaUsuarios(false);
	    })
	}

	const copiarEnlace = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			let protocol = window.location.protocol;
	        let domain = window.location.hostname;

	        let idEmpresa = '';

	        if (elUsuario.rol == 'empleado') {

	        	idEmpresa = elUsuario.idEmpresa;
	        }else{

	        	idEmpresa = elUsuario.uid;
	        }

	        let enlace = protocol+'//'+domain+'/cliente/login/'+urlEmpresa+'/'+idEmpresa

	        await navigator.clipboard.writeText(enlace);

	        Swal.fire({
		        icon: 'success',
		        title: 'Enlace Copiado.',
		    });
		}
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

						<div class="row">
							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Clientes {'('+usuarios.length+'/'+usuariosPermitidos+')'} <span class="cambiarSuscripcion">Cambiar suscripción</span></h5>
										<button onClick={copiarEnlace} class="btn btn-primary botonRepararTuPC widthContent">Copiar Enlace de registro</button>
									</div>
									<div class="formulario">
										<table class="table table-hover">
											<tr>
										      	<th class="th" scope="row">ID</th>
										      	<th class="th">Nombre</th>
										      	<th class="th">Dirección</th>
										      	<th class="th">Telefono</th>
										      	<th class="th">Acciones</th>
										    </tr>
										    {usuariosFinales.map((item) =>(
										    	<tr class="bordeTr">
										    		<td class="elTr" scope="row">{item.id}</td>
											      	<td class="elTr">{item.data.nombre}</td>
											      	<td class="elTr">{item.data.direccion}</td>
											      	<td class="elTr">{item.data.telefono}</td>
											      	<td class="elTr">
											      		<ElOjo
											      			estado={item.data.activo}
											      			accion={() => modificarEstadoUsuario(item)}
											      		/>
											      		<img onClick={() => preguntarEliminarUsuario(item)} src={trash} class="eye" />
											      	</td>
											    </tr>
										    ))}
										</table>
									</div>
								</div>

							</div>
						</div>

				</div>

			</div>

			<Modal mostrar={modalCliente}>

				<p onClick={() => {
					CambioModalCliente(false);
					CambioIdCliente(false);
				}} class="equix">X</p>
				<div class="formContainer formGestion">
					<div class="headerForm">
						<h5 class="tituloForm">Gestionar Cliente</h5>
					</div>
					<div class="formulario">

						<div class="form-group">
							<label for="email" class="form-label mt-4">Email</label>
							<input type="email" class="form-control" id="email" placeholder="Escriba su Email" />
						</div>

						<div class="form-group">
							<label for="nombre" class="form-label mt-4">Nombre y Apellido</label>
							<input type="text" class="form-control" id="nombre" placeholder="Escriba su nombre y apellido" />
						</div>

						<div class="form-group">
							<label for="telefono" class="form-label mt-4">Telefono</label>
							<input type="text" class="form-control" id="telefono" placeholder="Escriba el telefono de la empresa" />
						</div>

						<div class="form-group">
							<label for="DNIoNIF" class="form-label mt-4">DNI / NIF</label>
							<input type="text" class="form-control" id="DNIoNIF" placeholder="Escriba el DNI / NIF de la empresa" />
						</div>

						<div class="form-group">
							<label for="direccion" class="form-label mt-4">Dirección</label>
							<textarea class="form-control" id="direccion" rows="3"></textarea>
						</div>

						<br/>

						<button onClick={procesarRegistro} class="btn btn-primary botonRepararTuPC">Guardar</button>
								
					</div>
				</div>

			</Modal>

			<Modal mostrar={modalEstadoUsuario}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={alterarEstadoUsuario} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEstadoUsuario(false);
				    	CambioIdCliente(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

			<Modal mostrar={modalEliminarUsuario}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={procederEliminarUsuario} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEliminarUsuario(false);
				    	CambioIdCliente(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

		</AnimatedPage>
	);
}

export default ClientesEmpresaScreen;