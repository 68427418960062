import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc, referencia, almacenamiento, subirArchivo, obtenerURL} from '../conex.js';
import {validarUsuario} from '../../global/validarUsuario';

export async function consultarUsuarios(rol){

	let usuarios = [];

	let elUsuario = await validarUsuario();

	const q = buscar(coleccion(db, "usuarios"), donde("rol", "==", rol));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		if (elUsuario != false && rol == 'cliente') {

			if (elUsuario.rol == 'empleado') {

				if (elUsuario.idEmpresa == doc.data().idEmpresa) {

					usuarios.push({
						id: doc.id,
						data: doc.data(),
					});
				}
			}else if (elUsuario.uid == doc.data().idEmpresa) {

				usuarios.push({
					id: doc.id,
					data: doc.data(),
				});
			}
		}else if (elUsuario != false && rol == 'empleado'){

			if (elUsuario.uid == doc.data().idEmpresa) {

				usuarios.push({
					id: doc.id,
					data: doc.data(),
				});
			}
		}else if(rol == 'empresa'){

			usuarios.push({
				id: doc.id,
				data: doc.data(),
			});
		}
	});

	return usuarios;
}

export async function activaODesactivarUsuario(id, estado){

	let elEstado = true;

	if (estado == true) {
		elEstado = false;
	}

	const washingtonRef = documento(db, "usuarios", id);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  activo: elEstado,
	});

	return true;
}

export async function eliminarUsuario(id){

	await eliminarDoc(documento(db, "usuarios", id));

	return true;
}

export async function activarCuentaUsuario(uid){

	const washingtonRef = documento(db, "usuarios", uid);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  verificacionEmail: true,
	});

	return true;
}

export async function datosUsuario(uid){

	const docRef = documento(db, "usuarios", uid);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}

export async function actualizarDatosUsuario(id, data){

	let urlFoto = '';

	if (data.nuevaFoto != false) {

		const storageRef = referencia(almacenamiento, 'assets/usuarios/'+id+'/'+id);

		await subirArchivo(storageRef, data.nuevaFoto);

		urlFoto = await obtenerURL(storageRef);
	}else{

		urlFoto = data.foto;
	}

	const washingtonRef = documento(db, "usuarios", id);

	
	await actualizarDoc(washingtonRef, {
	  	nombre: data.nombre,
		telefono: data.telefono,
		DNIoNIF: data.DNIoNIF,
		direccion: data.direccion,
		foto: urlFoto,
	});

	let usuario = await datosUsuario(id);

	return usuario.data;
}

export async function guardarLogo(urlEmpresa, logo){

	const storageRef = referencia(almacenamiento, 'assets/logos/'+urlEmpresa+'/'+urlEmpresa);

	await subirArchivo(storageRef, logo);

	let urlFoto = await obtenerURL(storageRef);

	await set(documento(db, "logos", urlEmpresa), {
		logo: urlFoto,
	});

	return true;
}

export async function consultarLogo(urlEmpresa){

	const docRef = documento(db, "logos", urlEmpresa);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {

		return docSnap.data().logo;
	}else{

		return false;
	}
}