import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc} from '../conex.js';

export async function consultarPedidos(uid){

	let pedidos = [];

	const q = buscar(coleccion(db, "pedidos"), donde("uid", "==", uid));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		pedidos.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return pedidos;
}

export async function datosPedido(id){

	const docRef = documento(db, "pedidos", id);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}