export async function validarUsuario(){

	let usuario = await localStorage.getItem('usuarioReparacionDeOrdenadores');

	if (usuario != null) {

		let dataUser = JSON.parse(usuario);

		return dataUser;
	}else{

		return false;
	}
}