import { useEffect, useState } from 'react';
import './crearPresupuesto-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {datosReparacion, datosOrdenador, datosNumeroReparacion} from '../../../backend/reparaciones/reparaciones';
import {datosUsuario} from '../../../backend/usuarios/usuarios';
import {InfoReparacion} from '../../../components/presupuestos/presupuestos';
import {gestionarPresupuesto} from '../../../backend/presupuestos/presupuestos';
import {fechaHoy} from '../../../global/datosCalendario.js';

function CrearPresupuestoEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

	const [datosCompletosReparacion, DatosCompletosReparacion] = useState(false);

	const [costos, CambioCostos] = useState([]);
	const [costo, CambioCosto] = useState(false);
	const [agregarCosto, CambioAgregarCosto] = useState(false);
	const [quitarCosto, CambioQuitaCosto] = useState(false);

	const [subTotal, CambioSubTotal] = useState(0);
	const [total, CambioTotal] = useState(0);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			//document.getElementById('presupuestos').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (agregarCosto == true) {

			let losCostos = costos;
			let descripcionCosto = document.getElementById('descripcionCosto').value;
			let elCosto = document.getElementById('costo').value;

			if (descripcionCosto === '' || elCosto === '') {

				Swal.fire({
				    icon: 'info',
				    title: 'Complete los campos para continuar.',
				});
			}else if (isNaN(elCosto)) {

				Swal.fire({
				    icon: 'info',
				    title: 'El costo debe ser numerico.',
				});
			}else{

				losCostos.push({
					descripcion: descripcionCosto,
					costo: elCosto,
				});

				CambioCostos(losCostos);
			}

			CambioAgregarCosto(false);
			calcularTotal();
		}

		if (quitarCosto != false) {

			let losCostos = costos;
			let index = losCostos.indexOf(quitarCosto);

			losCostos.splice(index, 1);

			CambioCostos(losCostos);
			CambioQuitaCosto(false);
			calcularTotal();
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const verReparacion = async function(){

		Swal.fire({
	      	html: 	'<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          			'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          		'</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

		let buscarReparacion = document.getElementById('buscarReparacion').value;

		if (buscarReparacion === '') {

			Swal.fire({
			    icon: 'info',
			    title: 'Ingrese ID de la preparación.',
			});
		}else{

			let reparacion = await datosNumeroReparacion(buscarReparacion);

			if (reparacion != false) { console.log(reparacion);

				let usuario = await datosUsuario(reparacion.data.idCliente);
				let tecnico = await datosUsuario(reparacion.data.uid);
				let ordenador = await datosOrdenador(reparacion.data.idCliente);

				if (usuario != false && tecnico != false && ordenador != false) {
				
					let data = {
						reparacion: reparacion,
						usuario: usuario,
						tecnico: tecnico,
						ordenador: ordenador,
					};

					DatosCompletosReparacion(data);
				}else{
				
					Swal.fire({
					    icon: 'error',
					    title: 'No se encontro preparación.',
					});
				}

				Swal.close();
			}else{

				Swal.fire({
				    icon: 'error',
				    title: 'No se encontro preparación.',
				});
			}
		}
	}

	const calcularTotal = async function(){

		let elTotal = 0;
		let elSubTotal = 0;

		for (let elCosto of costos) {
			
			let suma = elSubTotal + parseFloat(elCosto.costo);

			elSubTotal = suma;
		}

		let iva = elSubTotal * 0.21;

		elTotal = elSubTotal + iva;

		CambioSubTotal(elSubTotal);
		CambioTotal(elTotal);
	}

	const procesarPresupuesto = async function(){

		Swal.fire({
			html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
				    '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
				'</div>',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEnterKey: false,
			allowEscapeKey: false,
		});

		var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var id = "";
      		for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));
	
      	if (costos.length == 0 || datosCompletosReparacion == false) {

      		Swal.fire({
		        icon: 'info',
		        title: 'Indique la reparación y sus costos para continuar.',
		    });
      	}else{

      		let fechaCreacion = await fechaHoy();

      		let datosPresupuesto = {
      			costos: costos,
      			idReparacion: datosCompletosReparacion.reparacion.id,
      			numeroFolio: datosCompletosReparacion.reparacion.data.numeroFolio,
      			fechaCreacion: fechaCreacion,
      			urlEmpresa: urlEmpresa,
      		};

      		gestionarPresupuesto(id, datosPresupuesto).then(response =>{

      			Swal.fire({
			        icon: 'success',
			        title: 'Datos Guardados.',
			    });

			    window.setTimeout(terminar, 1000);
      		});
      	}
	}

	const terminar = async function(){

		window.location.href = '/'+urlEmpresa+'/presupuestos';
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					<div class="container"><br/>

						<div class="row">
						
							
							<div class="col-sm-8">

								<div class="formContainer">
									
									<div class="formulario">

										<div class="formBusquedaIdReparacion">
											<div class="form-group">
												<input type="text" class="form-control" id="buscarReparacion" placeholder="ID de reparación" />
											</div>
											<button onClick={verReparacion} style={{marginLeft: 20,}} class="btn btn-primary botonRepararTuPC widthContent">Buscar</button>
										</div>
									
									</div>
								</div>

								<br/>

								<InfoReparacion
									datos={datosCompletosReparacion}
								/>
							</div>

							<div class="col-sm-4">

								<div class="formContainer">
									<div class="formulario">

										<div>
									      	<label for="descripcionCosto" class="form-label mt-4">Descripción del costo</label>
									      	<input type="text" class="form-control" id="descripcionCosto" />
									    </div>
									    <div>
									      	<label for="costo" class="form-label mt-4">Costo</label>
									      	<input type="text" class="form-control" id="costo" />
									    </div><br/>

									    <button onClick={() => CambioAgregarCosto(true)} class="btn btn-primary botonRepararTuPC widthContent">Agregar costo</button>
										<br/>

									    {costos.map((item) =>(
									    	<div class="formContainer containerCosto">
												<div class="formulario">
													<p onClick={() => CambioQuitaCosto(item)} class="equix">X</p>
													<p><span style={{fontWeight: 'bold',}}>Descripción: </span>{item.descripcion}</p>
													<p><span style={{fontWeight: 'bold',}}>Costo: </span>€{item.costo}</p>
												</div>
											</div>
									    ))}

									    <div class="finalPresupuesto">
									    	<span>Sub total</span>
									    	<span>€{subTotal}</span>
									    </div>
									    <div class="finalPresupuesto">
									    	<span>IVA</span>
									    	<span>21%</span>
									    </div>
									    <div class="finalPresupuesto">
									    	<span>Total</span>
									    	<span>€{total}</span>
									    </div>
									    <div class="finalPresupuesto">
									    	<button onClick={procesarPresupuesto} class="btn btn-primary botonRepararTuPC widthContent">Guardar</button>
									    </div>
									</div>
								</div>

							</div>

						</div><br/>

					</div>

				</div>

			</div>

		</AnimatedPage>
	);
}

export default CrearPresupuestoEmpresaScreen;