import { useEffect, useState } from 'react';
import './loading-styles.css';
import {validarUsuario} from '../../global/validarUsuario.js';

function LoadingScreen(){

	const [usuarioValidado, CambioUsuarioValidado] = useState(false);

	useEffect(() =>{

		if (usuarioValidado == false) {

			comprobarUsuario();

			CambioUsuarioValidado(true);
		}
	})

	const comprobarUsuario = async function(){

		validarUsuario().then(response =>{

			if (response != false) {

				if (response.rol == 'cliente') {

					window.location.href = '/cliente/panel/'+response.urlEmpresa+'/'+response.idEmpresa;
				}else if (response.rol == 'admin' || response.rol == 'subAdmin') {

					window.location.href = '/admin/';
				}else if (response.rol == 'empresa' || response.rol == 'empleado') {

					window.location.href = '/'+response.urlEmpresa;
				}
			}else{

				window.location.href = '/login';
			}
		});
	}

	return(
		<></>
	);
}

export default LoadingScreen;