import { useEffect, useState } from 'react';
import './home-styles.css';

function HomeScreen(){

	return(
		<h1>HomeScreen</h1>
	);
}

export default HomeScreen;