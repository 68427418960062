import { useEffect, useState } from 'react';
import './perfil-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {guardarLogo, datosUsuario, actualizarDatosUsuario} from '../../../backend/usuarios/usuarios';
import {Modal} from '../../../components/modals/modals';

function PerfilEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [uid, CambioUid] = useState('');
  	const [foto, CambioFoto] = useState('');
  	const [nombre, CambioNombre] = useState('');

  	const [email, CambioEmail] = useState('');
  	const [telefono, CambioTelefono] = useState('');
  	const [DNIoNIF, CambioDNIoNIF] = useState('');
  	const [direccion, CambioDireccion] = useState('');

  	const [nuevaFoto, CambioNuevaFoto] = useState(false);

  	const [modalCliente, CambioModalCliente] = useState(false);

	const [logoPuesto, CambioLogoPuesto] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}

				CambioUid(elUsuario.uid);
				CambioFoto(elUsuario.foto);
				CambioNombre(elUsuario.nombre);
				CambioEmail(elUsuario.email);
				CambioTelefono(elUsuario.telefono);
				CambioDNIoNIF(elUsuario.DNIoNIF);
				CambioDireccion(elUsuario.direccion);
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const procesarLogo = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          	'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	'</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    let logo = document.getElementById('formFile').files[0];

	    if (logoPuesto == false) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'Seleccione un logo para ocntinuar.',
		    });
	    }else{

	    	guardarLogo(urlEmpresa, logo).then(response =>{

	    		Swal.fire({
			        icon: 'success',
			        title: 'Logo actualizado.',
			    });

			    window.setTimeout(recargar, 1000);
	    	});
	    }
	}

	const recargar = async function(){

		window.location.reload();
	}

	const editarUsuario = async function(){

		CambioModalCliente(true);

		window.setTimeout(ponerDatos, 500);
	}

	const ponerDatos = async function(){

		document.getElementById('nombre').value = nombre;
		document.getElementById('telefono').value = telefono;
		document.getElementById('DNIoNIF').value = DNIoNIF;
		document.getElementById('direccion').value = direccion;
	}

	const procesarDatos = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let elNombre = document.getElementById('nombre').value;
	    let elTelefono = document.getElementById('telefono').value;
	    let elDNIoNIF = document.getElementById('DNIoNIF').value;
	    let elDireccion = document.getElementById('direccion').value;

	    if (elNombre === '' || elTelefono === '' || elDNIoNIF === '' || elDireccion === '') {

	    	Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
	    }else{

	    	let data = {
	    		nombre: elNombre,
				telefono: elTelefono,
				DNIoNIF: elDNIoNIF,
				direccion: elDireccion,
				foto: foto,
				nuevaFoto: nuevaFoto,
	    	};

	    	actualizarDatosUsuario(uid, data).then(response =>{

	    		Swal.fire({
			        icon: 'success',
			        title: 'Datos guardados',
			    });

			    window.setTimeout(terminar, 1000, response);
	    	})
	    }
	}

	const terminar = async function(respuesta){

		await localStorage.setItem('usuarioReparacionDeOrdenadores', JSON.stringify(respuesta));

		window.location.reload();
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

						<div class="row">
							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla">
										<div style={{display: 'flex', alignItems: 'center',}}>
											<img src={foto} class="fotoPerfil" />
											<h5 class="tituloForm" style={{marginLeft: 20,}}>{nombre}</h5>
										</div>
										<button class="btn btn-primary botonRepararTuPC" onClick={editarUsuario} style={{width: 'fit-content',}}>Editar</button>
									</div>
									<div class="formulario">
									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer">
									<div class="headerForm">
										<h5 class="tituloForm" style={{marginLeft: 20,}}>Tus Datos</h5>
									</div>
									<div class="formulario conColumnas">

										<div class="columnaReparacion">
									      	<label class="form-label mt-4">Email:</label>
									    	<br/>
									    	<span>{email}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Telefono:</label>
									    	<br/>
									    	<span>{telefono}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">DNI / NIF:</label>
									    	<br/>
									    	<span>{DNIoNIF}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Dirección:</label>
									    	<br/>
									    	<span>{direccion}</span>
									    </div>

									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer">

									<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Personalizar Logo de tu empresa</h5>
									</div>
									<div class="formulario">

										<div>
									      <label for="formFile" class="form-label mt-4">Seleccionar logo</label>
									      <input onChange={() => CambioLogoPuesto(true)} accept="image/*" class="form-control" type="file" id="formFile" />
									    </div><br/>

									    <button onClick={procesarLogo} class="btn btn-primary botonRepararTuPC">Guardar</button>

									</div>
								</div>

							</div>

							<div class="col-sm">

								<h1></h1>

							</div>
						</div><br/><br/>

					

				</div>

			</div>

			<Modal mostrar={modalCliente}>

				<p onClick={() => {
					CambioModalCliente(false);
				}} class="equix">X</p>
				<div class="formContainer formGestion">
					<div class="headerForm">
						<h5 class="tituloForm">Editar mis datos</h5>
					</div>
					<div class="formulario">

						<div class="form-group">
					      <label for="formFile2" class="form-label mt-4">Cambiar foto de perfil</label>
					      <input class="form-control" type="file" accept="image/*" id="formFile2" onChange={() => CambioNuevaFoto(document.getElementById('formFile2').files[0])} />
					    </div>

						<div class="form-group">
							<label for="nombre" class="form-label mt-4">Nombre y Apellido</label>
							<input type="text" class="form-control" id="nombre" placeholder="Escriba su nombre y apellido" />
						</div>

						<div class="form-group">
							<label for="telefono" class="form-label mt-4">Telefono</label>
							<input type="text" class="form-control" id="telefono" placeholder="Escriba el telefono de la empresa" />
						</div>

						<div class="form-group">
							<label for="DNIoNIF" class="form-label mt-4">DNI / NIF</label>
							<input type="text" class="form-control" id="DNIoNIF" placeholder="Escriba el DNI / NIF de la empresa" />
						</div>

						<div class="form-group">
							<label for="direccion" class="form-label mt-4">Dirección</label>
							<textarea class="form-control" id="direccion" rows="3"></textarea>
						</div>

						<br/>

						<button onClick={procesarDatos} class="btn btn-primary botonRepararTuPC">Guardar</button>
								
					</div>
				</div>

			</Modal>

		</AnimatedPage>
	);
}

export default PerfilEmpresaScreen;