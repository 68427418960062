import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc, referencia, almacenamiento, subirArchivo, obtenerURL} from '../conex.js';
import {datosUsuario} from '../usuarios/usuarios';
import {enviarNotificacion} from '../notificaciones/notificaciones';
import {fechaHoy} from '../../global/datosCalendario.js';

export async function gestionarReparacion(id, data, validarSubir){

	let datosFinales = false;

	if (validarSubir == true) { console.log('subir');

		const storageRef = referencia(almacenamiento, 'assets/reparaciones/'+id+'/'+id);

		await subirArchivo(storageRef, data.archivo);

		let archivo = await obtenerURL(storageRef);

		datosFinales = {
			activo: data.activo,
	    	cliente: data.cliente,
	    	idCliente: data.idCliente,
			descripcion: data.descripcion,
			tiempoEstimado: data.tiempoEstimado,
			estadoReparacion: data.estadoReparacion,
			fechaCreacion: data.fechaCreacion,
			uid: data.uid,
			tecnico: data.tecnico,
			empresa: data.empresa,
			archivo: archivo,
			numeroFolio: data.numeroFolio,
		};
	}else{

		datosFinales = {
			activo: data.activo,
	    	cliente: data.cliente,
	    	idCliente: data.idCliente,
			descripcion: data.descripcion,
			tiempoEstimado: data.tiempoEstimado,
			estadoReparacion: data.estadoReparacion,
			fechaCreacion: data.fechaCreacion,
			uid: data.uid,
			tecnico: data.tecnico,
			empresa: data.empresa,
			archivo: '',
			numeroFolio: data.numeroFolio,
		};
	}

	let usuario = await datosUsuario(data.idCliente);

	await set(documento(db, "reparaciones", id), datosFinales);

	if (usuario != false) {

		let envio = await fetch('https://app.satflow.es/reparacion-de-ordenadores-backend/enviarEmail/estadoReparacion.php',{
			method: 'POST',
			headers:{
			    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			body: "nombre_cliente="+data.cliente+
			    "&email_cliente="+usuario.email+
			    "&descripcion="+data.descripcion+
			    "&estado="+data.estadoReparacion+
			    "&tiempoEstimado="+data.tiempoEstimado
		});

		let noti = {
			de: data.uid,
			para: data.idCliente,
			titulo: data.tecnico,
			descripcion: 'Estado de la reparación de su equipo',
			datos:{
				tipo: 'reparacion',
				id: id,
			},
			vistaEmpresa: false,
			vistaCliente: false,
			fechaCreacion: data.fechaCreacion,
		};

		await enviarNotificacion(noti);

		let noti2 = {
			de: data.idCliente,
			para: data.uid,
			titulo: data.cliente,
			descripcion: 'A solicitado la reparación de su equipo',
			datos:{
				tipo: 'reparacion',
				id: id,
			},
			vistaEmpresa: false,
			vistaCliente: false,
			fechaCreacion: data.fechaCreacion,
		};

		await enviarNotificacion(noti2);
	}	

	return true;
}

export async function consultarReparaciones(empresa){

	let reparaciones = [];

	const q = buscar(coleccion(db, "reparaciones"), donde("empresa", "==", empresa));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		reparaciones.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return reparaciones;
}

export async function eliminarReparacion(id){

	await eliminarDoc(documento(db, "reparaciones", id));

	return true;
}

export async function datosReparacion(id){

	const docRef = documento(db, "reparaciones", id);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}

export async function gestionOrdenador(id, datos){

	await set(documento(db, "ordenadores", id), datos);

	var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    var elId = "";
      	for (var i=0; i<20; i++) elId +=characters.charAt(Math.floor(Math.random()*characters.length));

    let elUsuario = await datosUsuario(datos.uid);
	let elTecnico = await datosUsuario(datos.idEmpresa);
	let fechaCreacion = await fechaHoy();

	if (elUsuario != false) {

		let datosReparacion = {
			activo: true,
			cliente: elUsuario.data.nombre,
			idCliente: datos.uid,
			descripcion: datos.descripcionDelProblema,
			tiempoEstimado: '7',
			estadoReparacion: 'Pendiente',
			fechaCreacion: fechaCreacion,
			uid: datos.idEmpresa,
			tecnico: elTecnico.data.nombre,
			empresa: elTecnico.data.urlEmpresa,
			archivo: '',
		};

		await gestionarReparacion(elId, datosReparacion, false);
	}

	return true;
}

export async function datosOrdenador(uid){

	let ordenadores = [];

	const q = buscar(coleccion(db, "ordenadores"), donde("uid", "==", uid));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		ordenadores.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	if (ordenadores.length > 0) {

		return ordenadores[0];
	}else{

		return false;
	}
}

export async function datosNumeroReparacion(numeroFolio){

	let reparacion = [];

	const q = buscar(coleccion(db, "reparaciones"), donde("numeroFolio", "==", numeroFolio));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		reparacion.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	if (reparacion.length > 0) {

		return reparacion[0];
	}else{

		return false;
	}
}