import { useEffect, useState } from 'react';
import './reparaciones-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {Modal} from '../../../components/modals/modals';
import {edit, trash, flechaVer} from '../../../global/imgs.js';
import {consultarUsuarios} from '../../../backend/usuarios/usuarios';
import {fechaHoy} from '../../../global/datosCalendario.js';
import {gestionarReparacion, consultarReparaciones, eliminarReparacion} from '../../../backend/reparaciones/reparaciones';
import {datosSuscripcion} from '../../../backend/suscripciones/suscripciones';

function ReparacionesEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [pantalla, CambioPantalla] = useState('');

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [modalReparacion, CambioModalReparacion] = useState(false);

	const [idReparacion, CambioIdReparacion] = useState(false);
	const [busquedaUsuarios, CambioBusquedaUsuarios] = useState(false);
	const [usuarios, CambioUsuarios] = useState([]);
	const [usuariosFinales, CambioUsuariosFinales] = useState([]);
	const [limiteUsuarios, CambioLimiteUsuarios] = useState(20);
	const [numeroFolio, CambioNumeroFolio] = useState(parseInt(Math.random() * (999999 - 1000) + 1000));
	const [subirArchivo, CambioSubirArchivo] = useState(false);

	const [busquedaReparaciones, CambioBusquedaReparaciones] = useState(false);
	const [reparaciones, CambioReparaciones] = useState([]);
	const [reparacionesFinales, CambioReparacionesFinales] = useState([]);
	const [limiteReparaciones, CambioLimiteReparaciones] = useState(30);
	const [reparacionesPermitidas, CambioReparacionesPermitidas] = useState(30);

	const [preguntaModal, CambioPreguntaModal] = useState('');
	const [modalEliminarReparacion, CambioModalEliminarReparacion] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('reparaciones').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaReparaciones == false) {

			consultarReparaciones(urlEmpresa).then(response =>{

				let lasReparaciones = [];
				let cero = 0;

				for (let reparacion of response) {
					
					if (cero <= limiteReparaciones) {

						lasReparaciones.push(reparacion);
					}

					cero++;
				}

				CambioReparaciones(response);
				CambioReparacionesFinales(lasReparaciones);
			});

			CambioBusquedaReparaciones(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

					if (elUsuario.rol == 'empleado') {

						document.getElementById('empleados').setAttribute('style', 'display: none;');
					}

					Swal.fire({
				      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
				          	'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
				          	'</div>',
				      	showConfirmButton: false,
				      	allowOutsideClick: false,
				      	allowEnterKey: false,
				      	allowEscapeKey: false,
				    });

				    if (elUsuario.suscripcion != false) {

					    let laSuscripcion = await datosSuscripcion(elUsuario.suscripcion);

					    if (laSuscripcion != false) {

					    	if (laSuscripcion.data.cantidadRepararciones == '') {

					    		CambioReparacionesPermitidas('Ilimitada');
					    	}else{	

					    		CambioReparacionesPermitidas(parseInt(laSuscripcion.data.cantidadRepararciones));
					    	}
					    }else{

					    	CambioReparacionesPermitidas(20);
					    }
					}else{

						CambioReparacionesPermitidas(20);
					}

				    Swal.close();
			}
		}else{

			window.location.href = '/empresa/login';
		}

		if (busquedaUsuarios == false) {

			consultarUsuarios('cliente').then(response =>{

				let losUsuarios = [];
				let cero = 0;

				for (let usuario of response) {
					
					if (cero <= limiteUsuarios) {

						losUsuarios.push(usuario);
					}

					cero++;
				}

				CambioUsuarios(response);
				//CambioUsuariosFinales(losUsuarios);
			});

			CambioBusquedaUsuarios(true);
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const buscarCliente = async function(){

		let query = document.getElementById('buscarCliente').value;
		document.getElementById('idCliente').value = '';

	    if (query === '') {

	    	CambioUsuariosFinales([]);
	    	document.getElementById('idCliente').value = '';
	    }else{
	        
	        let resultado = [];

	        const datos = usuarios;

	        const regex = new RegExp(`${query.trim()}`, 'i');

	        const laBusqueda = datos.filter(datos => datos.data.nombre.search(regex) >= 0);

	        let losUsuarios = [];
			let cero = 0;

			for (let usuario of laBusqueda) {
					
				if (cero <= limiteUsuarios) {

					losUsuarios.push(usuario);
				}

				cero++;
			}

			/*if (laBusqueda.length > limiteUsuarios) {

				document.getElementById('botonVerMas').classList.remove('ocultarVerMas');
			}else{

				document.getElementById('botonVerMas').classList.add('ocultarVerMas');
			}*/

			CambioUsuariosFinales(losUsuarios);
	    }
	}

	const elegirUsuario = async function(item){

		document.getElementById('buscarCliente').value = item.data.nombre;
		document.getElementById('idCliente').value = item.id;
		CambioUsuariosFinales([]);
	}

	const procesarReparacion = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var id = "";
      		for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));
	
      	let cliente = document.getElementById('buscarCliente').value;
      	let idCliente = document.getElementById('idCliente').value;
      	let descripcion = document.getElementById('descripcion').value;
      	let tiempoEstimado = document.getElementById('tiempoEstimado').value;
		let estadoReparacion = document.getElementById('estadoReparacion').value;

      	let fechaCreacion = await fechaHoy();
		let elUsuario = await validarUsuario();

		if (cliente === '' || idCliente === '' || descripcion === '' || tiempoEstimado === '' || estadoReparacion === '') {

	    	Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
	    }else if (elUsuario.uid == undefined) {

			Swal.fire({
			    icon: 'info',
			    title: 'No hay usuario autenticado.',
			});
		}else{

			let elId = '';
	    	let foto = false;
	    	let dataFoto = '';

	    	if (idReparacion != false) {

	    		elId = idReparacion;
	    	}else{

	    		elId = id;
	    	}

	    	if (subirArchivo != false) {

	    		foto = true;
	    		dataFoto = document.getElementById('archivo').files[0];
	    	}

	    	let datos = {
	    		activo: true,
	    		cliente: cliente,
	    		idCliente: idCliente,
				descripcion: descripcion,
				tiempoEstimado: tiempoEstimado,
				estadoReparacion: estadoReparacion,
				fechaCreacion: fechaCreacion,
				uid: elUsuario.uid,
				tecnico: elUsuario.nombre,
				empresa: urlEmpresa,
				archivo: dataFoto,
				numeroFolio: numeroFolio,
	    	};

	    	gestionarReparacion(elId, datos, foto).then(response =>{

	    		Swal.fire({
			        icon: 'success',
			        title: 'Datos Guardados.',
			    });
	    		//CambioModalReparacion(false);
	    		CambioPantalla('gestionar');
				CambioIdReparacion(false);
				CambioBusquedaReparaciones(false);
				CambioNumeroFolio(parseInt(Math.random() * (999999 - 1000) + 1000));
	    	})
		}
	}

	const editarReparacion = async function(item){

		//CambioModalReparacion(true);
		CambioPantalla('gestionar');
		CambioIdReparacion(item.id);
		CambioNumeroFolio(item.data.numeroFolio);
		window.setTimeout(ponerDatos, 1000, item);
	}

	const ponerDatos = async function(item){

		document.getElementById('buscarCliente').value = item.data.cliente;
		document.getElementById('idCliente').value = item.data.idCliente;
		document.getElementById('descripcion').value = item.data.descripcion;
		document.getElementById('tiempoEstimado').value = item.data.tiempoEstimado;
	}

	const preguntarEliminarReparacion = async function(item){

		CambioPreguntaModal('¿Desea eliminar esta reparación?');
		CambioIdReparacion(item.id);
		CambioModalEliminarReparacion(true);
	}

	const procederEliminarReparacion = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    eliminarReparacion(idReparacion).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Reparaciòn eliminada.',
			});

			CambioModalEliminarReparacion(false);
			CambioIdReparacion(false);
			CambioBusquedaReparaciones(false);
	    })
	}

	if (pantalla == 'gestionar') {

		return(
			<AnimatedPage duracion={1}>

				<div class="centralPanel">
					
					<MenuEmpresa
						urlEmpresa={urlEmpresa}
					/>

					<div class="contenidoPanel">

						<BarraDeTareas/>

						

							<div class="row">
								<div class="col-sm">

									<div class="formContainer">
										<div class="headerForm">
											<h5 class="tituloForm">Gestionar Reparación</h5>
										</div>
										<div class="formulario">

											<p onClick={() => {
												CambioPantalla('');
												CambioIdReparacion(false);
											}} class="equix">X</p>

											<div class="form-group">
												<label for="buscarCliente" class="form-label mt-4">Cliente*</label>
												<input onKeyUp={buscarCliente} type="text" class="form-control" id="buscarCliente" placeholder="Buscar Cliente*" />
											</div>

											<input type="hidden" id="idCliente" />

											<div class="listaBusqueda">

												{usuariosFinales.map((item) =>(
													<div class="usuario" onClick={() => elegirUsuario(item)}>
														<img src={item.data.foto} class="imgUsuario" />
														<span class="nombreUsuario">{item.data.nombre}</span>
													</div>
												))}

											</div><br/>

											<div class="form-group">
												<label for="descripcion" class="form-label mt-4">Descripción del problema*</label>
												<textarea class="form-control" id="descripcion" rows="3"></textarea>
											</div><br/>

											<div class="form-group">
												<label for="tiempoEstimado" class="form-label mt-4">Tiempo Estimado*</label>
												<input type="number" class="form-control" id="tiempoEstimado" placeholder="Tiempo Estimado" />
											</div>

											<br/>

											<div class="form-group">
										      <label for="estadoReparacion" class="form-label mt-4">Estado de la reparación*</label>
										      <select class="form-select" id="estadoReparacion">
										        <option value="Pendiente">Pendiente</option>
										        <option value="En Proceso">En Proceso</option>
										        <option value="Completado">Completado</option>
										        <option value="Cancelado">Cancelado</option>
										      </select>
										    </div> <br/>

										    <div class="form-group">
										    	<label for="archivo" class="form-label mt-4">Documento adjunto (Fotos, Informes Tecnico)</label>
										    	<input type="file" id="archivo" onChange={() => CambioSubirArchivo(true)} />
											</div> <br/>

											<button onClick={procesarReparacion} class="btn btn-primary botonRepararTuPC">Guardar</button>
													
										</div>
									</div>

								</div>
							</div>

					</div>

				</div>
			</AnimatedPage>
		);
	}else{

		return(
			<AnimatedPage duracion={1}>

				<div class="centralPanel">
					
					<MenuEmpresa
						urlEmpresa={urlEmpresa}
					/>

					<div class="contenidoPanel">

						<BarraDeTareas/>

						

							<div class="row">
								<div class="col-sm">

									<div class="formContainer tablaContainer">
										<div class="headerForm headerTabla" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
											<h5 class="tituloForm">Reparaciones ({reparaciones.length}/{reparacionesPermitidas}) <span class="cambiarSuscripcion">Cambiar suscripción</span></h5>
											<button onClick={() => {
												CambioPantalla('gestionar');
												CambioNumeroFolio(parseInt(Math.random() * (999999 - 1000) + 1000));
											}} class="btn btn-primary botonRepararTuPC widthContent">Añadir Reparaciòn</button>
										</div>
										<div class="formulario">
											<table class="table table-hover">
												<tr>
											      	<th class="th" scope="row">ID</th>
											      	<th class="th">Cliente</th>
											      	<th class="th">Descripción</th>
											      	<th class="th">Técnico Responsable</th>
											      	<th class="th">Tiempo Estimado</th>
											      	<th class="th">Acciones</th>
											    </tr>

											    {reparacionesFinales.map((item) =>(
											    	<tr class="bordeTr">
												      	<td class="elTr" scope="row">{item.data.numeroFolio}</td>
												      	<td class="elTr">{item.data.cliente}</td>
												      	<td class="elTr">{item.data.descripcion}</td>
												      	<td class="elTr">{item.data.tecnico}</td>
												      	<td class="elTr">{item.data.tiempoEstimado} día(s)</td>
												      	<td class="elTr">
												      		<img onClick={() => editarReparacion(item)} src={edit} class="eye" />
													      	<img onClick={() => preguntarEliminarReparacion(item)} src={trash} class="eye" />
													      	<img onClick={() => window.location.href = '/'+urlEmpresa+'/reparacion/'+item.id} src={flechaVer} class="eye" />
												      	</td>
												    </tr>
											    ))}
											    
											</table>
										</div>
									</div>

								</div>
							</div>

					</div>

				</div>

				<Modal mostrar={modalReparacion}>

					<p onClick={() => {
						CambioModalReparacion(false);
						CambioIdReparacion(false);
					}} class="equix">X</p>
					<div class="formContainer formGestion">
						<div class="headerForm">
							<h5 class="tituloForm">Gestionar Reparación</h5>
						</div>
						<div class="formulario">

							<div class="form-group">
								<label for="buscarCliente" class="form-label mt-4">Cliente*</label>
								<input onKeyUp={buscarCliente} type="text" class="form-control" id="buscarCliente" placeholder="Buscar Cliente*" />
							</div>

							<input type="hidden" id="idCliente" />

							<div class="listaBusqueda">

								{usuariosFinales.map((item) =>(
									<div class="usuario" onClick={() => elegirUsuario(item)}>
										<img src={item.data.foto} class="imgUsuario" />
										<span class="nombreUsuario">{item.data.nombre}</span>
									</div>
								))}

							</div><br/>

							<div class="form-group">
								<label for="descripcion" class="form-label mt-4">Descripción del problema*</label>
								<textarea class="form-control" id="descripcion" rows="3"></textarea>
							</div><br/>

							<div class="form-group">
								<label for="tiempoEstimado" class="form-label mt-4">Tiempo Estimado*</label>
								<input type="number" class="form-control" id="tiempoEstimado" placeholder="Tiempo Estimado" />
							</div>

							<br/>

							<div class="form-group">
						      <label for="estadoReparacion" class="form-label mt-4">Estado de la reparación*</label>
						      <select class="form-select" id="estadoReparacion">
						        <option value="Pendiente">Pendiente</option>
						        <option value="En Proceso">En Proceso</option>
						        <option value="Completado">Completado</option>
						        <option value="Cancelado">Cancelado</option>
						      </select>
						    </div> <br/>

						    <div class="form-group">
						    	<label for="archivo" class="form-label mt-4">Documento adjunto (Fotos, Informes Tecnico)</label>
						    	<input type="file" id="archivo" onChange={() => CambioSubirArchivo(true)} />
							</div> <br/>

							<button onClick={procesarReparacion} class="btn btn-primary botonRepararTuPC">Guardar</button>
									
						</div>
					</div>

				</Modal>

				<Modal mostrar={modalEliminarReparacion}>
				    <h3 class="preguntaModal">
				    	{preguntaModal}
				    </h3>
				    <div class="botonesModal">
				    	<button onClick={procederEliminarReparacion} class="btn btn-success">Si</button>
				    	<button onClick={() => {
					    	CambioModalEliminarReparacion(false);
					    	CambioIdReparacion(false);
					    }} class="btn btn-danger">No</button>
				    </div>
				</Modal>

			</AnimatedPage>
		);
	}
}

export default ReparacionesEmpresaScreen;