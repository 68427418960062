import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './modals-styles.css';
import Portal from '../portal/portal';

export class Modal extends Component{
	render(){
		return(
			<Portal>
				{this.props.mostrar && (
					<div class="modalFondo">
						<div class="contenidoModal">
							{this.props.children}
						</div>
					</div>
				)}
			</Portal>
		);
	}
}