import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc, referencia, almacenamiento, subirArchivo, obtenerURL} from '../conex.js';
import {datosUsuario} from '../usuarios/usuarios';

export async function enviarNotificacion(datos){

	var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var id = "";
      		for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    await set(documento(db, "notificaciones", id), datos);

	return true;
}

export async function consultarNotis(uid){

	let notificaciones = [];
	let notificacionesFinales = [];

	const q = buscar(coleccion(db, "notificaciones"), donde("para", "==", uid));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		notificaciones.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	for (let noti of notificaciones) {
		
		let usuario = await datosUsuario(noti.data.de);

		if (usuario != false) {

			notificacionesFinales.push({
				id: noti.id,
				data: noti.data,
				usuario: usuario,
			});
		}
	}

	notificacionesFinales.sort(function(a, b) { 
		a = a.data.fechaCreacion; 
		b = b.data.fechaCreacion; 
		return a<b ? -1 : a>b ? 1 : 0; 
	});

	return notificacionesFinales;
}

export async function actualizarNoti(item){

	const washingtonRef = documento(db, "notificaciones", item.id);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  vistaCliente: true,
	  vistaEmpresa: true,
	});

	return true;
}