import { useEffect, useState } from 'react';
import './login-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {datosUsuario} from '../../../backend/usuarios/usuarios';
import {useParams} from 'react-router-dom';
import {validarUsuario} from '../../../global/validarUsuario';
import {iniciarSesion, recuperarPassword, resgistrarUsuario} from '../../../backend/auth/auth';
import {Modal} from '../../../components/modals/modals';
import {gestionOrdenador} from '../../../backend/reparaciones/reparaciones';
import {logoLogin, logoFinal1} from '../../../global/imgs.js';
import {consultarLogo} from '../../../backend/usuarios/usuarios';

function LoginClienteScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;
  	const id = routeParams.id;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [pantalla, CambioPantalla] = useState('registro');
	const [modalPassword, CambioModalPassword] = useState(false);
	const [usuario, CambioUsuario] = useState(false);
	const [claveCliente, CambioClaveCliente] = useState('');
	const [emailCliente, CambioEmailCliente] = useState('');
	const [idOrdenador, CambioIdOrdenador] = useState('');
	const [tipoDeOrdenador, CambioTipoOrdenador] = useState('');
	const [passwordDelOrdenador, CambioPasswordDelOrdenador] = useState('');
	const [marcaDelOrdenador, CambioMarcaDelOrdenador] = useState('');
	const [modeloDelOrdenador, CambioModeloDelOrdenador] = useState('');
	const [descripcionDelProblema, CambioDescripcionDelProblema] = useState('');
	const [cuentaConCertificadosDigitales, CambioCuentaConCertificadosDigitales] = useState(false);
	
	const [elLogo, CambioElLogo] = useState(logoFinal1);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	});

	const ponerUsuario = async function(){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
	    });

		let elUsuario = await validarUsuario();
		let empresa = await datosUsuario(id);

		if (empresa != false) {

			if (elUsuario != false) {

				if (elUsuario.rol == 'cliente') {

					if (elUsuario.urlEmpresa != urlEmpresa && empresa.id != id) {

						terminarSesion();
					}else{

						window.location.href = '/cliente/panel/'+urlEmpresa+'/'+id;
					}
				}else if (elUsuario.rol == 'empresa' || elUsuario.rol == 'empleado') {

					window.location.href = '/'+elUsuario.urlEmpresa;
				}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

					window.location.href = '/admin/';
				}else{

					terminarSesion();
				}
			}else{

				Swal.close();

				consultarLogo(urlEmpresa).then(response =>{

					if (response != false) {

						CambioElLogo(response);
					}
				});
			}
		}else{

			terminarSesion();
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/';
	}

	const procesarLogin = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('email').value;
	    let password = document.getElementById('password').value;

	    if (email === '' || password === '') {

	      Swal.fire({
	        icon: 'info',
	        title: 'Complete todos los campos para continuar.',
	      });
	    }else{

	      iniciarSesion(email, password).then(response =>{

	        if (response != false) {

	          terminar(response);
	        }else{

	          Swal.fire({
	            icon: 'error',
	            title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
	          });
	        }
	      })
	    }
	}

	const terminar = async function(respuesta){

		if (respuesta != false) {

			if (respuesta.rol == 'cliente') {

				await localStorage.setItem('usuarioReparacionDeOrdenadores', JSON.stringify(respuesta));
				
				window.location.href = '/cliente/panel/'+urlEmpresa+'/'+id;
			}else{

				Swal.fire({
			        icon: 'error',
			        title: 'No tiene permisos para ingresar aqui.',
			    });
			}
		}else{

			Swal.fire({
		        icon: 'error',
		        title: 'Lo sentimos pero hubo un error al autenticar sus datos.',
		    });
		}
	}

	const procesarRegistro = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('email').value;
	    let password = document.getElementById('password').value;
	    let passwordConfirmar = document.getElementById('passwordConfirmar').value;
	    let nombre = document.getElementById('nombre').value;
	    let telefono = document.getElementById('telefono').value;
	    let DNIoNIF = document.getElementById('DNIoNIF').value;
	    let direccion = document.getElementById('direccion').value;

	    if (email === '' || password === '' || passwordConfirmar === '' || nombre === '' || telefono === '' || DNIoNIF === '' || direccion === '') {

	    	Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
	    }else if (isNaN(telefono)) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'El telefono debe ser numerico.',
		    });
	    }else if (password != passwordConfirmar) {

	    	Swal.fire({
		        icon: 'info',
		        title: 'La contraseña no coincide.',
		    });
	    }else{

	    	let datos = {
	    		email: email,
				password: password,
				nombre: nombre,
				telefono: telefono,
				DNIoNIF: DNIoNIF,
				direccion: direccion,
				rol: 'cliente',
				idEmpresa: id,
				urlEmpresa: urlEmpresa,
	    	};

	    	resgistrarUsuario(datos).then(response =>{

		        if (response == 'ya existe') {

		        	Swal.fire({
		            	icon: 'error',
		            	title: 'El usuario ingresado ya existe.',
		          	});
		        }else if (response != false) {

		          	//terminar(response);
		          	CambioPantalla('ordenador');
					CambioUsuario(response);
					CambioClaveCliente(password);
					CambioEmailCliente(email);
					Swal.close();
		        }else{

		          	Swal.fire({
		            	icon: 'error',
		            	title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
		          	});
		        }
		    });
	    }
	}

	const pasarAOrdenador = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var elid = "";
      		for (var i=0; i<20; i++) elid +=characters.charAt(Math.floor(Math.random()*characters.length));

		if (tipoDeOrdenador === '' || passwordDelOrdenador === '' || marcaDelOrdenador === '' || modeloDelOrdenador === '' || descripcionDelProblema === '') {

			Swal.fire({
		        icon: 'info',
		        title: 'Complete todos los campos para continuar.',
		    });
		}else{

			let datos = {
				tipoDeOrdenador: tipoDeOrdenador,
				passwordDelOrdenador: passwordDelOrdenador,
				marcaDelOrdenador: marcaDelOrdenador,
				modeloDelOrdenador: modeloDelOrdenador,
				descripcionDelProblema: descripcionDelProblema,
				cuentaConCertificadosDigitales: cuentaConCertificadosDigitales,
				uid: usuario.uid,
				idEmpresa: id,
			};

			gestionOrdenador(elid, datos).then(response =>{

				//terminar(usuario);
				Swal.close();
				CambioIdOrdenador(elid);
				CambioPantalla('finalizar');
			});
		}
	}

	const procesarRecuperacion = async function(){

	    Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let emailRecuperar = document.getElementById('emailRecuperar').value;

	    if (emailRecuperar === '') {

	      Swal.fire({
	        title: 'Complete todos los campos para continuar.',
	        icon: 'info',
	      });
	    }else{
	      recuperarPassword(emailRecuperar).then(response =>{
	        if (response) {

	          Swal.fire({
	            title: 'Se ha enviado un correo electronico.',
	            icon: 'success',
	          });
	          CambioModalPassword(false);
	        }
	      })
	    }
	}

	if (pantalla == 'registro') {

		return(
			<AnimatedPage duracion={1}>
				<div class="fondoContainer">
					<div class="fondoHerramientas" style={{height: '159vh',}}></div>
					<div class="container containerLargoCompleto">

						<div class="row">
							<div class="col-sm" style={{paddingTop: '5%',}}>

								<div class="formContainer">
									<div class="pasos">
									
										<div class="paso">
											<span class="textoPasoActivo">Datos personales</span>
											<div class="puntoPasoActivo"></div>
										</div>

										<div class="lineaInactive"></div>

										<div class="paso">
											<span class="textoPasoInactivo">Datos del ordenador</span>
											<div class="puntoPasoInactivo"></div>
										</div>

										<div class="lineaInactive"></div>

										<div class="paso">
											<span class="textoPasoInactivo">Confirmar orden</span>
											<div class="puntoPasoInactivo"></div>
										</div>

									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer centrarTarjeta">
									<img src={elLogo} class="logoLogin" />
									<div class="headerForm">
										<h5 class="tituloForm">Registrate</h5>
										<span class="preguntaRegistro" style={{color: '#000', fontWeght: 'bold',}}>¿Ya tienes una cuenta? <span class="registrateAqui" onClick={() => CambioPantalla('')}>Ingresar Aqui</span></span>
									</div>
									<div class="formulario">

										<div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Email*</label>
									      	<input type="email" class="inputSatflow" id="email" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Contraseña*</label>
									      	<input type="password" class="inputSatflow" id="password" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Confirme su Contraseña*</label>
									      	<input type="password" class="inputSatflow" id="passwordConfirmar" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Nombre y Apellido*</label>
									      	<input type="text" class="inputSatflow" id="nombre" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Escriba el telefono*</label>
									      	<input type="text" class="inputSatflow" id="telefono" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Escriba el DNI / NIF*</label>
									      	<input type="text" class="inputSatflow" id="DNIoNIF" />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Dirección*</label>
									      	<textarea class="inputSatflow" id="direccion" rows="3"></textarea>
									    </div>

									    <br/>

									    <button onClick={procesarRegistro} class="btn btn-primary botonRepararTuPC">Registrarte</button>
									
									    <br/><br/>
									</div>
								</div><br/><br/>

							</div>
						</div>

					</div>
				</div>

			</AnimatedPage>
		);
	}else if (pantalla == 'ordenador') {

		return(
			<AnimatedPage duracion={1}>
				<div class="fondoContainer">
					<div class="fondoHerramientas" style={{height: '136vh',}}></div>
					<div class="container containerLargoCompleto">

						<div class="row">
							<div class="col-sm" style={{paddingTop: '5%',}}>

								<div class="formContainer">
									<div class="pasos">
									
										<div class="paso">
											<span class="textoPasoActivo">Datos personales</span>
											<div class="puntoPasoActivo"></div>
										</div>

										<div class="lineaActive"></div>

										<div class="paso">
											<span class="textoPasoActivo">Datos del ordenador</span>
											<div class="puntoPasoActivo"></div>
										</div>

										<div class="lineaInactive"></div>

										<div class="paso">
											<span class="textoPasoInactivo">Confirmar orden</span>
											<div class="puntoPasoInactivo"></div>
										</div>

									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer centrarTarjeta">
									<img src={elLogo} class="logoLogin" />
									<div class="headerForm">
										<h5 class="tituloForm">Registrar orden de reparación</h5>
									</div>
									<div class="formulario">

										<div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Tipo de ordenador*</label>
									      	<input type="text" class="inputSatflow" id="tipoDeOrdenador" value={tipoDeOrdenador} onChange={() => CambioTipoOrdenador(document.getElementById('tipoDeOrdenador').value)} />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Contraseña del ordenador*</label>
									      	<input type="password" class="inputSatflow" id="passwordDelOrdenador" value={passwordDelOrdenador} onChange={() => CambioPasswordDelOrdenador(document.getElementById('passwordDelOrdenador').value)} />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Marca del ordenador*</label>
									      	<input type="text" class="inputSatflow" id="marcaDelOrdenador" value={marcaDelOrdenador} onChange={() => CambioMarcaDelOrdenador(document.getElementById('marcaDelOrdenador').value)} />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Modelo del ordenador*</label>
									      	<input type="text" class="inputSatflow" id="modeloDelOrdenador" value={modeloDelOrdenador} onChange={() => CambioModeloDelOrdenador(document.getElementById('modeloDelOrdenador').value)} />
									    </div>

									    <div class="form-group">
									      	<label for="email" class="form-label mt-4 labelSatflow">Descripción del problema*</label>
									      	<input type="text" class="inputSatflow" id="descripcionDelProblema" value={descripcionDelProblema} onChange={() => CambioDescripcionDelProblema(document.getElementById('descripcionDelProblema').value)} />
									    </div>

									    <fieldset>

									    	<legend class="mt-4"></legend>
									    	<div class="form-check">
										        <input class="form-check-input" type="checkbox" id="cuentaConCertificadosDigitales" value={cuentaConCertificadosDigitales} onChange={() => CambioCuentaConCertificadosDigitales(document.getElementById('cuentaConCertificadosDigitales').checked)} />
										        <label class="form-check-label" for="flexCheckDefault">
										          	Cuenta con certificados digitales
										        </label>
										    </div>

									    </fieldset>

									    <br/>

									    <button onClick={() => CambioPantalla('confirmar')} class="btn btn-primary botonRepararTuPC">Siguiente</button>
									
									</div>
								</div><br/><br/>

							</div>
						</div>

					</div>
				</div>

			</AnimatedPage>
		);
	}else if (pantalla == 'confirmar') {

		return(
			<AnimatedPage duracion={1}>
				<div class="fondoContainer">
					<div class="fondoHerramientas"></div>
					<div class="container containerLargoCompleto">

						<div class="row">
							<div class="col-sm" style={{paddingTop: '5%',}}>

								<div class="formContainer">
									<div class="pasos">
									
										<div class="paso">
											<span class="textoPasoActivo">Datos personales</span>
											<div class="puntoPasoActivo"></div>
										</div>

										<div class="lineaActive"></div>

										<div class="paso">
											<span class="textoPasoActivo">Datos del ordenador</span>
											<div class="puntoPasoActivo"></div>
										</div>

										<div class="lineaActive"></div>

										<div class="paso">
											<span class="textoPasoActivo">Confirmar orden</span>
											<div class="puntoPasoActivo"></div>
										</div>

									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer centrarTarjeta">
									<img src={elLogo} class="logoLogin" />
									<div class="headerForm">
										<h5 class="tituloForm">Registrar orden de reparación</h5>
									</div>
									<div class="formulario">

										<div>
									      	<label for="email" class="form-label mt-4 labelSatflow">Punto de recogida</label>
									      	<select class="form-select" id="puntoDeRecogida">
									        	<option value="Domicilio del cliente">Domicilio del cliente</option>
									        	<option value="Oficinas">Oficinas</option>
									      	</select>
									    </div>

									    <br/>

									    <button onClick={pasarAOrdenador} class="btn btn-primary botonRepararTuPC">Completar</button>
									
									</div>
								</div><br/><br/>

							</div>
						</div>

					</div>
				</div>

			</AnimatedPage>
		);
	}else if (pantalla == 'finalizar') {

		return(
			<AnimatedPage duracion={1}>
				<div class="fondoContainer">
					<div class="fondoHerramientas"></div>
					<div class="container containerLargoCompleto">

						<div class="row">
							<div class="col-sm" style={{paddingTop: '5%',}}>

								<div class="formContainer">
									<div class="pasos">
									
										<div class="paso">
											<span class="textoPasoActivo">Datos personales</span>
											<div class="puntoPasoActivo"></div>
										</div>

										<div class="lineaActive"></div>

										<div class="paso">
											<span class="textoPasoActivo">Datos del ordenador</span>
											<div class="puntoPasoActivo"></div>
										</div>

										<div class="lineaActive"></div>

										<div class="paso">
											<span class="textoPasoActivo">Confirmar orden</span>
											<div class="puntoPasoActivo"></div>
										</div>

									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer centrarTarjeta">
									<div class="headerForm">
										<h5 class="tituloForm">Su orden ha sido registrada</h5>
									</div>
									<div class="formulario">

										<span>Acaba de completar el registro con éxito, a continuación te proveemos los datos de acceso para que puedas realizar el seguimiento del mismo.</span>

										<br/><br/>

										<span>
											<span><span style={{fontWeight: 'bold',}}>ID de la orden: </span>{idOrdenador}</span><br/>
											<span><span style={{fontWeight: 'bold',}}>Email: </span>{emailCliente}</span><br/>
											<span><span style={{fontWeight: 'bold',}}>Contraseña: </span>{claveCliente}</span><br/>
										</span>

										<br/>

									    <button onClick={() => CambioPantalla('')} class="btn btn-primary botonRepararTuPC">Iniciar Sesión</button>
									
									</div>
								</div><br/><br/>

							</div>
						</div>

					</div>
				</div>

			</AnimatedPage>
		);
	}else{

		return(
			<AnimatedPage duracion={1}>
				<div class="fondoContainer">
					<div class="fondoHerramientas"></div>
					<div class="container containerLargoCompleto">

						<div class="row">
							<div class="col-sm" style={{paddingTop: '5%',}}>

								<div class="formContainer centrarTarjeta">
										<img src={elLogo} class="logoLogin" />
										<div class="headerForm">
											<h5 class="tituloForm">Ingresar su panel personal</h5>
											<span class="preguntaRegistro" style={{color: '#000', fontWeght: 'bold',}}>¿No tienes una cuenta? <span class="registrateAqui" onClick={() => CambioPantalla('registro')}>Registrate Aqui</span></span>
										</div>
										<div class="formulario">
											<div class="form-group">
										      	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Email*</label>
										      	<input type="email" class="inputSatflow" id="email" />
										    </div>
										    <div class="form-group">
										      	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Contraseña*</label>
										      	<input type="password" class="inputSatflow" id="password" />
										    	<small onClick={() => CambioModalPassword(true)} id="emailHelp" class="form-text text-muted botonPreguntaPassword">¿Haz olvidado tu contraseña?</small>
										    </div><br/>

										    <button onClick={procesarLogin} class="btn btn-primary botonRepararTuPC">Entrar</button>

										    <br/><br/>
										</div>
									</div>

							</div>
						</div>

					</div>
				</div>

				<Modal mostrar={modalPassword}>
				    <p onClick={() => CambioModalPassword(false)} class="equix">X</p>
				    <div class="formContainer centrarTarjeta">
						<div class="headerForm">
							<h5 class="tituloForm">Recuperar Contraseña</h5>
						</div>
						<div class="formulario">
							<div class="form-group">
								<label for="emailRecuperar" class="form-label mt-4">Email</label>
								<input type="email" class="form-control" id="emailRecuperar" placeholder="Escriba su Email" />
							</div><br/>

							<button onClick={procesarRecuperacion} class="btn btn-primary botonRepararTuPC">Enviar</button>
						</div>
					</div>
				</Modal>
			</AnimatedPage>
		);
	}
}

export default LoginClienteScreen;