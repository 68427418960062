import { useEffect, useState } from 'react';
import './reparacion-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {datosUsuario} from '../../../backend/usuarios/usuarios';
import {useParams} from 'react-router-dom';
import {MenuCliente, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {datosReparacion, datosOrdenador} from '../../../backend/reparaciones/reparaciones';
import {InfoUsuario, ArchivoAdjunto} from '../../../components/reparaciones/reparaciones';

function ReparacionClienteScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;
  	const id = routeParams.id;
  	const idReparacion = routeParams.idReparacion;

  	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
  	const [ponerDatosDeLaReparacion, CambioPonerDatosDeLaReparacion] = useState(false);
	const [laReparacion, CambioLaReparacion] = useState(false);

	const [archivo, CambioArchivo] = useState(false);
	const [cliente, CambioCliente] = useState('');
	const [descripcion, CambioDescripcion] = useState('');
	const [empresa, CambiosEmpresa] = useState('');
	const [estado, CambioEstado] = useState('');
	const [elTecnico, CambioElTecnico] = useState('');
	const [tiempoEstimado, CambioTiempoEstimado] = useState('');

	const [dataCliente, CambioDataCliente] = useState(false);
	const [dataTecnico, CambioDataTecnico] = useState(false);

	const [tipoDeOrdenador, CambioTipoOrdenador] = useState('');
	const [passwordDelOrdenador, CambioPasswordDelOrdenador] = useState('');
	const [marcaDelOrdenador, CambioMarcaDelOrdenador] = useState('');
	const [modeloDelOrdenador, CambioModeloDelOrdenador] = useState('');
	const [descripcionDelProblema, CambioDescripcionDelProblema] = useState('');
	const [cuentaConCertificadosDigitales, CambioCuentaConCertificadosDigitales] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			//document.getElementById('home').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (ponerDatosDeLaReparacion == false) {

			verReparacion();
			CambioPonerDatosDeLaReparacion(true);
		}
	});

	const ponerUsuario = async function(){

		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
	    });

		let elUsuario = await validarUsuario();
		let empresa = await datosUsuario(id);

		if (empresa != false) {

			if (elUsuario != false) {

				if (elUsuario.rol == 'cliente') {

					if (elUsuario.urlEmpresa != urlEmpresa && empresa.id != id) {

						terminarSesion();
					}else{

						Swal.close();
						//window.location.href = '/cliente/panel/'+urlEmpresa+'/'+id;
					}
				}else if (elUsuario.rol == 'empresa' || elUsuario.rol == 'empleado') {

					window.location.href = '/'+elUsuario.urlEmpresa;
				}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

					window.location.href = '/admin/';
				}else{

					terminarSesion();
				}
			}else{

				window.location.href = '/cliente/login/'+urlEmpresa+'/'+id;
			}
		}else{

			terminarSesion();
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/';
	}

	const verReparacion = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          	'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	'</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

		let reparacion = await datosReparacion(idReparacion);
		let elUsuario = await validarUsuario();
		let ordenador = await datosOrdenador(reparacion.data.idCliente);

		if (reparacion != false) {

			let usuario = await datosUsuario(reparacion.data.idCliente);
			let tecnico = await datosUsuario(reparacion.data.uid);

			if (usuario != false && tecnico != false) {

				if (reparacion.data.archivo != '') {

					CambioArchivo(reparacion.data.archivo);
				}

				CambioCliente(reparacion.data.cliente);
				CambioDescripcion(reparacion.data.descripcion);
				CambiosEmpresa(reparacion.data.empresa);
				CambioEstado(reparacion.data.estadoReparacion);
				CambioElTecnico(reparacion.data.tecnico);
				CambioTiempoEstimado(reparacion.data.tiempoEstimado);
				CambioDataCliente(usuario);
				CambioDataTecnico(tecnico);

				CambioTipoOrdenador(ordenador.data.tipoDeOrdenador);
				CambioPasswordDelOrdenador(ordenador.data.passwordDelOrdenador);
				CambioMarcaDelOrdenador(ordenador.data.marcaDelOrdenador);
				CambioModeloDelOrdenador(ordenador.data.modeloDelOrdenador);
				CambioDescripcionDelProblema(ordenador.data.descripcionDelProblema);
				
				if (ordenador.data.cuentaConCertificadosDigitales == true) {

					CambioCuentaConCertificadosDigitales('Si');
				}else{

					CambioCuentaConCertificadosDigitales('No');
				}
				
				Swal.close();
			}else{

				if (elUsuario != false) {

					window.location.href = '/';
				}else{

					terminarSesion();
				}
			}
		}else{

			if (elUsuario != false) {

				window.location.href = '/';
			}else{

				terminarSesion();
			}
		}
	} 

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuCliente
					urlEmpresa={urlEmpresa}
					id={id}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					<div class="container">

						<div class="row">
							<div class="col-sm">

								<button onClick={() => window.location.href = '/cliente/reparaciones/'+urlEmpresa+'/'+id} class="btn btn-primary botonRepararTuPC">Volver a Reparaciones</button>	

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer">

									<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Datos del cliente</h5>
									</div>
									<div class="formulario">
										<InfoUsuario datos={dataCliente} />
									</div>
								</div>

							</div>

							<div class="col-sm">

								<div class="formContainer">

									<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Datos del tecnico responsable</h5>
									</div>
									<div class="formulario">
										<InfoUsuario datos={dataTecnico} />
									</div>
								</div>

							</div>

						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer">

									<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Datos del Ordenador</h5>
									</div>
									<div class="formulario conColumnas">

										<div class="columnaReparacion">
									      	<label class="form-label mt-4">Tipo de ordenador:</label>
									    	<br/>
									    	<span>{tipoDeOrdenador}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Contraseña del ordenador:</label>
									    	<br/>
									    	<span>{passwordDelOrdenador}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Marca del ordenador:</label>
									    	<br/>
									    	<span>{marcaDelOrdenador}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Modelo del ordenador:</label>
									    	<br/>
									    	<span>{modeloDelOrdenador}</span>
									    </div>
									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Cuenta con certificados digitales:</label>
									    	<br/>
									    	<span>{cuentaConCertificadosDigitales}</span>
									    </div>
									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">
							<div class="col-sm">

								<div class="formContainer">

									<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Datos de la reparación</h5>
									</div>
									<div class="formulario conColumnas">

										<div class="columnaReparacion">
									      	<label class="form-label mt-4">Descripción del problema:</label>
									    	<br/>
									    	<span>{descripcion}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Estado de la reparacion:</label>
									    	<br/>
									    	<span>{estado}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Tiempo estimado de la reparación:</label>
									    	<br/>
									    	<span>{tiempoEstimado} día(s)</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Archivo Adjunto:</label>
									    	<br/>
									    	<ArchivoAdjunto archivo={archivo} />
									    </div>
									</div>
								</div>

							</div>
						</div><br/>

					</div>

				</div>

			</div>

		</AnimatedPage>
	);
}

export default ReparacionClienteScreen;