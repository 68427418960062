import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootswatch/dist/litera/bootstrap.min.css';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import {datosUsuario} from '../backend/usuarios/usuarios';
import {validarUsuario} from '../global/validarUsuario';
import {Modal} from '../components/modals/modals';
import Swal from 'sweetalert2';

// Pantallas Normales
import LoadingScreen from './loading/loading';
import LoginScreen from './login/login';
import HomeScreen from './home/home';

// Pantallas Cliente
import LoginClienteScreen from './cliente/login/login';
import HomeClienteScreen from './cliente/home/home';
import ReparacionesClienteScreen from './cliente/reparaciones/reparaciones';
import ReparacionClienteScreen from './cliente/reparacion/reparacion';

// Pantallas Admin
import LoginAdminScreen from './admin/login/login';
import HomeAdminScreen from './admin/home/home';
import SuscripcionesAdminScreen from './admin/suscripciones/suscripciones';
import CuponesAdminScreen from './admin/cupones/cupones';

// Pantallas Empresa
import LoginEmpresasScreen from './empresas/login/login';
import HomeEmpresaScreen from './empresas/home/home';
import ActivarCuentaEmpresaScreen from './empresas/activarCuenta/activarCuenta';
import ClientesEmpresaScreen from './empresas/clientes/clientes';
import ReparacionesEmpresaScreen from './empresas/reparaciones/reparaciones';
import ReparacionEmpresaScreen from './empresas/reparacion/reparacion';
import EmpleadosEmpresasScreen from './empresas/empleados/empleados';
import EmpleadoEmpresaScreen from './empresas/empleado/empleado';
import SuscripcionesEmpresaScreen from './empresas/suscripciones/suscripciones';
import PedidosEmpresaScreen from './empresas/pedidos/pedidos';
import PedidoEmpresaScreen from './empresas/pedido/pedido';
import PerfilEmpresaScreen from './empresas/perfil/perfil';
import BlogEmpresaScreen from './empresas/blog/blog';
import FacturasEmpresaScreen from './empresas/facturas/facturas';
import PresupuestosEmpresaScreen from './empresas/presupuestos/presupuestos';
import CrearPresupuestoEmpresaScreen from './empresas/crearPresupuesto/crearPresupuesto';
import EditarPresupuestoEmpresaScreen from './empresas/editarPresupuesto/editarPresupuesto';

function App() {

  const [verificarUsuario, CambioUsuarioVerificado] = useState(false);
  const [modalVerificarEmail, CambioModalVerificarEmail] = useState(false);

  useEffect(() =>{

    if (verificarUsuario == false) {

      ponerUsuario();
      CambioUsuarioVerificado(true);
    }
  })

  const ponerUsuario = async function(){ 

    let elUsuario = await validarUsuario(); 

    if (elUsuario != false) {

      if (elUsuario.rol == 'empresa'){
        let losDatosUsuario = await datosUsuario(elUsuario.uid); 

        if (losDatosUsuario != false) {

          if (losDatosUsuario.data.verificacionEmail == false) {
            CambioModalVerificarEmail(true);
          }
        }
      }
    }
  }

  const terminarSesion = async function(){

    await localStorage.clear();
    window.location.href = '/empresa/login';
  }

  const pasarAEnviarEmail = async function(){

    Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
    });

    let elUsuario = await validarUsuario();

    if (elUsuario != false) {

      let envio = await fetch('https://satflow.es/reparacion-de-ordenadores-backend/enviarEmail/enviarEmail.php',{
          method: 'POST',
          headers:{
              "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
          body: "nombre_cliente="+elUsuario.nombre+
              "&email_cliente="+elUsuario.email+
              "&uid="+elUsuario.uid
      });

      Swal.fire({
          icon: 'success',
          title: 'Hemos enviado un email a '+elUsuario.email+'.',
      }); 
    }
  }

  return (
    <Router>
      <div>
        <div style={{display: 'none',}}>
          <Link to="/">
            Inicio
          </Link>
        </div>
        <Routes>
          <Route path="/" element={<LoadingScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/panel" element={<HomeScreen />} />
          <Route path="/admin/login" element={<LoginAdminScreen />} />
          <Route path="/admin/" element={<HomeAdminScreen />} />
          <Route path="/admin/suscripciones" element={<SuscripcionesAdminScreen />} />
          <Route path="/admin/cupones" element={<CuponesAdminScreen />} />
          <Route path="/empresa/login" element={<LoginEmpresasScreen />} />
          <Route path="/:urlEmpresa" element={<HomeEmpresaScreen />} />
          <Route path="/empresa/activa-cuenta/:uid" element={<ActivarCuentaEmpresaScreen />} />
          <Route path="/:urlEmpresa/clientes" element={<ClientesEmpresaScreen />} />
          <Route path="/:urlEmpresa/reparaciones" element={<ReparacionesEmpresaScreen />} />
          <Route path="/:urlEmpresa/reparacion/:id" element={<ReparacionEmpresaScreen />} />
          <Route path="/:urlEmpresa/empleados" element={<EmpleadosEmpresasScreen />} />
          <Route path="/:urlEmpresa/empleado/:id" element={<EmpleadoEmpresaScreen />} />
          <Route path="/:urlEmpresa/suscripciones/" element={<SuscripcionesEmpresaScreen />} />
          <Route path="/:urlEmpresa/pedidos/" element={<PedidosEmpresaScreen />} />
          <Route path="/:urlEmpresa/pedido/:id" element={<PedidoEmpresaScreen />} />
          <Route path="/:urlEmpresa/perfil" element={<PerfilEmpresaScreen />} />
          <Route path="/:urlEmpresa/blog" element={<BlogEmpresaScreen />} />
          <Route path="/:urlEmpresa/facturas" element={<FacturasEmpresaScreen />} />
          <Route path="/:urlEmpresa/presupuestos" element={<PresupuestosEmpresaScreen />} />
          <Route path="/:urlEmpresa/presupuesto/crear" element={<CrearPresupuestoEmpresaScreen />} />
          <Route path="/:urlEmpresa/presupuesto/editar/:id" element={<EditarPresupuestoEmpresaScreen />} />
          <Route path="/cliente/login/:urlEmpresa/:id" element={<LoginClienteScreen />} />
          <Route path="/cliente/panel/:urlEmpresa/:id" element={<HomeClienteScreen />} />
          <Route path="/cliente/reparaciones/:urlEmpresa/:id" element={<ReparacionesClienteScreen />} />
          <Route path="/cliente/reparacion/:urlEmpresa/:id/:idReparacion" element={<ReparacionClienteScreen />} />
        </Routes>
      </div>

      <Modal mostrar={modalVerificarEmail}>
          <h3 class="preguntaModal">
            No has activado tu cuenta, revisa tu correo.
          </h3>
          <p>Si no haz recibido ningun correo, Dale al boton "Enviar Correo"</p>
          <div class="botonesModal">
            <button onClick={pasarAEnviarEmail} class="btn btn-success">Enviar Correo</button>
            <button onClick={terminarSesion} class="btn btn-danger">No</button>
          </div>
      </Modal>

    </Router>
  );
}

export default App;
