import { useEffect, useState } from 'react';
import './cupones-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import {MenuAdmin, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {trash} from '../../../global/imgs.js';
import {Modal} from '../../../components/modals/modals';
import {consultarSuscripciones} from '../../../backend/suscripciones/suscripciones';
import {gestionarCupon, consultarCupones, eliminarCupon} from '../../../backend/cupones/cupones';

function CuponesAdminScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

	const [busquedaCupones, CambioBusquedaCupones] = useState(false);
	const [cupones, CambioCupones] = useState([]);

	const [busquedaSuscripcion, CambioBusquedaSuscripcion] = useState(false);
	const [suscripciones, CambioSuscripciones] = useState([]);

	const [modalCupon, CambioModalCupon] = useState(false);

	const [idCupon, CambioIdCupon] = useState(false);
	const [modalEliminarCupon, CambioModalEliminarCupon] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('cupones').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaCupones == false) {

			consultarCupones().then(response =>{

				CambioCupones(response);
			})

			CambioBusquedaCupones(true);
		}


		if (busquedaSuscripcion == false) {

			consultarSuscripciones().then(response =>{

				CambioSuscripciones(response);
			})

			CambioBusquedaSuscripcion(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'empresa' || elUsuario.rol == 'empleado') {

				window.location.href = '/'+elUsuario.urlEmpresa;
			}
		}else{

			window.location.href = '/admin/login';
		}
	}

	const procesarCupon = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var id = "";
      		for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

		let codigo = document.getElementById('codigo').value;
		let idSuscripcion = document.getElementById('idSuscripcion').value;
	
		if (codigo === '' || idSuscripcion === '') {

			Swal.fire({
			    icon: 'info',
			    title: 'No hay usuario autenticado.',
			});
		}else{

			let data = {
				codigo: codigo,
				idSuscripcion: idSuscripcion,
			};

			gestionarCupon(id, data).then(response =>{

				Swal.fire({
				    icon: 'success',
				    title: 'Cupon guardado.',
				});

				window.setTimeout(recargar, 1000);
			})
		}
	}

	const recargar = async function(){

		window.location.reload();
	}

	const procederEliminarCupon = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    eliminarCupon(idCupon).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Cupon eliminado.',
			});

			window.setTimeout(recargar, 1000);
	    });
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuAdmin/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

					<div class="row">
						<div class="col-sm">

							<div class="formContainer tablaContainer">
								<div class="headerForm headerTabla">
									<h5 class="tituloForm">Cupones</h5>
									<button onClick={() => CambioModalCupon(true)} class="btn btn-primary botonRepararTuPC widthContent">Crear Cupón</button>
								</div>
								<div class="formulario">
									<table class="table table-hover">
										<tr>
										    <th class="th" scope="row">Código</th>
										    <th class="th">Suscripción</th>
										    <th class="th">Acciones</th>
										</tr>
										{cupones.map((item) =>(
										    <tr class="bordeTr">
											    <td class="elTr" scope="row">{item.data.codigo}</td>
											    <td class="elTr">{item.suscripcion.data.nombre}</td><td class="elTr">
											      	<img onClick={() => {
												    	CambioModalEliminarCupon(true);
												    	CambioIdCupon(item.id);
												    }} src={trash} class="eye" />
											    </td>
											</tr>
										))}
									</table>
								</div>
							</div>

						</div>
					</div>

				</div>

			</div>

			<Modal mostrar={modalCupon}>
			    <p onClick={() => {
			    	CambioModalCupon(false);
			    }} class="equix">X</p>
			    <div class="formContainer formGestion">
					<div class="headerForm">
						<h5 class="tituloForm">Gestionar Cupon</h5>
					</div>
					<div class="formulario">
						<div class="form-group">
							<label for="codigo" class="form-label mt-4">Código*</label>
							<input type="text" class="form-control" id="codigo" placeholder="Código" />
						</div><br/>

						<div>
					      <label for="idSuscripcion" class="form-label mt-4">Suscripción</label>
					      <select class="form-select" id="idSuscripcion">
					        <option value="">Seleccione una suscripción</option>
					        {suscripciones.map((item) =>(
					        	<option value={item.id}>{item.data.nombre}</option>
					        ))}
					      </select>
					    </div><br/>

						<button onClick={procesarCupon} class="btn btn-primary botonRepararTuPC">Guardar</button>
					</div>
				</div>
			</Modal>

			<Modal mostrar={modalEliminarCupon}>
			    <h3 class="preguntaModal">
			    	¿Desea este eliminar cupón?
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={procederEliminarCupon} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEliminarCupon(false);
				    	CambioIdCupon(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>
		</AnimatedPage>
	);
}

export default CuponesAdminScreen;