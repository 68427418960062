import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc, referencia, almacenamiento, subirArchivo, obtenerURL} from '../conex.js';

export async function gestionarPresupuesto(id, datos){

	await set(documento(db, "presupuestos", id), datos);

	return true;
}

export async function consultarPresupuestos(urlEmpresa){

	let presupuestos = [];

	const q = buscar(coleccion(db, "presupuestos"), donde("urlEmpresa", "==", urlEmpresa));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		presupuestos.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return presupuestos;
}

export async function dataPresupuesto(id){

	const docRef = documento(db, "presupuestos", id);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}

export async function eliminarPresupuesto(id){

	await eliminarDoc(documento(db, "presupuestos", id));

	return true;
}

export async function presupuestoSegunReparacion(idReparacion){

	let presupuesto = [];

	const q = buscar(coleccion(db, "presupuestos"), donde("idReparacion", "==", idReparacion));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {

		presupuesto.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	if (presupuesto.length > 0 ) {

		return presupuesto[0];
	}else{

		return false;
	}
}