import React, { useEffect, useState } from 'react';
import './login-styles.css';
import {AnimatedPage} from '../../components/animacion/animacion';
import {Modal} from '../../components/modals/modals';
import {logoFinal1} from '../../global/imgs';
import {iniciarSesion, recuperarPassword, resgistrarUsuario} from '../../backend/auth/auth';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../global/validarUsuario';

function LoginScreen(){

	const [modalPassword, CambioModalPassword] = useState(false);
	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			window.location.href = '/';
		}
	}

	const irARegistrarEmpresa = async function(){

		await localStorage.setItem('registrarEmpresa', 'si');

		window.location.href = '/empresa/login';
	}

	const procesarLoginEmpresa = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('emailEmpresa').value;
	    let password = document.getElementById('passwordEmpresa').value;

	    if (email === '' || password === '') {

	      Swal.fire({
	        icon: 'info',
	        title: 'Complete todos los campos para continuar.',
	      });
	    }else{

	      iniciarSesion(email, password).then(response =>{

	        if (response != false) {

	          terminar(response, 'empresa');
	        }else{

	          Swal.fire({
	            icon: 'error',
	            title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
	          });
	        }
	      })
	    }
	}

	const procesarRecuperacion = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let emailRecuperar = document.getElementById('emailRecuperar').value;

	    if (emailRecuperar === '') {

	      Swal.fire({
	        title: 'Complete todos los campos para continuar.',
	        icon: 'info',
	      });
	    }else{
	      recuperarPassword(emailRecuperar).then(response =>{
	        if (response) {

	          Swal.fire({
	            title: 'Se ha enviado un correo electronico.',
	            icon: 'success',
	          });
	          CambioModalPassword(false);
	        }
	      })
	    }
	}

	const procesarLoginCliente = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let email = document.getElementById('emailCliente').value;
	    let password = document.getElementById('passwordCliente').value;

	    if (email === '' || password === '') {

	      Swal.fire({
	        icon: 'info',
	        title: 'Complete todos los campos para continuar.',
	      });
	    }else{

	      iniciarSesion(email, password).then(response =>{

	        if (response != false) {

	          terminar(response, 'cliente');
	        }else{

	          Swal.fire({
	            icon: 'error',
	            title: 'Lo sentimos pero no pudimos hubo un error al procesar sus datos.',
	          });
	        }
	      })
	    }
	}

	const terminar = async function(respuesta, desde){

		if (respuesta != false) {

			if (desde == 'empresa') {

				if (respuesta.rol == 'empresa' || respuesta.rol == 'empleado') {

					await localStorage.setItem('usuarioReparacionDeOrdenadores', JSON.stringify(respuesta));
					
					window.location.href = '/'+respuesta.urlEmpresa;
				}else{

					Swal.fire({
				        icon: 'error',
				        title: 'No tiene permisos para ingresar aqui.',
				    });
				}
			}else if (desde == 'cliente') {

				if (respuesta.rol == 'cliente') {

					await localStorage.setItem('usuarioReparacionDeOrdenadores', JSON.stringify(respuesta));
					
					window.location.href = '/';
				}else{

					Swal.fire({
				        icon: 'error',
				        title: 'No tiene permisos para ingresar aqui.',
				    });
				}
			}
		}else{

			Swal.fire({
		        icon: 'error',
		        title: 'Lo sentimos pero hubo un error al autenticar sus datos.',
		    });
		}
	}

	return(
		<AnimatedPage duracion={1}>
			<div class="fondoContainer">
					<div class="fondoHerramientas"></div>
					<div class="container containerLargoCompleto">

						

						<div class="row" style={{paddingTop: '5%',}}>
							<div class="col-sm">

								<div class="formContainer centrarTarjeta">
									<img src={logoFinal1} class="logoLogin" />
									<div class="headerForm">
										<h5 class="tituloForm">Ingresar al Panel de tu Empresa</h5>
										<span class="preguntaRegistro" style={{color: '#000', fontWeght: 'bold',}}>¿No tienes una cuenta? <span class="registrateAqui" onClick={irARegistrarEmpresa}>Registrate Aqui</span></span>
									</div>
									<div class="formulario">
										<div class="form-group">
											<label for="email" class="form-label mt-4 labelSatflow">Escriba su Email*</label>
									      	<input type="email" class="inputSatflow" id="emailEmpresa" />
									    </div>
									    <div class="form-group">
									    	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Contraseña*</label>
									      	<input type="password" class="inputSatflow" id="passwordEmpresa" />
									    	<br/><br/>
									    	<small onClick={() => CambioModalPassword(true)} id="emailHelp" class="form-text text-muted botonPreguntaPassword">¿Haz olvidado tu contraseña?</small>
									    </div><br/>

									    <button onClick={procesarLoginEmpresa} class="btn btn-primary botonRepararTuPC">Entrar</button>

									    <br/><br/>
									</div>
								</div>

							</div>

							<div class="col-sm">

								<div class="formContainer centrarTarjeta" style={{minHeight: 635,}}>
										<img src={logoFinal1} class="logoLogin" />
										<div class="headerForm">
											<h5 class="tituloForm">Ingresar su panel de cliente</h5>
										</div>
										<div class="formulario">
											<div class="form-group">
										      	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Email*</label>
										      	<input type="email" class="inputSatflow" id="emailCliente" />
										    </div>
										    <div class="form-group">
										      	<label for="email" class="form-label mt-4 labelSatflow">Escriba su Contraseña*</label>
										      	<input type="password" class="inputSatflow" id="passwordCliente" />
										    	<small onClick={() => CambioModalPassword(true)} id="emailHelp" class="form-text text-muted botonPreguntaPassword">¿Haz olvidado tu contraseña?</small>
										    </div><br/>

										    <button onClick={procesarLoginCliente} class="btn btn-primary botonRepararTuPC">Entrar</button>

										    <br/><br/>
										</div>
									</div>

							</div>
						</div>
					</div>
			</div>

			<Modal mostrar={modalPassword}>
				    <p onClick={() => CambioModalPassword(false)} class="equix">X</p>
				    <div class="formContainer centrarTarjeta">
						<div class="headerForm">
							<h5 class="tituloForm">Recuperar Contraseña</h5>
						</div>
						<div class="formulario">
							<div class="form-group">
								<label for="emailRecuperar" class="form-label mt-4">Email</label>
								<input type="email" class="inputSatflow" id="emailRecuperar" placeholder="Escriba su Email" />
							</div><br/>

							<button onClick={procesarRecuperacion} class="btn btn-primary botonRepararTuPC">Enviar</button>
						</div>
					</div>
				</Modal>
		</AnimatedPage>
	);
}

export default LoginScreen;