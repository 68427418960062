import { useEffect, useState } from 'react';
import './reparacion-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {datosReparacion, datosOrdenador} from '../../../backend/reparaciones/reparaciones';
import {datosUsuario} from '../../../backend/usuarios/usuarios';
import {InfoUsuario, ArchivoAdjunto} from '../../../components/reparaciones/reparaciones';
import {validarFactura, crearFactura} from '../../../backend/facturas/facturas';
import {fechaHoy} from '../../../global/datosCalendario.js';
import {presupuestoSegunReparacion} from '../../../backend/presupuestos/presupuestos';

function ReparacionEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;
  	const id = routeParams.id;

  	const [pantalla, CambioPantalla] = useState('loading');

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [ponerDatosDeLaReparacion, CambioPonerDatosDeLaReparacion] = useState(false);
	const [laReparacion, CambioLaReparacion] = useState(false);

	const [archivo, CambioArchivo] = useState(false);
	const [cliente, CambioCliente] = useState('');
	const [descripcion, CambioDescripcion] = useState('');
	const [empresa, CambiosEmpresa] = useState('');
	const [estado, CambioEstado] = useState('');
	const [elTecnico, CambioElTecnico] = useState('');
	const [tiempoEstimado, CambioTiempoEstimado] = useState('');
	const [numeroFolio, CambioNumeroFolio] = useState('');
	const [fechaReparacion, CambioFechaReparacion] = useState('');

	const [dataCliente, CambioDataCliente] = useState(false);
	const [dataTecnico, CambioDataTecnico] = useState(false); 

	const [tipoDeOrdenador, CambioTipoOrdenador] = useState('');
	const [passwordDelOrdenador, CambioPasswordDelOrdenador] = useState('');
	const [marcaDelOrdenador, CambioMarcaDelOrdenador] = useState('');
	const [modeloDelOrdenador, CambioModeloDelOrdenador] = useState('');
	const [descripcionDelProblema, CambioDescripcionDelProblema] = useState('');
	const [cuentaConCertificadosDigitales, CambioCuentaConCertificadosDigitales] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			//document.getElementById('home').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (ponerDatosDeLaReparacion == false) {

			Swal.fire({
		      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          	'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          	'</div>',
		      	showConfirmButton: false,
		      	allowOutsideClick: false,
		      	allowEnterKey: false,
		      	allowEscapeKey: false,
		    });

			verReparacion();
			CambioPonerDatosDeLaReparacion(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const verReparacion = async function(){

		let reparacion = await datosReparacion(id);
		let elUsuario = await validarUsuario();

		if (reparacion != false) {

			let usuario = await datosUsuario(reparacion.data.idCliente);
			let tecnico = await datosUsuario(reparacion.data.uid);
			let ordenador = await datosOrdenador(reparacion.data.idCliente);

			if (usuario != false && tecnico != false && ordenador != false) {

				if (reparacion.data.archivo != '') {

					CambioArchivo(reparacion.data.archivo);
				}

				CambioCliente(reparacion.data.cliente);
				CambioDescripcion(reparacion.data.descripcion);
				CambiosEmpresa(reparacion.data.empresa);
				CambioEstado(reparacion.data.estadoReparacion);
				CambioElTecnico(reparacion.data.tecnico);
				CambioTiempoEstimado(reparacion.data.tiempoEstimado);
				CambioNumeroFolio(reparacion.data.numeroFolio);
				CambioFechaReparacion(reparacion.data.fechaCreacion);

				CambioDataCliente(usuario);
				CambioDataTecnico(tecnico); console.log(usuario);

				CambioTipoOrdenador(ordenador.data.tipoDeOrdenador);
				CambioPasswordDelOrdenador(ordenador.data.passwordDelOrdenador);
				CambioMarcaDelOrdenador(ordenador.data.marcaDelOrdenador);
				CambioModeloDelOrdenador(ordenador.data.modeloDelOrdenador);
				CambioDescripcionDelProblema(ordenador.data.descripcionDelProblema);

				if (ordenador.data.cuentaConCertificadosDigitales == true) {

					CambioCuentaConCertificadosDigitales('Si');
				}else{

					CambioCuentaConCertificadosDigitales('No');
				}

				CambioPantalla('');
				Swal.close();
			}else{

				if (elUsuario != false) {

					window.location.href = elUsuario.urlEmpresa+'/reparaciones';
				}else{

					terminarSesion();
				}
			}
		}else{

			if (elUsuario != false) {

				window.location.href = elUsuario.urlEmpresa+'/reparaciones';
			}else{

				terminarSesion();
			}
		}
	}

	const verFichaReparacion = async function(item){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

    	// http://localhost/reparacion-de-ordenadores-backend/factura/factura.php

    	window.open(
    		'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/ficha.php?'+
    		'numeroFolio='+numeroFolio+
    		'&nombreEmpresa='+dataTecnico.data.nombre+
    		'&DNIoNIFEmpresa='+dataTecnico.data.DNIoNIF+
    		'&direccionEmpresa='+dataTecnico.data.direccion+
    		'&emailEmpresa='+dataTecnico.data.email+
    		'&fecha='+fechaReparacion+
    		'&presupuesto=0.00€'+
    		'&presupuestoFinal=0.00€'+
    		'&idCliente='+dataCliente.id+
    		'&nombreCliente='+dataCliente.data.nombre+
    		'&direccionCliente='+dataCliente.data.direccion+
    		'&telefonoCliente='+dataCliente.data.telefono+
    		'&emailCliente='+dataCliente.data.email+
    		'&articulo='+tipoDeOrdenador+
    		'&marca='+marcaDelOrdenador+
    		'&modelo='+modeloDelOrdenador+
    		'&serie=nada'+
    		'&evaluacion='+descripcionDelProblema+
    		'&resolucion=nada',
    		'_blank'
    	);

    	Swal.close();
  	}

  	const verTicketReparacion = async function(item){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

    	// http://localhost/reparacion-de-ordenadores-backend/factura/factura.php

    	window.open(
    		'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/ticket.php?'+
    		'numeroFolio='+numeroFolio+
    		'&nombreEmpresa='+dataTecnico.data.nombre+
    		'&DNIoNIFEmpresa='+dataTecnico.data.DNIoNIF+
    		'&direccionEmpresa='+dataTecnico.data.direccion+
    		'&emailEmpresa='+dataTecnico.data.email+
    		'&fecha='+fechaReparacion+
    		'&presupuesto=0.00€'+
    		'&presupuestoFinal=0.00€'+
    		'&idCliente='+dataCliente.id+
    		'&nombreCliente='+dataCliente.data.nombre+
    		'&direccionCliente='+dataCliente.data.direccion+
    		'&telefonoCliente='+dataCliente.data.telefono+
    		'&emailCliente='+dataCliente.data.email+
    		'&articulo='+tipoDeOrdenador+
    		'&marca='+marcaDelOrdenador+
    		'&modelo='+modeloDelOrdenador+
    		'&serie=nada'+
    		'&evaluacion='+descripcionDelProblema+
    		'&resolucion=nada',
    		'_blank'
    	);

    	Swal.close();
  	}

  	const enviarEmailReparacion = async function(){

  		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

  		await fetch('https://app.satflow.es/reparacion-de-ordenadores-backend/enviarEmail/estadoReparacion.php',{
			method: 'POST',
			headers:{
			    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			body: "nombre_cliente="+cliente+
			    "&email_cliente="+dataCliente.data.email+
			    "&descripcion="+descripcion+
			    "&estado="+estado+
			    "&tiempoEstimado="+tiempoEstimado
		});

		Swal.fire({
			icon: 'success',
			title: 'Email enviado a '+dataCliente.data.email,
		});
  	}

  	const verFactura = async function(){

  		Swal.fire({
		    html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		        '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		    '</div>',
		    showConfirmButton: false,
		    allowOutsideClick: false,
		    allowEnterKey: false,
		    allowEscapeKey: false,
		});

		let validarSiFacturaExiste = await validarFactura(id);

		let empresa = '';
		let total = 0;
		let subTotal = 0;

		if (dataTecnico.data.rol == 'empleado') {

			let dataEmpresa = await datosUsuario(dataTecnico.data.idEmpresa);

			if (dataEmpresa != false) {

				empresa = dataEmpresa.data.nombre;
			}
		}else{

			empresa = dataTecnico.data.nombre;
		}

		let presupuesto = await presupuestoSegunReparacion(id);

		if (presupuesto != false) {

			let costoFinal = await calcularTotalInicial(presupuesto.data.costos);

			total = costoFinal.total;
			subTotal = costoFinal.subTotal;
		}

		if (validarSiFacturaExiste != false) {

				window.open(
		    		'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/factura.php?'+
		    		'numeroFactura='+validarSiFacturaExiste.data.numeroFactura+
		    		'&fechaMostrar='+validarSiFacturaExiste.data.fecha+
		    		'&nombre='+dataCliente.data.nombre+
					'&email='+dataCliente.data.email+
					'&direccion='+dataCliente.data.direccion+
					'&telefono='+dataCliente.data.telefono+
					'&identificacion='+dataCliente.data.DNIoNIF+
					'&nombreProducto='+marcaDelOrdenador+
					'&costoProducto='+subTotal+'€'+
					'&total='+total+'€'+
					'&formaPago=nada'+
		    		'&empresa='+empresa+
					'&nombreTecnico='+dataTecnico.data.nombre+
					'&DNITecnico='+dataTecnico.data.DNIoNIF+
					'&direccionTecnico='+dataTecnico.data.direccion+
					'&emailTecnico='+dataTecnico.data.email,
		    		'_blank'
		    	);
		}else{

			let fecha = await fechaHoy();

			let date = new Date();

			let ano = date.getFullYear();

			let datos = {
				idReparacion: id,
				fecha: fecha,
				ano: ano,
				urlEmpresa: urlEmpresa,
			};

			crearFactura(datos).then(response =>{

				window.open(
		    		'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/factura.php?'+
		    		'numeroFactura='+response.numeroFactura+
		    		'&fechaMostrar='+response.fecha+
		    		'&nombre='+dataCliente.data.nombre+
					'&email='+dataCliente.data.email+
					'&direccion='+dataCliente.data.direccion+
					'&telefono='+dataCliente.data.telefono+
					'&identificacion='+dataCliente.data.DNIoNIF+
					'&nombreProducto='+marcaDelOrdenador+
					'&costoProducto='+subTotal+'€'+
					'&total='+total+'€'+
					'&formaPago=nada'+
		    		'&empresa='+empresa+
					'&nombreTecnico='+dataTecnico.data.nombre+
					'&DNITecnico='+dataTecnico.data.DNIoNIF+
					'&direccionTecnico='+dataTecnico.data.direccion+
					'&emailTecnico='+dataTecnico.data.email,
		    		'_blank'
		    	);
			})
		}

		Swal.close();
  	}

  	const calcularTotalInicial = async function(losCostos){

		let elTotal = 0;
		let elSubTotal = 0;

		for (let elCosto of losCostos) {
			
			let suma = elSubTotal + parseFloat(elCosto.costo);

			elSubTotal = suma;
		}

		let iva = elSubTotal * 0.21;

		elTotal = elSubTotal + iva;

		
		return {
			total: elTotal,
			subTotal: elSubTotal,
		};
	}

	const enviaWhatsapp = async function(){

		let textoWhatsapp = 'Hola estimado cliente '+dataCliente.data.nombre+' ya la reparación de su equipo fue agregada a nuestro sistema, el técnico agrego los siguientes detalles:\n -Descripción del problema:\n '+descripcion+'\n -Estado de la reparación:\n '+estado+'\n -Tiempo estimado:\n '+tiempoEstimado+' dia(s).';
	
		let url = "https://api.whatsapp.com/send?phone="+dataCliente.data.telefono+"&text="+textoWhatsapp;
	
		window.open(url, '_blank');
	}

	if (pantalla == 'loading') {

		return(
			<></>
		);
	}else{

		return(
			<AnimatedPage duracion={1}>

				<div class="centralPanel">
					
					<MenuEmpresa
						urlEmpresa={urlEmpresa}
					/>

					<div class="contenidoPanel">

						<BarraDeTareas/>

						<div class="container">

							<div class="row">
								<div class="col-sm"><br/>

									<div class="formContainer">

										<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
											<h5 class="tituloForm">{tipoDeOrdenador}</h5>
										</div><br/>
										<div class="formulario">
											
											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Cliente</span>
											  <input type="text" class="form-control" readonly value={dataCliente.data.nombre} />
											</div><br/>

											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">ID</span>
											  <input type="text" class="form-control" readonly value={dataCliente.id} />
											</div><br/>

											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Dirección</span>
											  <input type="text" class="form-control" readonly value={dataCliente.data.direccion} />
											</div><br/><hr/>

											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Dirección de recogida</span>
											  <input type="text" class="form-control" readonly value="Domicilio del cliente" />
											</div><br/><hr/>

											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Modelo</span>
											  <input type="text" class="form-control" readonly value={modeloDelOrdenador} />
											</div><br/>

											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Marca</span>
											  <input type="text" class="form-control" readonly value={marcaDelOrdenador} />
											</div><br/>

											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Cuenta con certificados</span>
											  <input type="text" class="form-control" readonly value={cuentaConCertificadosDigitales} />
											</div><br/>

											<div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Tiempo estimado</span>
											  <input type="text" class="form-control" readonly value={tiempoEstimado} />
											</div>

											<div class="columnaReparacion">
										      	<label class="form-label mt-4">Archivo Adjunto:</label>
										    	<br/>
										    	<ArchivoAdjunto archivo={archivo} />
										    </div>

										    <div class="input-group flex-nowrap">
											  <span class="input-group-text" id="addon-wrapping">Descripción del problema</span>
											  <input type="text" class="form-control" readonly value={descripcionDelProblema} />
											</div><br/>

										</div>
									</div>

								</div>
							</div><br/>

							<div class="row">
								<div class="col-sm">

									<button onClick={verFichaReparacion} class="btn btn-primary marginRight">Imprimir fecha</button>
									<button onClick={verTicketReparacion} class="btn btn-secondary marginRight">Imprimir ticket de la ficha</button>
									<button onClick={enviarEmailReparacion} class="btn btn-success marginRight">Enviar por correo</button>
									<button onClick={verFactura} class="btn btn-info marginRight">Generar factura</button>
									<button onClick={enviaWhatsapp} class="btn btn-warning marginRight">Enviar mensaje por whatsapp</button>

								</div>
							</div><br/>
						</div>

					</div>

				</div>

			</AnimatedPage>
		);
	}
}

export default ReparacionEmpresaScreen;