import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc} from '../conex.js';
import {datosSuscripcion} from '../suscripciones/suscripciones';

export async function gestionarCupon(id, data){

	await set(documento(db, "cupones", id), data);
	
	return true;
} 

export async function consultarCupones(){

	let cupones = [];
	let cuponesFinales = [];

	const q = buscar(coleccion(db, "cupones"), donde("idSuscripcion", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		cupones.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	for (let cupon of cupones) {
		
		let suscripcion = await datosSuscripcion(cupon.data.idSuscripcion);

		if (suscripcion != false) {

			cuponesFinales.push({
				id: cupon.id,
				data: cupon.data,
				suscripcion: suscripcion,
			})
		}
	}

	return cuponesFinales;
}

export async function eliminarCupon(id){

	await eliminarDoc(documento(db, "cupones", id));

	return true;
}

export async function validarSiYaUsoCupon(uid, codigo){

	let usados = [];
	let esta = false;

	const q = buscar(coleccion(db, "cuponesUsados"), donde("uid", "==", uid));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		usados.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	for (let usado of usados) {
		
		for (let usado of usados) {
		
			if (codigo == usado.data.codigo) {

				esta = true;
			}
		}
	}

	return esta;
}

export async function consultarCupon(codigo){

	let cupones = [];

	const q = buscar(coleccion(db, "cupones"), donde("codigo", "==", codigo));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		cupones.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	if (cupones.length > 0) {

		return cupones[0];
	}else{

		return false;
	}
}

export async function registrarUsoCupon(datos){

	var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var id = "";
      		for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

    await set(documento(db, "cuponesUsados", id), datos);
	
	return true;
}