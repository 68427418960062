import { useEffect, useState } from 'react';
import './tablas-styles.css';
import {eye, eyeCerrado} from '../../global/imgs.js';

export function ElOjo({estado, accion}){

	if (estado == true) {

		return(
			<>
				<img onClick={accion} src={eye} class="eye" />
			</>
		);
	}else{

		return(
			<>
				<img onClick={accion} src={eyeCerrado} class="eye" />
			</>
		);
	}
}

export function Estado({activo}){

	if (activo == true) {

		return(
			<span class="badge bg-success">Activo</span>
		);
	}else{

		return(
			<span class="badge bg-danger">Inactivo</span>
		);
	}
}