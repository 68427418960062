import { useEffect, useState } from 'react';

export function CaracSucrip({
	caracteristica,
	texto,
}){

	if (caracteristica != false || caracteristica != '') {

		return(
			<>
				<span class="caracContainer">
					<div class="checkCarac">✓</div>
					<span class="textoCarac">{texto}</span>
				</span>
			</>
		);
	}else{

		return(
			<></>
		);
	}
}

export function DuracionSuscrip({item}){

	if (item.data.duracion.tipo == 'anual') {

		return(
			<span class="infoPrecio">Al año</span>
		);
	}else if (item.data.duracion.tipo == 'mensual') {

		return(
			<span class="infoPrecio">Al mes</span>
		);
	}else if (item.data.duracion.tipo == 'dias') {

		return(
			<span class="infoPrecio">Por {item.data.duracion.dias} días</span>
		);
	}else{

		return(
			<></>
		);
	}
}