import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs} from '../conex.js';
import {fechaHoy} from '../../global/datosCalendario.js';

export async function resgistrarUsuario(datos){

	let pasarRegistro = true;

	if (datos.rol == 'empresa') {

		pasarRegistro = await validarSiEmpresaExite(datos.urlEmpresa);
	}

	if (pasarRegistro == true) {

		let registro = await crearUsuario(auth, datos.email, datos.password).then((userCredential) => {
		    // Signed in
		    const user = userCredential.user;
		    // ...
		    return user;
		})
		.catch((error) => {
		    const errorCode = error.code;
		    const errorMessage = error.message;
		    // ..
		    return 'ya existe';
		});

		if (registro != 'ya existe') {

			let datosFinales = '';
			let fechaCreacion = await fechaHoy();

			if (datos.rol == 'empresa') {

				datosFinales = {
					activo: true,
					verificacionEmail: false,
					fechaCreacion: fechaCreacion,
					email: datos.email,
					nombre: datos.nombre,
					telefono: datos.telefono,
					DNIoNIF: datos.DNIoNIF,
					direccion: datos.direccion,
					rol: datos.rol,
					suscripcion: datos.suscripcion,
					foto: 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fdescarga.jpg?alt=media&token=2897bb03-e19b-4671-9364-b7f8e4276c88',
					uid: registro.uid,
					urlEmpresa: datos.urlEmpresa,
				};
			}else if (datos.rol == 'cliente') {

				datosFinales = {
					activo: true,
					fechaCreacion: fechaCreacion,
					email: datos.email,
					nombre: datos.nombre,
					telefono: datos.telefono,
					DNIoNIF: datos.DNIoNIF,
					direccion: datos.direccion,
					rol: datos.rol,
					foto: 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fdescarga.jpg?alt=media&token=2897bb03-e19b-4671-9364-b7f8e4276c88',
					uid: registro.uid,
					idEmpresa: datos.idEmpresa,
					urlEmpresa: datos.urlEmpresa,
				};
			}else if (datos.rol == 'empleado') {

				datosFinales = {
					activo: datos.activo,
					fechaCreacion: fechaCreacion,
					email: datos.email,
					nombre: datos.nombre,
					telefono: datos.telefono,
					DNIoNIF: datos.DNIoNIF,
					direccion: datos.direccion,
					rol: datos.rol,
					rolEmpleado: datos.rolEmpleado,
					foto: 'https://firebasestorage.googleapis.com/v0/b/long-arcadia-415003.appspot.com/o/assets%2Fcore%2Fusuarios%2Fdescarga.jpg?alt=media&token=2897bb03-e19b-4671-9364-b7f8e4276c88',
					uid: registro.uid,
					idEmpresa: datos.idEmpresa,
					urlEmpresa: datos.urlEmpresa,
				};
			}

			await set(documento(db, "usuarios", registro.uid), datosFinales);
		
			if (datos.rol == 'empresa') {

				let envio = await fetch('https://app.satflow.es/reparacion-de-ordenadores-backend/enviarEmail/enviarEmail.php',{
		            method: 'POST',
		            headers:{
		                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
		            },
		            body: "nombre_cliente="+datos.nombre+
		                "&email_cliente="+datos.email+
		                "&uid="+registro.uid
		        });	
			}	

			return datosFinales;
		}else{
			return 'ya existe';
		}
	}else{

		return false;
	}
}

export async function iniciarSesion(email, password){
	let usuario = await sesionUsuario(auth, email, password).then((userCredential) => {
	    // Signed in
	    const user = userCredential.user;
	    // ...
	    return user;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    return false;
	});
	if (usuario != false) {
		const docRef = documento(db, "usuarios", usuario.uid);
		const docSnap = await leerDatos(docRef);
		if (docSnap.exists()) {
			return docSnap.data();
		}else{
			return false;
		}
	}else{
		return false;
	}
}

export async function recuperarPassword(email){
	let actualizando = await cambiarPassword(auth, email).then(() => {
	    // Password reset email sent!
	    // ..
	    return true;
	})
	.catch((error) => {
	    const errorCode = error.code;
	    const errorMessage = error.message;
	    // ..
	    return false;
	});

	return actualizando;
}

export async function guardarNuevaPassword(actualPassword, nuevaPassword){
	let usuario = await localStorage.getItem('usuarioRevirefer');
	if (usuario != null) {
		let dataUser = JSON.parse(usuario);
		let confirmarSesion = await iniciarSesion(dataUser.email, actualPassword);

		if (confirmarSesion != false) {
			const user = auth.currentUser;
			let actualizacion = await actualizarPassword(user, nuevaPassword).then(() => {
			  return true;
			}).catch((error) => {
			  return false;
			});
			return actualizacion;
		}else{
			return false;
		}
	}else{
		return false;
	}
}

export async function guardarNuevoEmail(actualEmail, nuevoEmail, passwordActual){
	let confirmarSesion = await iniciarSesion(actualEmail, passwordActual);

	if (confirmarSesion != false) {
		let actualizando = await actualizarEmail(auth.currentUser, nuevoEmail).then(() => {
		  return true;
		}).catch((error) => {
		  return false;
		});

		if (actualizando) {
			const ref = documento(db, "usuarios", confirmarSesion.uid);

			await actualizarDoc(ref, {
			  email: nuevoEmail
			});

			const docRef = documento(db, "usuarios", confirmarSesion.uid);
			const docSnap = await leerDatos(docRef);
			if (docSnap.exists()) {
				return docSnap.data();
			}else{
				return false;
			}

		}else{
			return false;
		}
	}else{
		return false;
	}
}

export async function validarSiEmpresaExite(urlEmpresa){

	let usuarios = [];

	const q = buscar(coleccion(db, "usuarios"), donde("urlEmpresa", "==", urlEmpresa));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		usuarios.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	if (usuarios.length > 0) {

		return false;
	}else{

		return true;
	}
}