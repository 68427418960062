import {auth, crearUsuario, db, documento, set, sesionUsuario, leerDatos, cambiarPassword, actualizarPassword, actualizarEmail, actualizarDoc, buscar, coleccion, donde, obtenerDocs, eliminarDoc} from '../conex.js';

export async function gestionarSuscripcion(id, datos){

	await set(documento(db, "suscripciones", id), datos);
	
	return true;
}

export async function consultarSuscripciones(){

	let suscripciones = [];

	const q = buscar(coleccion(db, "suscripciones"), donde("nombre", "!=", ""));

	const querySnapshot = await obtenerDocs(q);
	querySnapshot.forEach((doc) => {
		suscripciones.push({
			id: doc.id,
			data: doc.data(),
		});
	});

	return suscripciones;
}

export async function activaODesactivarSuscripcion(id, estado){

	let elEstado = true;

	if (estado == true) {
		elEstado = false;
	}

	const washingtonRef = documento(db, "suscripciones", id);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  activo: elEstado,
	});

	return true;
} 

export async function eliminarSuscripcion(id){

	await eliminarDoc(documento(db, "suscripciones", id));

	return true;
}

export async function tokenPaypal(){

	let token = await fetch('https://app.satflow.es/reparacion-de-ordenadores-backend/pagos/paypal.php',{
            method: 'POST',
            headers:{
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "accion=verToken&todo=nuevo&ahora=123"
    }).then(response =>{
        return response.json();
    }).then(json =>{
        console.log(json);
       	return json;
    });

    return token;
}

export async function contratarSuscripcion(data){

	console.log(data);

	var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    var id = "";
      	for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

	await set(documento(db, "pedidos", id), data);

	const washingtonRef = documento(db, "usuarios", data.uid);

	// Set the "capital" field of the city 'DC'
	await actualizarDoc(washingtonRef, {
	  suscripcion: data.datosCompra.idProducto,
	});

	return true;
}

export async function datosSuscripcion(id){

	const docRef = documento(db, "suscripciones", id);
	const docSnap = await leerDatos(docRef);
	if (docSnap.exists()) {
		return {
			id: docSnap.id,
			data: docSnap.data(),
		};
	}else{
		return false;
	}
}