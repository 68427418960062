import { useEffect, useState } from 'react';
import './suscripciones-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import {MenuAdmin, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {Modal} from '../../../components/modals/modals';
import {fechaHoy} from '../../../global/datosCalendario.js';
import {gestionarSuscripcion, consultarSuscripciones, activaODesactivarSuscripcion, eliminarSuscripcion} from '../../../backend/suscripciones/suscripciones';
import {ElOjo} from '../../../components/tablas/tablas';
import {edit, trash} from '../../../global/imgs.js';

function SuscripcionesAdminScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [modalSuscripcion, CambioModalSuscripcion] = useState(false);
	const [idSuscripcion, CambioIdSuscripcion] = useState(false);
	const [busquedaSuscripciones, CambioBusquedaSuscripciones] = useState(false);
	const [suscripciones, CambioSuscripciones] = useState([]);
	const [modalEstadoSuscripcion, CambioModalEstadoSuscripcion] = useState(false);
	const [preguntaModal, CambioPreguntaModal] = useState('');
	const [estadoSuscripcion, CambioEstadoSuscripcion] = useState(false);
	const [modalEliminarSuscripcion, CambioModalEliminarSuscripcion] = useState(false);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('suscripciones').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaSuscripciones == false) {

			consultarSuscripciones().then(response =>{

				CambioSuscripciones(response);
			});

			CambioBusquedaSuscripciones(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'empresa' || elUsuario.rol == 'empleado') {

				window.location.href = '/'+elUsuario.urlEmpresa;
			}
		}else{

			window.location.href = '/admin/login';
		}
	}

	const procesarSuscripcion = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    var characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
    	var id = "";
      		for (var i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

	    let nombre = document.getElementById('nombreSuscripcion').value;
	    let descripcion = document.getElementById('descripcionSuscripcion').value;
	    let precio = document.getElementById('precioSuscripcion').value;
		let duracion = false;
		let cantidadClientes = document.getElementById('cantidadClientes').value;
		let cantidadRepararciones = document.getElementById('cantidadRepararciones').value;
		let tipoPerfil = false;
		let funcionesForo = false;
		let cantidadMensajes = document.getElementById('cantidadMensajes').value;
		let notificaciones = false;
		let tabloAnuncios = false;
		let bibliotecaRecursos = false;
		let seminarios = false;
		let soporte = false;
		let analisisReportes = false;

		//Establecer Duracion
		if (document.getElementById('anual').checked == true) {

			duracion = {
				tipo: 'anual',
				dias: '365',
			};
		}else if (document.getElementById('mensual').checked == true) {

			duracion = {
				tipo: 'mensual',
				dias: '28',
			};
		}else if (document.getElementById('dias').checked == true) {

			if (document.getElementById('cantidadDias').value === '' || parseInt(document.getElementById('cantidadDias').value) <= 0) {

				Swal.fire({
			        icon: 'info',
			        title: 'Indique la duración en días de las suscripcion correctamente.',
			    });

			    return false;
			}else{

				duracion = {
					tipo: 'dias',
					dias: document.getElementById('cantidadDias').value,
				};
			}
		}

		//Establecer tipo de perfil
		if (document.getElementById('basico').checked == true) {

			tipoPerfil = 'basico';
		}else if (document.getElementById('avanzado').checked == true) {

			tipoPerfil = 'avanzado';
		}else if (document.getElementById('premium').checked == true) {

			tipoPerfil = 'premium';
		}

		//Establecer funciones del foro
		if (document.getElementById('soloLectura').checked == true) {

			funcionesForo = 'soloLectura';
		}else if (document.getElementById('creacionYRespuesta').checked == true) {

			funcionesForo = 'creacionYRespuesta';
		}else if (document.getElementById('accesoTotal').checked == true) {

			funcionesForo = 'accesoTotal';
		}

		//Establecer notificaciones
		if (document.getElementById('basicasNotis').checked == true) {

			notificaciones = 'basicasNotis';
		}else if (document.getElementById('avanzadasNotis').checked == true) {

			notificaciones = 'avanzadasNotis';
		}

		//Establecer tablon de anuncios
		if (document.getElementById('tabloAnuncios').checked == true) {

			tabloAnuncios = true;
		}

		//Establecer Biblioteca de recursos
		if (document.getElementById('bibliotecaRecursos').checked == true) {

			bibliotecaRecursos = true;
		}

		//Establecer Seminarios
		if (document.getElementById('seminarios').checked == true) {

			seminarios = true;
		}

		//Establecer soporte
		if (document.getElementById('soporte').checked == true) {

			soporte = true;
		}

		//Estaclecer analisis y reportes
		if (document.getElementById('analisisReportes').checked == true) {

			analisisReportes = true;
		}

		let fechaCreacion = await fechaHoy();
		let elUsuario = await validarUsuario();

		if (nombre === '' || descripcion === '' || precio === '' || duracion == false) {

			Swal.fire({
			    icon: 'info',
			    title: 'Complete los campos que tienen un "*" para continuar.',
			});
		}else if (elUsuario.uid == undefined) {

			Swal.fire({
			    icon: 'info',
			    title: 'No hay usuario autenticado.',
			});
		}else{

			let datos = {
				activo: true,
				fechaCreacion: fechaCreacion,
				uid: elUsuario.uid,
				nombre: nombre,
				descripcion: descripcion,
				precio: precio,
				duracion: duracion,
				cantidadClientes: cantidadClientes,
				cantidadRepararciones: cantidadRepararciones,
				tipoPerfil: tipoPerfil,
				funcionesForo: funcionesForo,
				cantidadMensajes: cantidadMensajes,
				notificaciones: notificaciones,
				tabloAnuncios: tabloAnuncios,
				bibliotecaRecursos: bibliotecaRecursos,
				seminarios: seminarios,
				soporte: soporte,
				analisisReportes: analisisReportes,
			};

			let elId = '';

			if (idSuscripcion != false) {

				elId = idSuscripcion;
			}else{

				elId = id;
			}

			gestionarSuscripcion(elId, datos).then(response =>{

				Swal.fire({
				    icon: 'success',
				    title: 'Suscripción guardada.',
				});
				CambioModalSuscripcion(false);
				CambioIdSuscripcion(false);
				CambioBusquedaSuscripciones(false);
			})
		}
	}

	const modificarEstadoSuscripcion = async function(item){

		if (item.data.activo == true) {

			CambioPreguntaModal('¿Desea desactivar esta suscripción?');
		}else if (item.data.activo == false) {

			CambioPreguntaModal('¿Desea activar esta suscripción?');
		}

		CambioIdSuscripcion(item.id);
		CambioEstadoSuscripcion(item.data.activo);
		CambioModalEstadoSuscripcion(true);
	}

	const alterarEstadoSuscripcion = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    activaODesactivarSuscripcion(idSuscripcion, estadoSuscripcion).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Suscripción actualizada.',
			});

			CambioModalEstadoSuscripcion(false);
			CambioIdSuscripcion(false);
			CambioBusquedaSuscripciones(false);
	    })
	}

	const editarSuscripcion = async function(item){

		CambioModalSuscripcion(true);
		CambioIdSuscripcion(item.id);

		window.setTimeout(ponerDatosSuscripcion, 1000, item);
	}

	const ponerDatosSuscripcion = async function(item){

		document.getElementById('nombreSuscripcion').value = item.data.nombre;
		document.getElementById('descripcionSuscripcion').value = item.data.descripcion;
		document.getElementById('precioSuscripcion').value = item.data.precio;
	
		//Establecer Duracion
		if (item.data.duracion.tipo == 'anual') {

			document.getElementById('anual').checked = true;
		}else if (item.data.duracion.tipo == 'mensual') {

			document.getElementById('mensual').checked = true;
		}else if (item.data.duracion.tipo == 'dias') {

			document.getElementById('dias').checked = true;
			document.getElementById('cantidadDias').value = item.data.duracion.dias;
		}

		//Establecer tipo de perfil
		if (item.data.tipoPerfil == 'basico') {

			document.getElementById('basico').checked = true;
		}else if (item.data.tipoPerfil == 'avanzado') {

			document.getElementById('avanzado').checked = true;
		}else if (item.data.tipoPerfil == 'premium') {

			document.getElementById('premium').checked = true;
		}

		//Establecer funciones del foro
		if (item.data.funcionesForo == 'soloLectura') {

			document.getElementById('soloLectura').checked = true;
		}else if (item.data.funcionesForo == 'creacionYRespuesta') {

			document.getElementById('creacionYRespuesta').checked = true;
		}else if (item.data.funcionesForo == 'accesoTotal') {

			document.getElementById('accesoTotal').checked = true;
		}

		//Establecer notificaciones
		if (item.data.notificaciones = 'basicasNotis') {

			document.getElementById('basicasNotis').checked = true;
		}else if (item.data.notificaciones = 'avanzadasNotis') {

			document.getElementById('avanzadasNotis').checked = true;
		}

		//Establecer tablon de anuncios
		if (item.data.tabloAnuncios == true) {

			document.getElementById('tabloAnuncios').checked = true;
		}

		//Establecer Biblioteca de recursos
		if (item.data.bibliotecaRecursos == true) {

			document.getElementById('bibliotecaRecursos').checked = true;
		}

		//Establecer Seminarios
		if (item.data.seminarios == true) {

			document.getElementById('seminarios').checked = true;
		}

		//Establecer soporte
		if (item.data.soporte == true) {

			document.getElementById('soporte').checked = true;
		}

		//Estaclecer analisis y reportes
		if (item.data.analisisReportes == true) {

			document.getElementById('analisisReportes').checked = true;
		}
	}

	const preguntarEliminarSuscripcion = async function(item){

		CambioPreguntaModal('¿Desea eliminar esta suscripción?');
		CambioIdSuscripcion(item.id);
		CambioModalEliminarSuscripcion(true);
	}

	const procederEliminarSuscripcion = async function(){

		Swal.fire({
	      	html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          		'<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          	  '</div>',
	      	showConfirmButton: false,
	      	allowOutsideClick: false,
	      	allowEnterKey: false,
	      	allowEscapeKey: false,
	    });

	    eliminarSuscripcion(idSuscripcion).then(response =>{

	    	Swal.fire({
				icon: 'success',
				title: 'Suscripción eliminado.',
			});

			CambioModalEliminarSuscripcion(false);
			CambioIdSuscripcion(false);
			CambioBusquedaSuscripciones(false);
	    })
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuAdmin/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					

						<div class="row">
							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla">
										<h5 class="tituloForm">Suscripciones</h5>
										<button onClick={() => CambioModalSuscripcion(true)} class="btn btn-primary botonRepararTuPC widthContent">Crear Suscripción</button>
									</div>
									<div class="formulario">
										<table class="table table-hover">
											<tr>
										      	<th class="th" scope="row">Nombre</th>
										      	<th class="th">Precio</th>
										      	<th class="th">Duración</th>
										      	<th class="th">Acciones</th>
										    </tr>
										    {suscripciones.map((item) =>(
										    	<tr class="bordeTr">
											      	<td class="elTr" scope="row">{item.data.nombre}</td>
											      	<td class="elTr">€{item.data.precio}</td>
											      	<td class="elTr">{item.data.duracion.tipo} ({item.data.duracion.dias} días)</td>
											      	<td class="elTr">
											      		<ElOjo
											      			estado={item.data.activo}
											      			accion={() => modificarEstadoSuscripcion(item)}
											      		/>
											      		<img onClick={() => editarSuscripcion(item)} src={edit} class="eye" />
											      		<img onClick={() => preguntarEliminarSuscripcion(item)} src={trash} class="eye" />
											      	</td>
											    </tr>
										    ))}
										</table>
									</div>
								</div>

							</div>
						</div>

				</div>

			</div>

			<Modal mostrar={modalSuscripcion}>
			    <p onClick={() => {
			    	CambioModalSuscripcion(false);
			    	CambioIdSuscripcion(false);
			    }} class="equix">X</p>
			    <div class="formContainer formGestion">
					<div class="headerForm">
						<h5 class="tituloForm">Gestionar Suscripción</h5>
					</div>
					<div class="formulario">
						<div class="form-group">
							<label for="nombreSuscripcion" class="form-label mt-4">Nombre*</label>
							<input type="text" class="form-control" id="nombreSuscripcion" placeholder="Nombre*" />
						</div><br/>

						<div class="form-group">
							<label for="descripcionSuscripcion" class="form-label mt-4">Descripción</label>
							<input type="text" class="form-control" id="descripcionSuscripcion" placeholder="Descripción*" />
						</div><br/>

						<div class="form-group">
							<label for="precioSuscripcion" class="form-label mt-4">Precio*</label>
							<input type="number" class="form-control" id="precioSuscripcion" placeholder="Precio*" />
						</div><br/>

						
						<div class="form-group" style={{textAlign: 'left',}}>
							<label for="tipoPerfil" class="form-label mt-4">Duración*</label>
						</div><br/>

						<fieldset class="form-group" style={{textAlign: 'left',}}>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="duracion" id="anual" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Anual
					        	</label>
					      	</div>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="duracion" id="mensual" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Mensual
					        	</label>
					      	</div>
					      	<div class="form-check" style={{display: 'flex', flexDirection: 'row', alignItems: 'center',}}>
					        	<input class="form-check-input" type="radio" name="duracion" id="dias" />
					        	<label class="form-check-label" for="optionsRadios1" style={{marginLeft: 10,}}>
					          		Días
					        	</label>
					        	<input style={{width: 150, display: 'inline-block', marginLeft: 10,}} type="number" class="form-control" id="cantidadDias" placeholder="Catidad días" />
					      	</div>
					    </fieldset><br/>


						<div class="form-group">
							<label for="cantidadClientes" class="form-label mt-4">Cantidad de Cliente</label>
							<input type="number" class="form-control" id="cantidadClientes" placeholder="Cantidad de Cliente" />
						</div><br/>

						<div class="form-group">
							<label for="cantidadRepararciones" class="form-label mt-4">Cantidad de Repararciones</label>
							<input type="number" class="form-control" id="cantidadRepararciones" placeholder="Cantidad de Repararciones" />
						</div><br/>

						<div class="form-group" style={{textAlign: 'left',}}>
							<label for="tipoPerfil" class="form-label mt-4">Tipo de perfil</label>
						</div><br/>

						<fieldset class="form-group" style={{textAlign: 'left',}}>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="tipoPerfil" id="basico" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Básico
					        	</label>
					      	</div>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="tipoPerfil" id="avanzado" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Avanzado
					        	</label>
					      	</div>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="tipoPerfil" id="premium" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Premium
					        	</label>
					      	</div>
					    </fieldset><br/>

					    <div class="form-group" style={{textAlign: 'left',}}>
							<label for="funcionesForo" class="form-label mt-4">Funciones del foro</label>
						</div><br/>

						<fieldset class="form-group" style={{textAlign: 'left',}}>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="funcionesForo" id="soloLectura" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Solo Lectura
					        	</label>
					      	</div>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="funcionesForo" id="creacionYRespuesta" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Creación y respuesta a temas
					        	</label>
					      	</div>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="funcionesForo" id="accesoTotal" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Acceso Total
					        	</label>
					      	</div>
					    </fieldset><br/>

					    <div class="form-group">
							<label for="cantidadMensajes" class="form-label mt-4">Cantidad de Mensajes al dia</label>
							<input type="number" class="form-control" id="cantidadMensajes" placeholder="Cantidad de Mensajes al dia" />
						</div><br/>

						<div class="form-group" style={{textAlign: 'left',}}>
							<label for="notificaciones" class="form-label mt-4">Notificaciones</label>
						</div><br/>

						<fieldset class="form-group" style={{textAlign: 'left',}}>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="notificaciones" id="basicasNotis" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Básicas
					        	</label>
					      	</div>
					      	<div class="form-check">
					        	<input class="form-check-input" type="radio" name="notificaciones" id="avanzadasNotis" />
					        	<label class="form-check-label" for="optionsRadios1">
					          		Avanzadas
					        	</label>
					      	</div>
					    </fieldset><br/>

					    <fieldset class="form-group" style={{textAlign: 'left',}}>
					    	<div class="form-check">
						        <input class="form-check-input" type="checkbox" id="tabloAnuncios" />
						        <label class="form-check-label" for="flexCheckDefault">
						          	Tablón de anuncios
						        </label>
						    </div>
					    </fieldset><br/>

					    <fieldset class="form-group" style={{textAlign: 'left',}}>
					    	<div class="form-check">
						        <input class="form-check-input" type="checkbox" id="bibliotecaRecursos" />
						        <label class="form-check-label" for="flexCheckDefault">
						          	Biblioteca de recursos
						        </label>
						    </div>
					    </fieldset><br/>

					    <fieldset class="form-group" style={{textAlign: 'left',}}>
					    	<div class="form-check">
						        <input class="form-check-input" type="checkbox" id="seminarios" />
						        <label class="form-check-label" for="flexCheckDefault">
						          	Seminarios Web y Eventos
						        </label>
						    </div>
					    </fieldset><br/>

					    <fieldset class="form-group" style={{textAlign: 'left',}}>
					    	<div class="form-check">
						        <input class="form-check-input" type="checkbox" id="soporte" />
						        <label class="form-check-label" for="flexCheckDefault">
						          	Soporte
						        </label>
						    </div>
					    </fieldset><br/>

					    <fieldset class="form-group" style={{textAlign: 'left',}}>
					    	<div class="form-check">
						        <input class="form-check-input" type="checkbox" id="analisisReportes" />
						        <label class="form-check-label" for="flexCheckDefault">
						          	Analisis y reportes
						        </label>
						    </div>
					    </fieldset><br/>

						<button onClick={procesarSuscripcion} class="btn btn-primary botonRepararTuPC">Guardar</button>
					</div>
				</div>
			</Modal>


			<Modal mostrar={modalEstadoSuscripcion}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={alterarEstadoSuscripcion} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEstadoSuscripcion(false);
				    	CambioIdSuscripcion(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>

			<Modal mostrar={modalEliminarSuscripcion}>
			    <h3 class="preguntaModal">
			    	{preguntaModal}
			    </h3>
			    <div class="botonesModal">
			    	<button onClick={procederEliminarSuscripcion} class="btn btn-success">Si</button>
			    	<button onClick={() => {
				    	CambioModalEliminarSuscripcion(false);
				    	CambioIdSuscripcion(false);
				    }} class="btn btn-danger">No</button>
			    </div>
			</Modal>


		</AnimatedPage>
	);
}

export default SuscripcionesAdminScreen;