import { useEffect, useState } from 'react';
import './pedido-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {datosPedido} from '../../../backend/pedidos/pedidos';
import {descargarImg} from '../../../global/imgs';
import {datosSuscripcion} from '../../../backend/suscripciones/suscripciones';
import {datosUsuario} from '../../../backend/usuarios/usuarios';

function PedidoEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;
  	const id = routeParams.id;

  	const [pantalla, CambioPantalla] = useState('loading');

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [uid, CambioUid] = useState(false);
	const [usuario, CambioUsuario] = useState({});

	const [busquedaPedido, CambioBusquedaPedido] = useState(false);
	const [pedido, CambioPedido] = useState({});
	const [producto, CambioProducto] = useState({});
	const [comprador, CambioComprador] = useState({})

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}

		if (busquedaPedido == false) {

			Swal.fire({
		      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#d81321" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		      showConfirmButton: false,
		      allowOutsideClick: false,
		      allowEnterKey: false,
		      allowEscapeKey: false,
		    });

			datosPedido(id).then(response =>{

				if (response != false) {

					CambioPedido(response);

					ponerProducto(response);
				}else{

					window.location.href = '/'+urlEmpresa;
				}
			});

			CambioBusquedaPedido(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else if (elUsuario.rol == 'empleado') {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}

				CambioUid(elUsuario.uid);
				CambioUsuario(elUsuario);
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const ponerProducto = async function(item){

		let elProducto = false;
		let elComprador = false;

		if (item.data.tipo == 'suscripcion') {

			elProducto = await datosSuscripcion(item.data.datosCompra.idProducto);
		}

		elComprador = await datosUsuario(item.data.uid);

		if (elProducto != false && elComprador != false) {

			CambioProducto(elProducto);
			CambioComprador(elComprador);

			Swal.close();
			CambioPantalla('');
		}else{

			window.location.href = '/'+urlEmpresa;
		}
	}

	const verFactura = async function(item){

		/*Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

    	let producto = false;

    	if (item.data.tipo == 'suscripcion') {

    		producto = await datosSuscripcion(item.data.datosCompra.idProducto);
    	}

    	if (producto != false) {*/

    		window.open(
    			'https://app.satflow.es/reparacion-de-ordenadores-backend/factura/factura.php?'+
    			'numeroFactura='+pedido.id+
    			'&fechaMostrar='+pedido.data.fecha+
    			'&nombre='+usuario.nombre+
				'&email='+usuario.email+
				'&direccion='+usuario.direccion+
				'&telefono='+usuario.telefono+
				'&identificacion='+usuario.DNIoNIF+
				'&nombreProducto='+producto.data.nombre+
				'&costoProducto='+producto.data.precio+
				'&total='+producto.data.precio+
				'&formaPago='+pedido.data.datosCompra.metodoPago,
    			'_blank'
    		);
    	/*}

    	Swal.close();*/
  	}

  	if (pantalla == 'loading') {

  		return(
  			<></>
  		);
  	}else{

		return(
			<AnimatedPage duracion={1}>

				<div class="centralPanel">
					
					<MenuEmpresa
						urlEmpresa={urlEmpresa}
					/>

					<div class="contenidoPanel">

						<BarraDeTareas/>

						

							<div class="row" style={{padding: 10,}}>
								<div class="col-sm-8">

									<div class="formContainer">
										<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
											<h5 class="tituloForm2">Datos del usuario</h5>
										</div>
										<div class="formulario">
											<img
												src={comprador.data.foto}
												class="fotoUsuario"
											/>

											<div class="rowPedido">

												<div>
													<h5 class="tituloForm2">Nombre:</h5>
													<span>{comprador.data.nombre}</span>
												</div>
												<div>
													<h5 class="tituloForm2">Telfono:</h5>
													<span>{comprador.data.telefono}</span>
												</div>
											</div>

											<div class="rowPedido">
												<div>
													<h5 class="tituloForm2">Email:</h5>
													<span>{comprador.data.email}</span>
												</div>
												<div>
													<h5 class="tituloForm2">Direcciòn:</h5>
													<span>{comprador.data.direccion}</span>
												</div>
											</div>

										</div>
									</div>

									<br/>

									<div class="formContainer">
										<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
											<h5 class="tituloForm2">Productos</h5>
										</div>
										<div class="formulario" style={{overflow: 'auto',}}>
											<table class="table table-hover">
												
												<tr>
											      	<th scope="row" class="ocultarMovil">ID</th>
											      	<th>Nombre</th>
											      	<th>Precio</th>
											      	<th>Ver</th>
											    </tr>

											    	<tr>
												      	<td scope="row" class="ocultarMovil">{producto.id}</td>
												      	<td>{producto.data.nombre}</td>
												      	<td>${producto.data.precio}</td>
												      	<td class="ocultarMovil">1</td>
												    </tr>
											    
											</table>
										</div>
									</div><br/>

								</div>

								<div class="col-sm-4">

									<div class="formContainer">
										<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
											<h5 class="tituloForm2">Datos del pedido</h5>
										</div>
										<div class="formulario">
											<h5 class="tituloForm2">ID:</h5>
											<span>{pedido.id}</span>
											<br/>
											<br/>

											<h5 class="tituloForm2">Fecha:</h5>
											<span>{pedido.data.fecha}</span>
											<br/>
											<br/>

											<h5 class="tituloForm2">Tipo:</h5>
											<span>{pedido.data.tipo}</span>
											<br/>
											<br/>

											<h5 class="tituloForm2">Descargar Factura:</h5>
											<button onClick={verFactura} class="btn btn-primary botonRepararTuPC">Descargar</button>
											<br/>
											<br/>


											<hr/>
											<div class="rowPedido">
												<h5 class="tituloForm2">Subtotal:</h5>
												<span>$ {producto.data.precio}</span>
											</div>

											<div class="rowPedido">
												<h5 class="tituloForm2">Descuento:</h5>
												<span>$ 0</span>
											</div>

											<div class="rowPedido">
												<h5 class="tituloForm2">Total:</h5>
												<span>$ {producto.data.precio}</span>
											</div>
										</div>
									</div>

								</div>
							</div><br/>

					</div>

				</div>

			</AnimatedPage>
		);
	}
}

export default PedidoEmpresaScreen;