import { useEffect, useState } from 'react';
import './empleado-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {InfoUsuario} from '../../../components/reparaciones/reparaciones';
import {datosUsuario} from '../../../backend/usuarios/usuarios';
import {flechaVer} from '../../../global/imgs.js';
import {gestionarReparacion, consultarReparaciones, eliminarReparacion} from '../../../backend/reparaciones/reparaciones';

function EmpleadoEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;
  	const id = routeParams.id;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [busquedaDatosEmpleado, CambioBusquedaEmpleado] = useState(false);
	const [datosEmpleado, CambioDatosEmpleado] = useState(false);

	const [nombre, CambioNombre] = useState('');
	const [email, CambioEmail] = useState('');
	const [telefono, CambioTelefono] = useState('');
	const [DNIoNIF, CambioDNIoNIF] = useState('');
	const [direccion, CambioDireccion] = useState('');

	const [busquedaReparaciones, CambioBusquedaReparaciones] = useState(false);
	const [reparaciones, CambioReparaciones] = useState([]);
	const [reparacionesFinales, CambioReparacionesFinales] = useState([]);
	const [limiteReparaciones, CambioLimiteReparaciones] = useState(30);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			//document.getElementById('home').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaDatosEmpleado == false) {

			Swal.fire({
		      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
		          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
		          '</div>',
		      showConfirmButton: false,
		      allowOutsideClick: false,
		      allowEnterKey: false,
		      allowEscapeKey: false,
		    });

		    datosUsuario(id).then(response =>{

		    	if (response != false) { 

		    		CambioDatosEmpleado(response);

		    		CambioNombre(response.data.nombre);
					CambioEmail(response.data.email);
					CambioTelefono(response.data.telefono);
					CambioDNIoNIF(response.data.DNIoNIF);
					CambioDireccion(response.data.direccion);

		    		Swal.close();
		    	}else{

		    		window.location.href = '/'+urlEmpresa+'/empleados';
		    	}
		    });

			CambioBusquedaEmpleado(true);
		}

		if (busquedaReparaciones == false) {

			consultarReparaciones(urlEmpresa).then(response =>{

				let lasReparaciones = [];
				let lasReparaciones2 = [];
				let cero = 0;

				for (let reparacion of response) {
					
					if (reparacion.data.uid == id) {

						lasReparaciones.push(reparacion);
					}
				}

				for (let reparacion2 of lasReparaciones) {
					
					if (cero <= limiteReparaciones) {

						lasReparaciones2.push(reparacion2);
					}

					cero++;
				}

				CambioReparaciones(lasReparaciones);
				CambioReparacionesFinales(lasReparaciones2);
			});

			CambioBusquedaReparaciones(true);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else if (elUsuario.rol == 'empleado') {

				terminarSesion();
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					<div class="container">

						<div class="row">
							<div class="col-sm">

								<div class="formContainer">

									<div class="headerForm" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
										<h5 class="tituloForm">Datos del empleado</h5>
									</div>
									<div class="formulario conColumnas">

										<div class="columnaReparacion">
									      	<label class="form-label mt-4">Nombre:</label>
									    	<br/>
									    	<span>{nombre}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Email:</label>
									    	<br/>
									    	<span>{email}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Teléfono:</label>
									    	<br/>
									    	<span>{telefono}</span>
									    </div>

									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">DNI / NIF:</label>
									    	<br/>
									    	<span>{DNIoNIF}</span>
									    </div>
									    <div class="columnaReparacion">
									      	<label class="form-label mt-4">Dirección:</label>
									    	<br/>
									    	<span>{direccion}</span>
									    </div>
									</div>
								</div>

							</div>
						</div><br/>

						<div class="row">

							<div class="col-sm">

								<div class="formContainer tablaContainer">
									<div class="headerForm headerTabla">
										<h5 class="tituloForm">Reparaciones</h5>
									</div>
									<div class="formulario">
										<table class="table table-hover">
											<tr>
										      	<th class="th" scope="row">Cliente</th>
										      	<th class="th">Descripción</th>
										      	<th class="th">Técnico Responsable</th>
										      	<th class="th">Tiempo Estimado</th>
										      	<th class="th">Acciones</th>
										    </tr>

										    {reparacionesFinales.map((item) =>(
										    	<tr class="bordeTr">
											      	<td class="elTr" scope="row">{item.data.cliente}</td>
											      	<td class="elTr">{item.data.descripcion}</td>
											      	<td class="elTr">{item.data.tecnico}</td>
											      	<td class="elTr">{item.data.tiempoEstimado} día(s)</td>
											      	<td class="elTr">
											      		<img onClick={() => window.location.href = '/'+urlEmpresa+'/reparacion/'+item.id} src={flechaVer} class="eye" />
											      	</td>
											    </tr>
										    ))}
										    
										</table>
									</div>
								</div>

							</div>

						</div><br/>

					</div>

				</div>

			</div>

		</AnimatedPage>
	);
}

export default EmpleadoEmpresaScreen;