import { useEffect, useState } from 'react';
import './suscripciones-styles.css';
import {AnimatedPage} from '../../../components/animacion/animacion';
import Swal from 'sweetalert2';
import {validarUsuario} from '../../../global/validarUsuario';
import {useParams} from 'react-router-dom';
import {MenuEmpresa, BotonMotrarMenu, BarraDeTareas} from '../../../components/menu/menu';
import {consultarSuscripciones, tokenPaypal, contratarSuscripcion} from '../../../backend/suscripciones/suscripciones';
import {CaracSucrip, DuracionSuscrip} from '../../../components/suscripciones/suscripciones';
import {Modal} from '../../../components/modals/modals';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {fechaHoy, meses} from '../../../global/datosCalendario';
import {validarSiYaUsoCupon, consultarCupon, registrarUsoCupon} from '../../../backend/cupones/cupones';

function SuscripcionesEmpresaScreen(){

	const routeParams = useParams();
  	const urlEmpresa = routeParams.urlEmpresa;

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [uid, CambioUid] = useState(false);
	const [usuarioSuscripcion, CambioUsuarioSuscripcion] = useState(false);

	const [busquedaSuscripciones, CambioBusquedaSuscripciones] = useState(false);
	const [suscripciones, CambioSuscripciones] = useState([]);

	const [ponerTokenPaypal, CambioPonerTokenPaypal] = useState(false);
	const [elTokenPaypal, CambioElTokenPaypal] = useState('');
	const [idOrdenPaypal, CambioIdOrdenPaypal] = useState('');

	const [modalPago, CambioModalPago] = useState(false);

	const [idSuscripcion, CambioIdSuscripcion] = useState(false);
	const [precioSuscripcion, CambioPrecioSuscripcion] = useState(0);
	const [duracion, CambioDuracion] = useState({});

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			document.getElementById('suscripciones').classList.add('elementoMenuActive');
			CambioUsuarioBuscado(true);
		}

		if (busquedaSuscripciones == false) {

			consultarSuscripciones().then(response =>{

				CambioSuscripciones(response);
			});

			CambioBusquedaSuscripciones(true);
		}

		if (ponerTokenPaypal == false) {

			tokenPaypal().then(response =>{

				CambioElTokenPaypal(response);
			});

			CambioPonerTokenPaypal(true);
		}

		if (suscripciones.length > 0 && usuarioSuscripcion != false) {

			let lasSuscripciones = suscripciones;
			let suscripcion = false;

			for (let laSuscripcion of lasSuscripciones) {
				
				if (usuarioSuscripcion == laSuscripcion.id) {

					suscripcion = laSuscripcion;
				}
			}

			let index = lasSuscripciones.indexOf(suscripcion);
			
			lasSuscripciones.splice(index, 1);

			CambioSuscripciones(lasSuscripciones);
			CambioUsuarioSuscripcion(false);
		}
	});

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'cliente') {

				window.location.href = '/';
			}else if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/admin/';
			}else if (elUsuario.urlEmpresa != urlEmpresa) {

				terminarSesion();
			}else if (elUsuario.rol == 'empleado') {

				terminarSesion();
			}else{

				if (elUsuario.rol == 'empleado') {

					document.getElementById('empleados').setAttribute('style', 'display: none;');
				}

				CambioUid(elUsuario.uid);
				CambioUsuarioSuscripcion(elUsuario.suscripcion);
			}
		}else{

			window.location.href = '/empresa/login';
		}
	}

	const terminarSesion = async function(){

	    await localStorage.clear();
	    window.location.href = '/empresa/login';
	}

	const verMetodosDePago = async function(item){

		CambioIdSuscripcion(item.id);
		CambioPrecioSuscripcion(item.data.precio);
		CambioDuracion(item.data.duracion);
		CambioModalPago(true);

		// años fecha.slice(0, -14)
		// mes fecha.slice(5, -11)
		// dia fecha.slice(8, -8)
	}

	const createOrder = async function(){

		let orden = await fetch('https://app.satflow.es/reparacion-de-ordenadores-backend/pagos/paypal.php',{
            method: 'POST',
            headers:{
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "accion=crearOrden"+
            "&token="+elTokenPaypal+
            "&precio="+precioSuscripcion+
            "&idMembresia="+idSuscripcion+
            "&uid="+uid,
        }).then(response =>{
            return response.json();
        }).then(json =>{

            let datos = {
                referencia: json.referencia,
                paypal: JSON.parse(json.paypal),
            }

            console.log(datos);

            //window.location.href = datos.links[1].href;
            return datos;
        });

        CambioIdOrdenPaypal(orden.paypal.id);

        return orden.paypal.id;
	}
	
	const onApprove = async function(data){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

	    let fecha = await fechaHoy();
	    let fechaVencimiento = '';

	    let year = fecha.slice(0, -14);
		let month = fecha.slice(5, -11);
		let day = fecha.slice(8, -8);

		if (duracion.tipo == 'anual') {

			let suma = parseInt(year) + 1;

			fechaVencimiento = suma+'-'+month+'-'+day;
		}else if (duracion.tipo == 'mensual'){

			let suma = parseInt(month) + 1;
			let diaActual = day;
			let anoActual = parseInt(year);
			let mesFinal = '';

			if (suma > 12) {

				let nuevoAno = anoActual + 1;

				mesFinal = '01';
				anoActual = nuevoAno;
			}else{

				let elCero = '';

				if (suma < 10) {

					elCero = '0';
				}

				mesFinal = elCero+suma;
			}

			if (diaActual == '29' || diaActual == '30' || diaActual == '31') {

				diaActual = '28';
			}

			fechaVencimiento = anoActual+'-'+mesFinal+'-'+diaActual;
		}else if (duracion.tipo == 'dias') {

			let diasFinales = '';
			let diaFinalMes = meses[parseInt(month - 1)].dias;

			let suma = parseInt(day) + parseInt(duracion.dias);

			let anoActual = parseInt(year);
			let mesFinal = '';

			if (suma > diaFinalMes) {

				let restaDia = diaFinalMes - suma;
				let sumaMes = parseInt(month) + 1;

				diasFinales = restaDia;

				if (sumaMes > 12) {

					let nuevoAno = anoActual + 1;

					mesFinal = '01';
					anoActual = nuevoAno;
				}else{

					let elCero = '';

					if (suma < 10) {

						elCero = '0';
					}

					mesFinal = elCero+sumaMes;
				}

				fechaVencimiento = anoActual+'-'+mesFinal+'-'+diaFinalMes;
			}else{

				fechaVencimiento = anoActual+'-'+month+'-'+suma;
			}
		}

		let numeroFactura = Math.random() * (999999 - 1000) + 1000;

	    let datos = {
	    	fecha: fecha,
	    	uid: uid,
	    	tipo: 'suscripcion',
	    	numeroFactura: numeroFactura,
	    	datosCompra:{
	    		metodoPago: 'paypal',
	    		idOrdenPaypal: data.orderID,
	    		idProducto: idSuscripcion,
	    		fechaVencimiento: fechaVencimiento,
	    	},
	    };

	    contratarSuscripcion(datos).then(response =>{

	    	Swal.fire({
			    icon: 'success',
			    title: 'Suscripción cambiada.',
			});

			window.setTimeout(terminarSesion, 1000);
	    });
	}

	const aplicarCupon = async function(){

		Swal.fire({
	      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#7367f0" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	          '</div>',
	      showConfirmButton: false,
	      allowOutsideClick: false,
	      allowEnterKey: false,
	      allowEscapeKey: false,
	    });

		let codigo = document.getElementById('codigo').value;

		if (codigo === '') {

			Swal.fire({
			    icon: 'info',
			    title: 'Ingrese un código para continuar.',
			});
		}else{

			let validarCodigo = await validarSiYaUsoCupon(uid, codigo);
			
			if (validarCodigo == false) {

				let elCupon = await consultarCupon(codigo);

				let fechaCreacion = await fechaHoy();

				if (elCupon != false) {

					let datosCupon = {
						uid: uid,
						fechaCreacion: fechaCreacion,
						idCupon: elCupon.id,
						codigo: elCupon.data.codigo,
					};

					await registrarUsoCupon(datosCupon);

					let dataAprovado = {
						orderID: '',
					};

					onApprove(dataAprovado);
				}else{

					Swal.fire({
					    icon: 'info',
					    title: 'El código no es valido.',
					});
				}
			}else{

				Swal.fire({
				    icon: 'info',
				    title: 'Ya utilizaste este cupon.',
				});
			}
		}
	}

	return(
		<AnimatedPage duracion={1}>

			<div class="centralPanel">
				
				<MenuEmpresa
					urlEmpresa={urlEmpresa}
				/>

				<div class="contenidoPanel">

					<BarraDeTareas/>

					<div class="container"><br/>

						<div class="row">
								
							<div class="col-sm">
								<h1 style={{textAlign: 'center',}}>Suscripciones</h1>
							</div>

						</div>

						<div class="row">
								
								<div class="col-sm">
									<div class="suscripciones">
										{suscripciones.map((item) =>(

											
												<div class="suscripcion">

													<div class="datosSuscripcionContainer">
														<h3 class="tituloSuscripcion">{item.data.nombre}</h3>
														<span class="descripcionSuscripcion">{item.data.descripcion}</span>
														<br/><br/>
														<div class="includedContainer">
															<span class="textIncluded">CARACTERÍSTICAS INCLUIDAS</span>
															<div class="lineaSuscripcion"></div>
														</div>
														<div class="caracSuscrip">
																<CaracSucrip
																	caracteristica={item.data.analisisReportes}
																	texto={'Analisis y reportes'}
																/>

																<CaracSucrip
																	caracteristica={item.data.bibliotecaRecursos}
																	texto={'Acceso a la biblioteca de recursos'}
																/>

																	
																<CaracSucrip
																	caracteristica={item.data.cantidadClientes}
																	texto={'Registrar '+item.data.cantidadClientes+' clientes'}
																/>

																<CaracSucrip
																	caracteristica={item.data.cantidadMensajes}
																	texto={'Envio de '+item.data.cantidadMensajes+' mensajes'}
																/>

																

																	<CaracSucrip
																		caracteristica={item.data.cantidadRepararciones}
																		texto={'Registrar '+item.data.cantidadMensajes+' repararciones'}
																	/>

																	<CaracSucrip
																		caracteristica={item.data.funcionesForo}
																		texto={'Funciones del foro: '+item.data.cantidadMensajes}
																	/>

																

																	<CaracSucrip
																		caracteristica={item.data.notificaciones}
																		texto={'Notificaciones: '+item.data.notificaciones}
																	/>

																	<CaracSucrip
																		caracteristica={item.data.seminarios}
																		texto={'Seminarios'}
																	/>

																

																	<CaracSucrip
																		caracteristica={item.data.soporte}
																		texto={'Soporte'}
																	/>

																	<CaracSucrip
																		caracteristica={item.data.tabloAnuncios}
																		texto={'Anuncios'}
																	/>

																

																	<CaracSucrip
																		caracteristica={item.data.tipoPerfil}
																		texto={'Tipo de perfil: '+item.data.tipoPerfil}
																	/>
														</div>
													</div>

													<div class="precioContainer">

														<h1 class="precioSuscrip">€ {item.data.precio}</h1>

														<DuracionSuscrip
															item={item}
														/>
														<br/>

														<button onClick={() => verMetodosDePago(item)} class="btn btn-primary botonRepararTuPC">Empezar</button>

													</div>

												</div>

										))}
									</div>
								</div>
							
						</div><br/><br/>

					</div>

				</div>

			</div>

			<Modal mostrar={modalPago}>
			    <p onClick={() => {
			    	CambioModalPago(false);
			    	CambioIdSuscripcion(false);
			    }} class="equix">X</p>
			    <div class="formContainer formGestion">
					<div class="headerForm">
						<h5 class="tituloForm">Pagar con</h5>
					</div>
					<div class="formulario">

						<PayPalScriptProvider options={{ clientId: "AWdxUpBRgxUs5zQd6zC4Rqq6O0Ffhz4bNjh_4W3AwLcPOffm9bG77BeBGwtATi-n7hCOe9WIDQhlM2UY", currency: "EUR", }}>
				            <PayPalButtons
				                createOrder={createOrder}
				                onApprove={onApprove}
				            />
				        </PayPalScriptProvider>

					</div>
					<div class="headerForm">
						<h5 class="tituloForm">Aplicar cupón</h5>
					</div>
					<div class="formulario">

						<div class="form-group">
							<label for="codigo" class="form-label mt-4">Código*</label>
							<input type="text" class="form-control" id="codigo" placeholder="Código" />
						</div><br/>

						<button onClick={aplicarCupon} class="btn btn-primary botonRepararTuPC">Aplicar</button>

					</div>
				</div>
			</Modal>

		</AnimatedPage>
	);
}

export default SuscripcionesEmpresaScreen;